<template>
  <div>
    <UnderConstructionBanner :is-visible="true" />
    <!--Menu-->
    <div class="mt-8">
      <div class="flex justify-between">
        <FrequencyFilter
          v-model="selectedPeriod"
          :periods="periodsByFrequency"
        />
      </div>
    </div>
    <!-- header section -->
    <div
      class="bg-gray-100 rounded-xl overflow-hidden p-4 flex justify-between mt-4"
    >
      <div class="flex flex-col gap-2">
        <h2>{{ portfolioName }}</h2>
        <p class="target-goal">Portfolio description</p>
      </div>
      <div>
        <button
          class="focus:outline-none mx-2 my-2 bg-[#191B45] transition duration-150 ease-in-out font-bold hover:opacity-75 rounded text-white px-6 py-3 text-base rounded-lg opacity-50 cursor-not-allowed"
        >
          Performance
        </button>
        <button
          class="focus:outline-none mx-2 my-2 bg-[#191B45] transition duration-150 ease-in-out font-bold hover:opacity-75 rounded text-white px-6 py-3 text-base rounded-lg opacity-50 cursor-not-allowed"
        >
          Edit preferences
        </button>
      </div>
    </div>
    <!-- Modules -->
    <div class="grid grid-cols-2 gap-6 mt-10">
      <ModuleCard
        v-for="(card, index) in modules"
        :key="index"
        v-bind="card"
        :frequency="card.frequency"
        :period="selectedPeriod.period"
        :title="card.title"
        :description="card.description"
        :aggregatedView="true"
        :aggregatedUrl="card.aggregatedUrl"
        :status2xCertification="status2xCertification"
      />
    </div>
  </div>
</template>

<script>
import ModuleCard from "../ModuleCard.vue";
import { moduleDescriptions, getAggregatedUrl } from "../../IMMhelpers";
import UnderConstructionBanner from "@/components/imm-components/UnderConstructionBanner.vue";
import FrequencyFilter from "@/components/imm-components/dashboard/FrequencyFilter.vue";
import { apiExecute } from "@/helpers/api";

export default {
  name: "AggregatedPortfolio",
  components: { ModuleCard, UnderConstructionBanner, FrequencyFilter },
  props: {
    portfolioName: {
      type: String,
      required: true,
    },
    investorPreferences: {
      type: Array,
      required: true,
    },
    status2xCertification: {
      type: String || null,
    },
  },
  data() {
    return {
      selectedPeriod: {
        frequency: "All",
        period: null,
      },
      selectedPortfolio: null,
      selectedYear: "2024",
      modules: [],
      periodsByFrequency: null,
    };
  },
  async mounted() {
    this.updatePeriodsByFrequency();
    this.modules = this.investorPreferences[0]?.preferences?.map((module) => {
      return {
        ...module,
        description: moduleDescriptions[module.title],
        aggregatedUrl: getAggregatedUrl(module.title, this.portfolioName),
      };
    });
  },
  methods: {
    async updatePeriodsByFrequency() {
      try {
        this.periodsByFrequency = null;
        const data = await apiExecute("get", "/imm/dashboard/periods", {
          portfolio: this.portfolioName,
        });
        this.periodsByFrequency = data.periods;
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    portfolioName: {
      handler: function () {
        this.selectedPeriod = {
          frequency: "All",
          period: null,
        };
        this.updatePeriodsByFrequency();
      },
    },
  },
};
</script>
