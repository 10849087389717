import axios from 'axios'
import Config from '@/../env'
import Cookies from '@/helpers/cookies'


const client = axios.create({
    baseURL: `${Config.apiUrl}`,
    json: true,
    headers: {
      'api-version': Config.apiVersion || '',
      'authorization': 'Bearer '+Cookies.get('session'),
      'project': Cookies.get('project'),
    }
})

export const apiExecute = (method, resource, data) => {
  client.defaults.headers["authorization"] = "Bearer " + Cookies.get("session");
  if (method.toLowerCase() == "get") {
    return client({ method, url: resource, params: data }).then((req) => {
      return req.data;
    });
  } else {
    return client({ method, url: resource, data }).then((req) => {
      return req.data;
    });
  }
}

const execute = (method, resource, data) => {
  client.defaults.headers['authorization'] = 'Bearer '+Cookies.get('session');
  return client({ method, url: resource, data,}).then(req => {
    return req.data
  }).catch(function(err){
    console.log(err);
  })
}

const addEndpoints = (object) => {

  // Add a find function
  object.find = () => {
    return execute('get', object.endpoint)
  };

  object.findOne = (id) => {
    if(id){
      return execute('get', `${object.endpoint}/${id}`)
    } else {
      return false;
    }
  };

  object.create = (data) => {
      return execute('post', object.endpoint, data)
  };

  object.update = (id, data) => {
      return execute('put', `${object.endpoint}/${id}`, data)
  };

  object.delete = (id) => {
      return execute('delete', `${object.endpoint}/${id}`)
  };

  object.custom = (method, endpoint, data) => {
    return execute(method, object.endpoint+endpoint, data);
  }

  object.raw = (method, endpoint, data) => {
    return execute(method, endpoint, data);
  }

  return object;

}

export default {
   init(collection) {
    return addEndpoints({
      endpoint: '/'+collection
    });
  }
}
