<template>
  <div class="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8 mt-16">
    <div class="max-w-7xl mx-auto">
      <!-- header section -->
      <div class="mb-8">
        <h1 class="text-3xl font-bold text-gray-900">{{ page }}</h1>
        <p class="mt-2 text-xl text-gray-500">Dashboard overview and metrics</p>
      </div>

      <!-- loading state -->
      <div v-if="loading" class="flex justify-center items-center h-64">
        <div
          class="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"
        ></div>
      </div>

      <!-- main content -->
      <div v-else class="bg-white shadow rounded-lg p-6">
        <div v-if="!hasData">No data available</div>
        <div
          v-for="(indicatorCharts, indicatorIndex) in groupedData"
          :key="indicatorIndex"
          class="mb-8"
        >
          <h2 class="text-2xl font-bold text-gray-800 mb-4">
            {{ indicatorCharts.indicator }}
          </h2>
          <table class="divide-y divide-gray-200">
            <thead>
              <tr class="w-[100px]">
                <th
                  class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Survey Question
                </th>
                <th
                  v-for="(date, period) in periodDates"
                  :key="period"
                  class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ date }}
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr
                v-for="(question, questionIndex) in indicatorCharts.charts"
                :key="questionIndex"
              >
                <td class="px-6 py-4 text-sm font-medium text-gray-900">
                  {{ question.chartTitle }}
                  <div v-if="question" class="mt-4">
                    <PlotBarChart
                      v-if="question.chartType === 'Bar'"
                      :data="question"
                      :color-schema="company.chartColorSchema"
                    />
                    <!--  <PlotLineChart
                      v-if="
                        getChartForQuestion(question, indicator).chartType ===
                        'Line'
                      "
                      :data="getChartForQuestion(question, indicator)"
                    /> -->
                  </div>
                </td>
                <td
                  v-for="(_, period) in periodDates"
                  :key="period"
                  class="px-6 py-4 text-sm text-gray-500"
                >
                  <div v-if="question.periods">
                    <p
                      class="mt-2 text-gray-600"
                      v-if="
                        question.chartType !== 'Bar' ||
                        question.chartType !== 'Line'
                      "
                    >
                      {{
                        Array.isArray(question.periods[period].answer)
                          ? question.periods[period].answer.join(", ")
                          : question.periods[period].answer ||
                            "No data available"
                      }}
                    </p>
                  </div>
                  <div v-else>No data available</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/stores/user";
import { getDashboardData } from "@/components/imm-components/IMMhelpers";
import PlotBarChart from "@/components/imm-components/charts/Plot-BarChart.vue";
import {
  pageMapper,
  checkWhiteLabel,
} from "@/components/imm-components/white-label";
//import PlotLineChart from "@/components/imm-components/charts/Plot-LineChart.vue";

export default {
  name: "DashboardSingle",
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  components: {
    PlotBarChart,
    //PlotLineChart,
  },
  data() {
    return {
      teamId: null,
      company: null,
      user: null,
      page: "",
      loading: true,
      dashboardData: undefined,
      surveyId: null,
    };
  },
  computed: {
    hasData() {
      return Object.values(this.groupedData).length > 0;
    },
    periodDates() {
      return this.dashboardData.periodDates;
    },
    groupedData() {
      const orgs = this.dashboardData?.organizations;
      if (!orgs || orgs.length === 0) {
        return {};
      }
      const surveys = orgs[0]?.surveys;
      if (!surveys || surveys.length === 0) {
        return {};
      }
      return surveys[0]?.indicators;
    },
  },
  async mounted() {
    this.teamId = this.$route.query.teamId;

    try {
      this.user = this.userStore.user;
      if (!this.user) {
        this.user = await this.userStore.fetchUser();
      }
      this.company = checkWhiteLabel(this.user?.featureFlags || []);
      this.surveyId = this.$route.params.surveyId;
      this.page = pageMapper[this.surveyId];
      this.dashboardData = await getDashboardData(
        this.teamId,
        this.surveyId,
        this.$route.query.frequency
      );
    } catch (error) {
      console.error("Error in mounted:", error);
    } finally {
      this.loading = false;
    }
  },
};
</script>
