<template>
  <div class="static assementPageStyle">
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <div
        id="loaded"
        key="load"
        class="container"
        v-if="endofcreated && roiData && roiData !== ''"
      >
        <div class="PoweredbyTitle_logo mb-4">
          <div class="PoweredbyTitleWrepper">
            <img
              v-if="roiData.logo && roiData.logo[0] && roiData.logo[0].url"
              class="h-16 print:h-auto print:w-64"
              :src="roiData.logo[0].url"
              style="height: 10rem"
            />
            <h1
              class="text-6xl ml-10 tracking-tighter font-medium print:text-7xl"
            >
              {{ roiData.organizationName || user.organization.name }}'s Gender
              ROI™ Assessment
            </h1>
          </div>
          <div class="powerd_invite-wrapper">
            <div class="powerdby_logo_wrapper">
              <div class="Poweredby_logoImg">
                <span class="powerd-logo">Powered by:</span>
                <img
                  src="@/assets/images/roi/sweef-capital.png"
                  alt="background-image"
                  style="height: 50px"
                />
                <img
                  src="@/assets/images/logo-navy.png"
                  alt="background-image"
                />
              </div>
              <div
                class="EmailInvitemember"
                v-if="
                  roiData.team_id &&
                  user.organization._id == roiData.team_id &&
                  this.roiData.scoreData &&
                  roiData.scoreData != '' &&
                  (this.roiData.percentage == 100 ||
                    this.roiData.scoreData.length > 1 ||
                    isRepeatAssessment)
                "
              >
                <div class="EmailInput" style="border: none">
                  <input
                    type="email"
                    placeholder="Enter Email Address"
                    v-model="inviteMemberEmail"
                  />
                </div>
                <div class="InviteBtn tooltip">
                  <div @click="inviteMember()">
                    <button
                      class="text-white text-sm font-medium"
                      style="width: max-content"
                    >
                      Share
                    </button>
                  </div>
                  <span class="share-email-tooltip-text"
                    >When you invite someone to view your shared assessment
                    results, they will receive an email with a link. They will
                    need to already have, or create an account, at
                    <router-link to="/" style="color: #599bd8"
                      >app.equilo.io</router-link
                    >
                    to log in and view your shared assessment results.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="text-align: right">
          <div class="flex" style="justify-content: end">
            <div class="">
              <div class="roi_portfolio_button pb-4">
                <router-link to="/roi/roiSharedAssessments">
                  <div class="tooltip">
                    <p style="cursor: pointer; margin-top: 3px">
                      Shared Gender ROI™
                    </p>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="ml-2" v-if="organizationDetail.accessRoi">
              <div class="roi_portfolio_button pb-4">
                <div class="tooltip" @click="checkportfolioPermissionpopup()">
                  <p style="cursor: pointer; margin-top: 3px">
                    Gender ROI™ Portfolio
                  </p>
                </div>
                <!-- </span> -->
              </div>
            </div>
          </div>
        </div>
        <transition name="fadeIn" mode="out-in">
          <div id="loaded" key="content">
            <!-- Assement Page Wrepper -->
            <div class="AssementPageWrepper">
              <div
                class="spiderchart_main_container mb-20"
                v-if="
                  roiData.certifiedThatAllResponsesTrueAndAccurate == 'Yes' ||
                  isRepeatAssessment
                "
              >
                <div class="OverviewTitle">
                  <h2 style="width: 942px" class="tooltip_most_recent">
                    Gender ROI™ Pillar Assessment Results
                    <span
                      v-if="roiData.mostRecentSurveyData || isRepeatAssessment"
                      class="tooltip_text_most_recent"
                      >This is the most recently calculated Gender ROI™ Score
                      based on a fully completed assessment. A new assessment is
                      currently in progress.</span
                    >
                  </h2>
                  <span></span>
                </div>
                <div class="spiderchart_content flex">
                  <!-- <div class="left_side flex" :style="{ 'width': user.languagePreference == 'Spanish' ? '29%' : '25%' }">
                    <div class="orange-dot mr-5"></div>
                      <span class="font-bold" v-if="roiData.scoreData[0].financialReportingYear">{{
                        roiData.scoreData[0].financialReportingYear
                      }}</span>
                      <span class="font-bold" v-if="roiData.scoreData[0].financialReportingYear == ''">Latest
                        Quarter</span>
                  </div>
                  <div class="flex dot_with_text" v-if="roiData.scoreData.length > 1">
                    <div class="blue-dot  mr-5"></div>
                      <span class="font-bold" v-if="roiData.scoreData[1].financialReportingYear">{{
                      roiData.scoreData[1].financialReportingYear
                      }}</span>
                      <span class="font-bold" v-if="roiData.scoreData[1].financialReportingYear == ''">First Historical
                        Year</span>
                  </div>
                  <div class="flex teal_dot_with_text tooltip" style="display: flex !important;"
                    :style="(roiData.scoreData.length == 1) ? 'margin-top:30px !important' : ''">
                    <div class="teal-dot  mr-5"></div>
                    <template>
                      <span class="font-bold" style="width: 200px;">GESI Contextual Score - {{
                        roiData.countryHqText
                      }}</span>
                    </template>
                    <span class="gender_roi_context_hover_text">This score is the contextual gender analysis score for
                      this country, calculated using Equilo's
                      proprietary algorithms and utilizing existing publicly available for this country related to this
                      Gender ROI™ parameter. The full country contextual assessment with detailed data visualizations
                      and
                      statistics is available via subscription <a href="https://www.equilo.io/pricing-enterprise"
                        target="_blank" style="color: blue">here</a>.</span>
                  </div> -->
                  <div class="right_side">
                    <div class="spiderchartContent" id="chart">
                      <div class="relative" :key="1">
                        <spiderchart
                          v-if="
                            roiData.scoreData != '' &&
                            roiData.scoreData.length == 1
                          "
                          :paddingL="40"
                          :paddingR="40"
                          :paddingT="40"
                          :paddingB="40"
                          :details="roiData.scoreData[0]"
                          :gesiScore="roiData.roiGesiScoreData"
                          :financialYear="
                            roiData.scoreData[0].financialReportingYear
                              ? roiData.scoreData[0].financialReportingYear
                              : 'Latest Quarter'
                          "
                          :gesiLabel="
                            'GESI Contextual Score - ' + roiData.countryHqText
                          "
                          class="overflow-visible"
                          style="position: relative; height: 100%; width: 100%"
                          :organizationType="roiData.organizationType"
                          :roiData="roiData.scoreData[0]"
                        >
                        </spiderchart>
                        <spiderchart-multiple
                          v-else
                          :paddingL="40"
                          :paddingR="40"
                          :paddingT="40"
                          :paddingB="40"
                          :financialYear="
                            roiData.scoreData[0].financialReportingYear
                              ? roiData.scoreData[0].financialReportingYear
                              : 'Latest Quarter'
                          "
                          :financialYear1="
                            roiData.scoreData[1].financialReportingYear
                              ? roiData.scoreData[1].financialReportingYear
                              : 'First Historical Year'
                          "
                          :details="roiData.scoreData[0]"
                          :detailsOld="roiData.scoreData[1]"
                          :gesiLabel="
                            'GESI Contextual Score - ' + roiData.countryHqText
                          "
                          :gesiScore="roiData.roiGesiScoreData"
                          class="overflow-visible"
                          style="position: relative; height: 100%; width: 100%"
                          :organizationType="roiData.organizationType"
                          :roiData="roiData.scoreData[0]"
                        >
                        </spiderchart-multiple>
                        <template>
                          <!--Opportunity-->
                          <div class="Ind_Inv_Icon opportunity-icon" id="2uur">
                            <img
                              src="../../assets/images/roi/opportunity.svg"
                              alt="Indirect Investment"
                            />
                            <span style="color: #2bb900"> Opportunity </span>
                          </div>

                          <!--Inclusion-->
                          <div class="Ind_Inv_Icon inclusion-icon" id="8uur">
                            <img
                              src="../../assets/images/roi/inclusion.svg"
                              alt="Indirect Investment"
                            />
                            <span style="color: #00a269"> Inclusion </span>
                          </div>

                          <!--Resilince-->
                          <div class="Ind_Inv_Icon resilince-icon" id="10uur">
                            <img
                              src="../../assets/images/roi/resilience.svg"
                              alt="Indirect Investment"
                            />
                            <span style="color: #0068cd"> Resilience </span>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="OverviewBlock flex">
                <div class="OverviewBlock_left_side pr-10">
                  <div class="OverviewTitle">
                    <h2>Take the Survey</h2>
                    <span></span>
                  </div>
                  <div class="take_survey_container flex">
                    <div class="take_survey_inner" style="padding-right: 25px">
                      <h2 class="title">
                        <img src="@/assets/images/assignment_black.png" />
                        General
                      </h2>
                      <ul>
                        <li
                          :class="{ 'border-animations': showAnimationClass }"
                        >
                          <p></p>
                          <p></p>
                          <p></p>
                          <p></p>
                          <span :class="{ 'span-space': showAnimationClass }"
                            >Enterprise Intake Information</span
                          >
                          <div
                            class="tooltip"
                            v-if="
                              !repeatInProgressData?.isDataSynced
                                ? repeatAirtableChangeData.certifiedThatAllResponsesTrueAndAccurate ==
                                  'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <img
                            src="@/assets/images/check_circle.png"
                            v-else-if="
                              repeatInProgressData?.isDataSynced &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <img
                            src="@/assets/images/edit_black_icon.png"
                            id="editImageIcon"
                            alt="edit-image-icon/1"
                            @click="moduleClicked($event)"
                            class="cursor-pointer notranslate"
                            :class="{ 'img-space': showAnimationClass }"
                            v-else
                          />
                        </li>
                        <li>
                          <span>Financials</span>
                          <div
                            class="tooltip"
                            v-if="
                              !repeatInProgressData?.isDataSynced
                                ? repeatAirtableChangeData.modTwoComplete &&
                                  repeatAirtableChangeData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <img
                            src="@/assets/images/check_circle.png"
                            v-else-if="
                              repeatInProgressData?.isDataSynced &&
                              roiData.modTwoComplete &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div
                            class="tooltip"
                            v-else-if="
                              roiData.airtableChange
                                ? roiData.airtableChange.modTwoComplete &&
                                  roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <div
                            v-else-if="
                              !roiData.sharedAssesment?.includes(
                                'modTwoEditable'
                              ) && !user.admin
                            "
                          >
                            <img src="@/assets/images/lock-dark.png" />
                          </div>
                          <img
                            src="@/assets/images/edit_black_icon.png"
                            id="editImageIcon"
                            alt="edit-image-icon/2"
                            @click="moduleClicked($event)"
                            class="cursor-pointer notranslate"
                            v-else-if="
                              roiData.editAccess &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div class="tooltip" v-else>
                            <img src="@/assets/images/lock-dark.png" />
                            <span
                              class="module_complete_hover_text"
                              v-if="roiData.percentage == 0"
                            >
                              Complete Enterprise Intake Information Module to
                              unlock!
                            </span>
                          </div>
                        </li>
                        <li
                          v-if="
                            (roiData.organizationType &&
                              roiData.organizationType ==
                                'On-lending facility') ||
                            roiData.organizationType == 'Fund' ||
                            roiData.organizationType == 'Institutional Investor'
                          "
                        >
                          <span
                            >Financial Products, Services, and Processes</span
                          >
                          <div
                            class="tooltip"
                            v-if="
                              !repeatInProgressData?.isDataSynced
                                ? repeatAirtableChangeData.modSeventeenComplete &&
                                  repeatAirtableChangeData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <img
                            src="@/assets/images/check_circle.png"
                            v-else-if="
                              repeatInProgressData?.isDataSynced &&
                              roiData.modSeventeenComplete &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div
                            class="tooltip"
                            v-else-if="
                              roiData.airtableChange
                                ? roiData.airtableChange.modSeventeenComplete &&
                                  roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <div
                            v-else-if="
                              !roiData.sharedAssesment?.includes(
                                'modSeventeenEditable'
                              ) && !user.admin
                            "
                          >
                            <img src="@/assets/images/lock-dark.png" />
                          </div>
                          <img
                            src="@/assets/images/edit_black_icon.png"
                            id="editImageIcon"
                            alt="edit-image-icon/17"
                            @click="moduleClicked($event)"
                            class="cursor-pointer notranslate"
                            v-else-if="
                              roiData.editAccess &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div class="tooltip" v-else>
                            <img src="@/assets/images/lock-dark.png" />
                            <span
                              class="module_complete_hover_text"
                              v-if="roiData.percentage == 0"
                            >
                              Complete Enterprise Intake Information Module to
                              unlock!
                            </span>
                          </div>
                        </li>
                      </ul>
                      <br />
                      <h2 class="title">
                        <img src="@/assets/images/EligibleIcon.3.png" />
                        Workforce
                      </h2>
                      <ul>
                        <li>
                          <span>Employment, Leadership, and Pay Equity</span>
                          <div
                            class="tooltip"
                            v-if="
                              !repeatInProgressData?.isDataSynced
                                ? repeatAirtableChangeData.modThreeComplete &&
                                  repeatAirtableChangeData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <img
                            src="@/assets/images/check_circle.png"
                            v-else-if="
                              repeatInProgressData?.isDataSynced &&
                              roiData.modThreeComplete &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div
                            class="tooltip"
                            v-else-if="
                              roiData.airtableChange
                                ? roiData.airtableChange.modThreeComplete &&
                                  roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <div
                            v-else-if="
                              !roiData.sharedAssesment?.includes(
                                'modThreeEditable'
                              ) && !user.admin
                            "
                          >
                            <img src="@/assets/images/lock-dark.png" />
                          </div>
                          <img
                            src="@/assets/images/edit_black_icon.png"
                            id="editImageIcon"
                            alt="edit-image-icon/3"
                            @click="moduleClicked($event)"
                            class="cursor-pointer notranslate"
                            v-else-if="
                              roiData.editAccess &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div class="tooltip" v-else>
                            <img src="@/assets/images/lock-dark.png" />
                            <span
                              class="module_complete_hover_text"
                              v-if="roiData.percentage == 0"
                            >
                              Complete Enterprise Intake Information Module to
                              unlock!
                            </span>
                          </div>
                        </li>
                        <li>
                          <span>Diversity in Recruitment</span>
                          <div
                            class="tooltip"
                            v-if="
                              !repeatInProgressData?.isDataSynced
                                ? repeatAirtableChangeData.modFourComplete &&
                                  repeatAirtableChangeData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <img
                            src="@/assets/images/check_circle.png"
                            v-else-if="
                              repeatInProgressData?.isDataSynced &&
                              roiData.modFourComplete &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div
                            class="tooltip"
                            v-else-if="
                              roiData.airtableChange
                                ? roiData.airtableChange.modFourComplete &&
                                  roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <div
                            v-else-if="
                              !roiData.sharedAssesment?.includes(
                                'modFourEditable'
                              ) && !user.admin
                            "
                          >
                            <img src="@/assets/images/lock-dark.png" />
                          </div>
                          <img
                            src="@/assets/images/edit_black_icon.png"
                            id="editImageIcon"
                            alt="edit-image-icon/4"
                            @click="moduleClicked($event)"
                            class="cursor-pointer notranslate"
                            v-else-if="
                              roiData.editAccess &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div class="tooltip" v-else>
                            <img src="@/assets/images/lock-dark.png" />
                            <span
                              class="module_complete_hover_text"
                              v-if="roiData.percentage == 0"
                            >
                              Complete Enterprise Intake Information Module to
                              unlock!
                            </span>
                          </div>
                        </li>
                        <li>
                          <span>Gender Lens in Policies and Strategies</span>
                          <div
                            class="tooltip"
                            v-if="
                              !repeatInProgressData?.isDataSynced
                                ? repeatAirtableChangeData.modSevenComplete &&
                                  repeatAirtableChangeData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <img
                            src="@/assets/images/check_circle.png"
                            v-else-if="
                              repeatInProgressData?.isDataSynced &&
                              roiData.modSevenComplete &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div
                            class="tooltip"
                            v-else-if="
                              roiData.airtableChange
                                ? roiData.airtableChange.modSevenComplete &&
                                  roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <div
                            v-else-if="
                              !roiData.sharedAssesment?.includes(
                                'modSevenEditable'
                              ) && !user.admin
                            "
                          >
                            <img src="@/assets/images/lock-dark.png" />
                          </div>
                          <img
                            src="@/assets/images/edit_black_icon.png"
                            id="editImageIcon"
                            alt="edit-image-icon/7"
                            @click="moduleClicked($event)"
                            class="cursor-pointer notranslate"
                            v-else-if="
                              roiData.editAccess &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div class="tooltip" v-else>
                            <img src="@/assets/images/lock-dark.png" />
                            <span
                              class="module_complete_hover_text"
                              v-if="roiData.percentage == 0"
                            >
                              Complete Enterprise Intake Information Module to
                              unlock!
                            </span>
                          </div>
                        </li>
                        <li>
                          <span>Women Workforce Support Policies</span>
                          <div
                            class="tooltip"
                            v-if="
                              !repeatInProgressData?.isDataSynced
                                ? repeatAirtableChangeData.modEightComplete &&
                                  repeatAirtableChangeData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <img
                            src="@/assets/images/check_circle.png"
                            v-else-if="
                              repeatInProgressData?.isDataSynced &&
                              roiData.modEightComplete &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div
                            class="tooltip"
                            v-else-if="
                              roiData.airtableChange
                                ? roiData.airtableChange.modEightComplete &&
                                  roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <div
                            v-else-if="
                              !roiData.sharedAssesment?.includes(
                                'modEightEditable'
                              ) && !user.admin
                            "
                          >
                            <img src="@/assets/images/lock-dark.png" />
                          </div>
                          <img
                            src="@/assets/images/edit_black_icon.png"
                            id="editImageIcon"
                            alt="edit-image-icon/8"
                            @click="moduleClicked($event)"
                            class="cursor-pointer notranslate"
                            v-else-if="
                              roiData.editAccess &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div class="tooltip" v-else>
                            <img src="@/assets/images/lock-dark.png" />
                            <span
                              class="module_complete_hover_text"
                              v-if="roiData.percentage == 0"
                            >
                              Complete Enterprise Intake Information Module to
                              unlock!
                            </span>
                          </div>
                        </li>
                        <li>
                          <span
                            >Survivor-Centered Prevention and Response to
                            GBVH</span
                          >
                          <div
                            class="tooltip"
                            v-if="
                              !repeatInProgressData?.isDataSynced
                                ? repeatAirtableChangeData.modNineComplete &&
                                  repeatAirtableChangeData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <img
                            src="@/assets/images/check_circle.png"
                            v-else-if="
                              repeatInProgressData?.isDataSynced &&
                              roiData.modNineComplete &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div
                            class="tooltip"
                            v-else-if="
                              roiData.airtableChange
                                ? roiData.airtableChange.modNineComplete &&
                                  roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <div
                            v-else-if="
                              !roiData.sharedAssesment?.includes(
                                'modNineEditable'
                              ) && !user.admin
                            "
                          >
                            <img src="@/assets/images/lock-dark.png" />
                          </div>
                          <img
                            src="@/assets/images/edit_black_icon.png"
                            id="editImageIcon"
                            alt="edit-image-icon/9"
                            @click="moduleClicked($event)"
                            class="cursor-pointer notranslate"
                            v-else-if="
                              roiData.editAccess &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div class="tooltip" v-else>
                            <img src="@/assets/images/lock-dark.png" />
                            <span
                              class="module_complete_hover_text"
                              v-if="roiData.percentage == 0"
                            >
                              Complete Enterprise Intake Information Module to
                              unlock!
                            </span>
                          </div>
                        </li>
                        <li>
                          <span>Inclusive Health Insurance Policies</span>
                          <div
                            class="tooltip"
                            v-if="
                              !repeatInProgressData?.isDataSynced
                                ? repeatAirtableChangeData.modTenComplete &&
                                  repeatAirtableChangeData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <img
                            src="@/assets/images/check_circle.png"
                            v-else-if="
                              repeatInProgressData?.isDataSynced &&
                              roiData.modTenComplete &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div
                            class="tooltip"
                            v-else-if="
                              roiData.airtableChange
                                ? roiData.airtableChange.modTenComplete &&
                                  roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <div
                            v-else-if="
                              !roiData.sharedAssesment?.includes(
                                'modTenEditable'
                              ) && !user.admin
                            "
                          >
                            <img src="@/assets/images/lock-dark.png" />
                          </div>
                          <img
                            src="@/assets/images/edit_black_icon.png"
                            id="editImageIcon"
                            alt="edit-image-icon/10"
                            @click="moduleClicked($event)"
                            class="cursor-pointer notranslate"
                            v-else-if="
                              roiData.editAccess &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div class="tooltip" v-else>
                            <img src="@/assets/images/lock-dark.png" />
                            <span
                              class="module_complete_hover_text"
                              v-if="roiData.percentage == 0"
                            >
                              Complete Enterprise Intake Information Module to
                              unlock!
                            </span>
                          </div>
                        </li>
                        <li
                          v-if="
                            roiData?.womworkptTwo &&
                            Number(roiData?.womworkptTwo) > 0
                          "
                        >
                          <span
                            >Fair Treatment of Part-time or Temporary
                            Workers</span
                          >
                          <div
                            class="tooltip"
                            v-if="
                              !repeatInProgressData?.isDataSynced
                                ? repeatAirtableChangeData.modElevenComplete &&
                                  repeatAirtableChangeData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <img
                            src="@/assets/images/check_circle.png"
                            v-else-if="
                              repeatInProgressData?.isDataSynced &&
                              roiData.modElevenComplete &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div
                            class="tooltip"
                            v-else-if="
                              roiData.airtableChange
                                ? roiData.airtableChange.modElevenComplete &&
                                  roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <div
                            v-else-if="
                              !roiData.sharedAssesment?.includes(
                                'modElevenEditable'
                              ) && !user.admin
                            "
                          >
                            <img src="@/assets/images/lock-dark.png" />
                          </div>
                          <img
                            src="@/assets/images/edit_black_icon.png"
                            id="editImageIcon"
                            alt="edit-image-icon/11"
                            @click="moduleClicked($event)"
                            class="cursor-pointer notranslate"
                            v-else-if="
                              roiData.editAccess &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div class="tooltip" v-else>
                            <img src="@/assets/images/lock-dark.png" />
                            <span
                              class="module_complete_hover_text"
                              v-if="roiData.percentage == 0"
                            >
                              Complete Enterprise Intake Information Module to
                              unlock!
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div
                      class="take_survey_inner"
                      style="padding-left: 25px; padding-right: 25px"
                    >
                      <h2 class="title">
                        <img src="@/assets/images/EligibleIcon2.png" />
                        Leadership
                      </h2>
                      <ul>
                        <li>
                          <span>Performance, Evaluation, and Progression</span>
                          <div
                            class="tooltip"
                            v-if="
                              !repeatInProgressData?.isDataSynced
                                ? repeatAirtableChangeData.modFiveComplete &&
                                  repeatAirtableChangeData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <img
                            src="@/assets/images/check_circle.png"
                            v-else-if="
                              repeatInProgressData?.isDataSynced &&
                              roiData.modFiveComplete &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div
                            class="tooltip"
                            v-else-if="
                              roiData.airtableChange
                                ? roiData.airtableChange.modFiveComplete &&
                                  roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <div
                            v-else-if="
                              !roiData.sharedAssesment?.includes(
                                'modFiveEditable'
                              ) && !user.admin
                            "
                          >
                            <img src="@/assets/images/lock-dark.png" />
                          </div>
                          <img
                            src="@/assets/images/edit_black_icon.png"
                            id="editImageIcon"
                            alt="edit-image-icon/5"
                            @click="moduleClicked($event)"
                            class="cursor-pointer notranslate"
                            v-else-if="
                              roiData.editAccess &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div class="tooltip" v-else>
                            <img src="@/assets/images/lock-dark.png" />
                            <span
                              class="module_complete_hover_text"
                              v-if="roiData.percentage == 0"
                            >
                              Complete Enterprise Intake Information Module to
                              unlock!
                            </span>
                          </div>
                        </li>
                        <li>
                          <span
                            >Professional Development, Networking, and
                            Mentoring</span
                          >
                          <div
                            class="tooltip"
                            v-if="
                              !repeatInProgressData?.isDataSynced
                                ? repeatAirtableChangeData.modSixComplete &&
                                  repeatAirtableChangeData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <img
                            src="@/assets/images/check_circle.png"
                            v-else-if="
                              repeatInProgressData?.isDataSynced &&
                              roiData.modSixComplete &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div
                            class="tooltip"
                            v-else-if="
                              roiData.airtableChange
                                ? roiData.airtableChange.modSixComplete &&
                                  roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <div
                            v-else-if="
                              !roiData.sharedAssesment?.includes(
                                'modSixEditable'
                              ) && !user.admin
                            "
                          >
                            <img src="@/assets/images/lock-dark.png" />
                          </div>
                          <img
                            src="@/assets/images/edit_black_icon.png"
                            id="editImageIcon"
                            alt="edit-image-icon/6"
                            @click="moduleClicked($event)"
                            class="cursor-pointer notranslate"
                            v-else-if="
                              roiData.editAccess &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div class="tooltip" v-else>
                            <img src="@/assets/images/lock-dark.png" />
                            <span
                              class="module_complete_hover_text"
                              v-if="roiData.percentage == 0"
                            >
                              Complete Enterprise Intake Information Module to
                              unlock!
                            </span>
                          </div>
                        </li>
                      </ul>
                      <br />
                      <h2 class="title">
                        <img src="@/assets/images/account_tree_black.png" />
                        Value Chain
                      </h2>
                      <ul>
                        <li>
                          <span
                            >Gender-Responsive Procurement and
                            Gender-disaggregated Data in Supply Chains</span
                          >
                          <div
                            class="tooltip"
                            v-if="
                              !repeatInProgressData?.isDataSynced
                                ? repeatAirtableChangeData.modTwelveComplete &&
                                  repeatAirtableChangeData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <img
                            src="@/assets/images/check_circle.png"
                            v-else-if="
                              repeatInProgressData?.isDataSynced &&
                              roiData.modTwelveComplete &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div
                            class="tooltip"
                            v-else-if="
                              roiData.airtableChange
                                ? roiData.airtableChange.modTwelveComplete &&
                                  roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <div
                            v-else-if="
                              !roiData.sharedAssesment?.includes(
                                'modTwelveEditable'
                              ) && !user.admin
                            "
                          >
                            <img src="@/assets/images/lock-dark.png" />
                          </div>
                          <img
                            src="@/assets/images/edit_black_icon.png"
                            id="editImageIcon"
                            alt="edit-image-icon/12"
                            @click="moduleClicked($event)"
                            class="cursor-pointer notranslate"
                            v-else-if="
                              roiData.editAccess &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div class="tooltip" v-else>
                            <img src="@/assets/images/lock-dark.png" />
                            <span
                              class="module_complete_hover_text"
                              v-if="roiData.percentage == 0"
                            >
                              Complete Enterprise Intake Information Module to
                              unlock!
                            </span>
                          </div>
                        </li>
                        <li>
                          <span
                            >Gendered Approach to Value Chains and
                            Gender-Disaggregated Value Chain Data</span
                          >
                          <div
                            class="tooltip"
                            v-if="
                              !repeatInProgressData?.isDataSynced
                                ? repeatAirtableChangeData.modThirteenComplete &&
                                  repeatAirtableChangeData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <img
                            src="@/assets/images/check_circle.png"
                            v-else-if="
                              repeatInProgressData?.isDataSynced &&
                              roiData.modThirteenComplete &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div
                            class="tooltip"
                            v-else-if="
                              roiData.airtableChange
                                ? roiData.airtableChange.modThirteenComplete &&
                                  roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <div
                            v-else-if="
                              !roiData.sharedAssesment?.includes(
                                'modThirteenEditable'
                              ) && !user.admin
                            "
                          >
                            <img src="@/assets/images/lock-dark.png" />
                          </div>
                          <img
                            src="@/assets/images/edit_black_icon.png"
                            id="editImageIcon"
                            alt="edit-image-icon/13"
                            @click="moduleClicked($event)"
                            class="cursor-pointer notranslate"
                            v-else-if="
                              roiData.editAccess &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div class="tooltip" v-else>
                            <img src="@/assets/images/lock-dark.png" />
                            <span
                              class="module_complete_hover_text"
                              v-if="roiData.percentage == 0"
                            >
                              Complete Enterprise Intake Information Module to
                              unlock!
                            </span>
                          </div>
                        </li>
                      </ul>
                      <br />
                      <h2 class="title">
                        <img src="@/assets/images/society_black_icon.png" />
                        Society
                      </h2>
                      <ul>
                        <li>
                          <span>Gender Equality Beyond the Work Place</span>
                          <div
                            class="tooltip"
                            v-if="
                              !repeatInProgressData?.isDataSynced
                                ? repeatAirtableChangeData.modFourteenComplete &&
                                  repeatAirtableChangeData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <img
                            src="@/assets/images/check_circle.png"
                            v-else-if="
                              repeatInProgressData?.isDataSynced &&
                              roiData.modFourteenComplete &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div
                            class="tooltip"
                            v-else-if="
                              roiData.airtableChange
                                ? roiData.airtableChange.modFourteenComplete &&
                                  roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <div
                            v-else-if="
                              !roiData.sharedAssesment?.includes(
                                'modFourteenEditable'
                              ) && !user.admin
                            "
                          >
                            <img src="@/assets/images/lock-dark.png" />
                          </div>
                          <img
                            src="@/assets/images/edit_black_icon.png"
                            id="editImageIcon"
                            alt="edit-image-icon/14"
                            @click="moduleClicked($event)"
                            class="cursor-pointer notranslate"
                            v-else-if="
                              roiData.editAccess &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div class="tooltip" v-else>
                            <img src="@/assets/images/lock-dark.png" />
                            <span
                              class="module_complete_hover_text"
                              v-if="roiData.percentage == 0"
                            >
                              Complete Enterprise Intake Information Module to
                              unlock!
                            </span>
                          </div>
                        </li>
                        <li>
                          <span>Climate Risks</span>
                          <div
                            class="tooltip"
                            v-if="
                              !repeatInProgressData?.isDataSynced
                                ? repeatAirtableChangeData.modFifteenComplete &&
                                  repeatAirtableChangeData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <img
                            src="@/assets/images/check_circle.png"
                            v-else-if="
                              repeatInProgressData?.isDataSynced &&
                              roiData.modFifteenComplete &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div
                            class="tooltip"
                            v-else-if="
                              roiData.airtableChange
                                ? roiData.airtableChange.modFifteenComplete &&
                                  roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <div
                            v-else-if="
                              !roiData.sharedAssesment?.includes(
                                'modFifteenEditable'
                              ) && !user.admin
                            "
                          >
                            <img src="@/assets/images/lock-dark.png" />
                          </div>
                          <img
                            src="@/assets/images/edit_black_icon.png"
                            id="editImageIcon"
                            alt="edit-image-icon/15"
                            @click="moduleClicked($event)"
                            class="cursor-pointer notranslate"
                            v-else-if="
                              roiData.editAccess &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div class="tooltip" v-else>
                            <img src="@/assets/images/lock-dark.png" />
                            <span
                              class="module_complete_hover_text"
                              v-if="roiData.percentage == 0"
                            >
                              Complete Enterprise Intake Information Module to
                              unlock!
                            </span>
                          </div>
                        </li>
                        <li>
                          <span>Community Engagement through CSR</span>
                          <div
                            class="tooltip"
                            v-if="
                              !repeatInProgressData?.isDataSynced
                                ? repeatAirtableChangeData.modSixteenComplete &&
                                  repeatAirtableChangeData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <img
                            src="@/assets/images/check_circle.png"
                            v-else-if="
                              repeatInProgressData?.isDataSynced &&
                              roiData.modSixteenComplete &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div
                            class="tooltip"
                            v-else-if="
                              roiData.airtableChange
                                ? roiData.airtableChange.modSixteenComplete &&
                                  roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                    'Yes'
                                : false
                            "
                          >
                            <img
                              src="@/assets/images/inprogress.png"
                              id="editImageIcon"
                              alt="edit-image-icon/2"
                            />
                            <span class="module_complete_hover_text">
                              Your data is being processed, please check back in
                              a few minutes.
                            </span>
                          </div>
                          <div
                            v-else-if="
                              !roiData.sharedAssesment?.includes(
                                'modSixteenEditable'
                              ) && !user.admin
                            "
                          >
                            <img src="@/assets/images/lock-dark.png" />
                          </div>
                          <img
                            src="@/assets/images/edit_black_icon.png"
                            id="editImageIcon"
                            alt="edit-image-icon/16"
                            @click="moduleClicked($event)"
                            class="cursor-pointer notranslate"
                            v-else-if="
                              roiData.editAccess &&
                              roiData.certifiedThatAllResponsesTrueAndAccurate ==
                                'Yes'
                            "
                          />
                          <div class="tooltip" v-else>
                            <img src="@/assets/images/lock-dark.png" />
                            <span
                              class="module_complete_hover_text"
                              v-if="roiData.percentage == 0"
                            >
                              Complete Enterprise Intake Information Module to
                              unlock!
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <span class="grey_border"></span>
                  <div class="progress_bar_container flex">
                    <h2 class="title">Survey Completed</h2>
                    <k-progress
                      v-if="!repeatInProgressData?.isDataSynced"
                      style="width: 100%; margin: 0 !important"
                      :percent="
                        repeatInProgressData?.percentage | mathOnlyRound
                      "
                      color="#2BD4C4"
                    ></k-progress>
                    <k-progress
                      v-else
                      style="width: 100%; margin: 0 !important"
                      :percent="Math.round(roiData.percentage)"
                      color="#2BD4C4"
                    ></k-progress>
                  </div>
                  <div
                    class="InviteBtn"
                    style="margin-top: 2rem; float: right"
                    v-if="
                      roiData.percentage == 100 &&
                      repeatInProgressData?.isDataSynced &&
                      !roiData.mostRecentSurveyData
                    "
                  >
                    <div>
                      <button
                        class="text-white text-sm font-medium flex"
                        @click="submitSurvey()"
                        style="align-items: center"
                      >
                        Submit new Gender ROI™ Survey
                      </button>
                    </div>
                  </div>
                </div>
                <div class="OverviewBlock_right_side pl-10">
                  <div class="equal_strength">
                    <div class="OverviewTitle">
                      <h3 class="font-bold">{{ roiData.organizationName }}</h3>
                    </div>
                    <ul>
                      <li v-if="roiData.organizationType">
                        <span
                          >Enterprise Type:
                          <b> {{ roiData.organizationType }} </b></span
                        >
                      </li>
                      <li v-if="roiData.enterpriseSize">
                        <span
                          >Enterprise Size:
                          <b>{{
                            roiData.enterpriseSize.split("(")[0]
                          }}</b></span
                        >
                      </li>
                      <li v-if="roiData.womworkforceTwo">
                        <span
                          >Number of Employees :
                          <b>
                            {{ roiData.womworkforceTwo | mathOnlyRound }}</b
                          ></span
                        >
                      </li>
                      <li v-if="roiData.countryHqText">
                        <span
                          >Headquarters:
                          <b>{{ roiData.countryHqText }}</b></span
                        >
                      </li>
                      <li v-if="roiSector">
                        <span
                          >Sector: <b>{{ roiSector }}</b></span
                        >
                      </li>
                    </ul>
                    <br />
                    <TwoXAligned :roiData="roiData" />
                    <br />
                    <span class="flex">
                      <h3 class="font-bold">
                        Learn about the Gender ROI™ Assessment
                      </h3>
                      <img
                        class="cursor-pointer"
                        src="@/assets/images/plus-sign-new.svg"
                        style="height: 20px; top: 2px; position: relative"
                        @click="learnMoreAboutModal = true"
                      />
                    </span>
                    <div class="InviteBtn flex mt-5">
                      <div>
                        <a
                          class="text-white text-sm font-medium flex mr-1"
                          href="https://sweefcapital.com/wp-content/uploads/2023/08/Sweef-Capital-Gender-ROITM-Practitioners-Manual-1-1.pdf"
                          download
                          target="_blank"
                        >
                          English
                        </a>
                      </div>
                      <div>
                        <a
                          class="text-white text-sm font-medium flex mr-1"
                          href="https://sweefcapital.com/wp-content/uploads/2023/08/Portuguese-Sweef-Capital-Gender-ROITM-Practitioners-Manual.pdf"
                          download
                          target="_blank"
                        >
                          Portuguese
                        </a>
                      </div>
                      <div>
                        <a
                          class="text-white text-sm font-medium flex mr-1"
                          href="https://sweefcapital.com/wp-content/uploads/2023/08/Vietnamese-Sweef-Capital-Gender-ROITM-Practitioners-Manual.pdf"
                          download
                          target="_blank"
                        >
                          Vietnamese
                        </a>
                      </div>
                      <div>
                        <a
                          class="text-white text-sm font-medium flex mr-1"
                          href="https://sweefcapital.com/wp-content/uploads/2023/08/Spanish-Sweef-Capital-Gender-ROITM-Practitioners-Manual.pdf"
                          download
                          target="_blank"
                        >
                          Spanish
                        </a>
                      </div>
                      <div>
                        <a
                          class="text-white text-sm font-medium flex"
                          href="https://sweefcapital-my.sharepoint.com/personal/pdayal_sweefcapital_com/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fpdayal%5Fsweefcapital%5Fcom%2FDocuments%2FSweef%20Capital%2FPractitioners%20Manual%2FFrench%20Translation%2D%20Sweef%20Capital%20Gender%20ROI%2Epdf&parent=%2Fpersonal%2Fpdayal%5Fsweefcapital%5Fcom%2FDocuments%2FSweef%20Capital%2FPractitioners%20Manual&ga=1"
                          download
                          target="_blank"
                        >
                          French
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="gender_roi_score mt-10">
                    <div class="OverviewTitle">
                      <h2 style="width: 768px" class="tooltip_most_recent">
                        Gender ROI™ Score
                        <span
                          v-if="
                            roiData.mostRecentSurveyData || isRepeatAssessment
                          "
                          class="tooltip_text_most_recent"
                          >This is the most recently calculated Gender ROI™
                          Score based on a fully completed assessment. A new
                          assessment is currently in progress.</span
                        >
                      </h2>
                      <span></span>
                    </div>
                    <div class="mb-8">
                      <h1
                        class="font-bold text-5xl"
                        v-if="
                          roiData.percentage == 100 ||
                          roiData.mostRecentSurveyData ||
                          isRepeatAssessment
                        "
                      >
                        {{ genderRoiScore }}
                      </h1>
                    </div>
                    <div class="gender_roi_score_chart" style="width: 111%">
                      <k-progress
                        style="
                          width: 100%;
                          margin: 0 !important;
                          position: relative;
                          top: 7px;
                        "
                        :percent="
                          roiData.percentage == 100 ||
                          roiData.mostRecentSurveyData ||
                          isRepeatAssessment
                            ? Math.round((genderRoiScore * 100) / 6)
                            : 0
                        "
                        :show-text="false"
                        color="#2BD4C4"
                      ></k-progress>
                      <img
                        src="@/assets/images/roi/chart-bar.png"
                        style="position: relative; width: 100%"
                      />
                      <!-- <chart /> -->
                    </div>
                    <div
                      class="finance_average_score mt-5"
                      v-if="
                        ((roiData.organizationType &&
                          roiData.organizationType == 'On-lending facility') ||
                          roiData.organizationType == 'Fund' ||
                          roiData.organizationType ==
                            'Institutional Investor') &&
                        (roiData.percentage == 100 ||
                          isRepeatAssessment ||
                          roiData.mostRecentSurveyData)
                      "
                    >
                      <div class="OverviewTitle">
                        <h2 style="width: 700px" class="tooltip_most_recent">
                          Financial Processes, Products, & Services
                          <span
                            v-if="
                              roiData.mostRecentSurveyData || isRepeatAssessment
                            "
                            class="tooltip_text_most_recent"
                            >This is the most recently calculated Gender ROI™
                            Score based on a fully completed assessment. A new
                            assessment is currently in progress.</span
                          >
                        </h2>
                      </div>
                      <div class="mb-8">
                        <h1 class="font-bold text-5xl">
                          {{ averageFinanceScore }}
                        </h1>
                      </div>
                    </div>
                    <div
                      class="InviteBtn"
                      v-if="
                        roiData.percentage == 100 ||
                        roiData.mostRecentSurveyData ||
                        isRepeatAssessment
                      "
                      style="margin-top: 3rem"
                    >
                      <div class="tooltip_most_recent flex">
                        <button
                          class="text-white text-sm font-medium flex"
                          @click="$router.push({ name: 'roiAssessment' })"
                          style="align-items: center"
                        >
                          <img
                            src="@/assets/images/roi/assessment_black.png"
                            style="margin: 0 5px"
                          />
                          View Gender ROI™ Assessment Results
                        </button>
                        <span
                          v-if="
                            roiData.mostRecentSurveyData || isRepeatAssessment
                          "
                          class="tooltip_text_most_recent"
                          >This is the most recently calculated Gender ROI™
                          Score based on a fully completed assessment. A new
                          assessment is currently in progress.</span
                        >
                      </div>
                    </div>
                    <div
                      class="span_text"
                      v-else
                      :style="
                        roiData.percentage == 100
                          ? 'margin-top: 3rem'
                          : 'margin-top: 9rem'
                      "
                    >
                      <span class="font-bold">
                        Complete all survey modules to reveal the score.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="spiderchart_main_container mt-10"
                style="padding: 40px 40px 30px 40px"
              >
                <div class="OverviewTitle">
                  <h2 style="width: 825px">
                    Invite an enterprise for Gender ROI™
                  </h2>
                  <span></span>
                </div>
                <div class="spiderchart_content flex">
                  <p
                    class="text-gray-600 print:text-2xl print:mt-10 font-medium"
                    style="margin-right: 50px; margin-top: 8px"
                  >
                    Send a request to an enterprise to complete Gender ROI™
                    assessment.
                  </p>
                  <!-- <div class="flex flex-col ml-auto mt-auto w-6/12 lg:w-6/12"> -->
                  <div style="display: flex">
                    <dd
                      class="text-sm leading-5 text-gray-900"
                      style="margin-right: 10px"
                    >
                      <input
                        type="email"
                        class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm"
                        placeholder="Enter Email Address"
                        v-model="userEmail"
                      />
                    </dd>
                    <div class="text-sm leading-5 text-gray-900">
                      <div
                        @click="emailAssessmentReq()"
                        style="
                          background-color: #201747;
                          color: white !important;
                          cursor: pointer;
                        "
                        class="text-white px-3 py-2 text-xs gap-1 font-semibold rounded-lg text-white transition duration-200 shadow border flex flex-row items-center justify-center"
                      >
                        <button class="text-white text-sm font-medium">
                          Email Gender ROI™ assessment request
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <!-- roiDataHaveData.haveData -->
      <div key="else_if_div" v-else>
        <div class="flex flex-row justify-left items-center mt-6 ml-20">
          <div class="flex flex-col item-center justify-left">
            <div class="flex flex-row items-center justify-left print:flex-col">
              <h1
                class="text-6xl tracking-tighter font-medium text-center print:text-7xl"
              >
                Hi, {{ user.firstname }} {{ user.lastname }}
              </h1>
            </div>
            <div
              class="text-gray-600 font-medium flex flex-row items-center justify-left print:flex-col"
            >
              Your survey calculation is in progress and your survey will be
              available in a few minutes.
            </div>
            <div
              class="survey flex flex-row items-center justify-left print:flex-col mt-3 mb-2"
            >
              <a
                href="/#/roi"
                @click="pageReload()"
                class="text-white text-sm font-medium"
                style="width: max-content"
              >
                Refresh Page
              </a>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- Learn more About Modal -->
    <div class="fixed z-10 inset-0" v-if="learnMoreAboutModal">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 transition-opacity cursor-pointer"
          @click="closePopup()"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="width: 40% !important; margin-top: 6rem"
        >
          <div
            class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4"
            style="
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
              border-radius: 8px;
            "
          >
            <div class="sm:flex sm:items-start" style="height: auto">
              <div
                class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left learn_more_modal_text_container"
              >
                <div class="flex">
                  <h3
                    class="text-lg leading-6 font-bold text-gray-900"
                    id="modal-headline"
                    style="width: 360px"
                  >
                    About the Gender ROI™ Assessment
                  </h3>
                  <span class="close" @click="closePopup">&times;</span>
                </div>
                <div
                  class="mt-5 overflow-y-auto learn_more_modal_text"
                  id="style-1"
                >
                  <p>
                    The Sweef Capital Gender ROI™️ (Gender ROI™️) is a
                    diagnostic measurement and management tool that is designed
                    to support entrepreneurs, investment and development finance
                    practitioners to assess the gender equality and diversity
                    performance of companies, informing both practice and
                    performance in addressing inequalities, while highlighting
                    significant opportunities to improve outcomes for women and
                    girls.
                    <a
                      href="https://sweefcapital.com/gender-roi-practitioners-manual-download/"
                      style="color: #0077c8"
                      target="_blank"
                      >Click here</a
                    >
                    to visit SWEEF Capital to learn more!
                  </p>
                  <br />
                  <p>
                    The tool comprises of 24 quantitative and qualitative
                    indicators applied across the intersectionality of the
                    enterprise dimensions of Leadership, Workforce, Value
                    Chains, as well as through Society and Outcome Pillars of
                    Resilience, Opportunity, and Inclusion. The assessment
                    identifies gaps, determining appropriate actions and
                    interventions applied at the enterprise level which are
                    focused on delivering outcomes that support women’s economic
                    empowerment.
                  </p>
                  <br />
                  <p>
                    By applying different levers of change across the enterprise
                    dimensions targeting women’s economic and social roles,
                    contributions, and constraints and vulnerabilities, women’s
                    economic empowerment is demonstrated through 3 Outcome
                    Pillars - Resilience, Opportunities and Inclusion.
                  </p>
                  <br />
                  <p>
                    These Outcome Pillars are goalposts for women’s economic
                    empowerment which involve addressing the need for women’s
                    participation in gainful employment and to be in a position
                    of influence and decision making, as well as ensuring equal
                    access to opportunities for women’s participation and
                    advancement, and safeguarding women’s well-being through
                    safety nets and protection, thereby addressing gender norms
                    and biases. These are also the key tenets in building strong
                    and resilient companies to meet the challenges of today's
                    dynamic business environment. Each stakeholder has a role to
                    play; they are mutually beneficial and should be seen as a
                    collective engine of sustainable and inclusive development.
                  </p>
                  <br />
                  <p>
                    To calculate Gender ROI™ scores across these 24 indicators,
                    there are 16 discrete survey modules that ask questions
                    across a range of topics. These surveys may be taken all at
                    once, or over a discrete period of time, filled by one or
                    more people as appropriate.
                  </p>
                  <br />
                  <p>
                    This tool is digitized via the Equilo platform, with the
                    following benefits for users coming soon (data will be
                    collected now, with the automated benefits below in beta
                    testing and coming soon!):
                  </p>
                  <br />
                  <ul style="list-style-type: disc">
                    <li class="flex">
                      <span class="mr-2">➜</span>
                      <p>
                        Eases data collection and reporting, with multiple
                        survey modules that can be completed by multiple people
                        within an organization at any time.
                      </p>
                    </li>
                    <li class="flex mt-3">
                      <span class="mr-2">➜</span>
                      <p>
                        Automatically analyzes the reported data, displaying
                        results instantly on a personalized dashboard. Users
                        need to have an account created at app.equilo.io to
                        access their results.
                      </p>
                    </li>
                    <li class="flex mt-3">
                      <span class="mr-2">➜</span>
                      <p>
                        Easily share dashboard and summary results or
                        downloadable .CSV files with others.
                      </p>
                    </li>
                    <li class="flex mt-3">
                      <span class="mr-2">➜</span>
                      <p>
                        View organizational results alongside a contextual
                        market-level analysis to better understand,
                        contextualize, and address gaps.
                      </p>
                    </li>
                    <li class="flex mt-3">
                      <span class="mr-2">➜</span>
                      <p>
                        Data can be automatically aggregated for investors and
                        financial actors at portfolio levels when enterprises
                        complete this assessment across the portfolio.
                      </p>
                    </li>
                    <li class="flex mt-3">
                      <span class="mr-2">➜</span>
                      <p>
                        Receive intelligently automated action plan based on
                        identified gaps.
                      </p>
                    </li>
                  </ul>
                  <br />
                  <p>
                    Module 1 must be completed first, but all other modules may
                    be completed in any order. Each survey module may be
                    completed by the same or a different person. The Assessment
                    is associated with the main user's email ID. This person may
                    choose, for each module, to complete the survey, or may
                    enter an email address to send a request to another person
                    to complete the specific module.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Learn more About Modal -->

    <!-- Complete Survey Modal -->
    <div class="fixed z-10 inset-0" v-if="completeSurveyModal">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 transition-opacity cursor-pointer"
          @click="closePopup()"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="width: 35% !important; margin-top: 6rem"
        >
          <div
            class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4"
            style="
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
              border-radius: 8px;
            "
          >
            <div class="sm:flex sm:items-start" style="height: auto">
              <div
                class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left learn_more_modal_text_container"
                style="width: 100%"
              >
                <div class="flex">
                  <h1
                    class="text-lg leading-6 font-bold text-gray-900"
                    id="modal-headline"
                    style="width: 100%"
                  >
                    Who will complete this Survey?
                  </h1>
                  <span class="close" @click="closePopup">&times;</span>
                </div>
                <div
                  class="mt-5 overflow-y-auto learn_more_modal_text"
                  style="height: auto; color: black; font-weight: 500"
                >
                  <input
                    class="mr-4"
                    type="radio"
                    name="shared"
                    id="myself"
                    @change="isMyselfChecked()"
                  />Complete this module myself
                  <br />
                  <input
                    class="mr-4"
                    type="radio"
                    name="shared"
                    id="other"
                    @change="isMyselfChecked()"
                  />Request a team member to complete this module
                </div>
                <div class="InviteBtn mt-5 mb-4" v-if="!isShareVisible">
                  <div>
                    <button
                      class="text-white text-sm font-medium"
                      @click="fillMyself"
                      :class="
                        isMyselfRadioChecked
                          ? ''
                          : 'pointer_event_none opacity_light'
                      "
                    >
                      Launch
                    </button>
                  </div>
                </div>
                <div>
                  <div
                    class="EmailInvitemember mt-5 mb-4 flex"
                    v-if="isShareVisible"
                  >
                    <div class="EmailInput">
                      <input
                        type="email"
                        placeholder="Enter Email Address"
                        v-model="invitedUserEmail"
                      />
                    </div>
                    <div class="InviteBtn tooltip">
                      <div @click="onInviteClick">
                        <button class="text-white text-sm font-medium">
                          Invite
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Complete Survey Modal -->

    <!-- Request Admin Modal Start -->
    <div class="fixed z-50 inset-0 overflow-y-auto" v-if="completeModule1Popup">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 630px; width: 100%"
        >
          <span class="close" @click="closeModule1Popup()" style="right: 8px"
            >&times;</span
          >
          <div class="Modal_Readmorestyle">
            <div class="ModalInnerStyle">
              <p style="font-weight: 600; text-align: center">
                You must complete Enterprise Intake Information module to unlock
                all other modules, which may then be completed in any order.
              </p>
              <button
                style="background-color: #fe5000; color: white"
                class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mr-4 ml-4"
                @click="checkModule1PopupStatus()"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->

    <!-- repeat language -->
    <div class="fixed z-50 inset-0 overflow-y-auto" v-if="proceedModal">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 630px; width: 100%"
        >
          <span class="close" @click="closeProceedModal()" style="right: 8px"
            >&times;</span
          >
          <div class="Modal_Readmorestyle">
            <div class="ModalInnerStyle">
              <p style="font-weight: 600">
                You are about to repeat the Gender ROI™ Assessment. All your
                historical results will be saved and downloadable in .CSV. Your
                results from the first time you took the assessment will be
                displayed as historical comparison data in the visualizations.
                Your most recent fully completed assessment will continue to
                populate the data visualizations until you fully complete a new
                assessment.
              </p>
              <button
                style="background-color: #fe5000; color: white"
                class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mr-4 ml-4"
                @click="redirectRoiDashbored()"
              >
                Are you ready to proceed?
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->

    <!-- Request portfolio permission -->
    <div
      class="fixed z-50 inset-0 overflow-y-auto"
      v-if="requestportfoliopermissionModal"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 630px; width: 100%"
        >
          <span class="close" @click="closeRequestModal()" style="right: 8px"
            >&times;</span
          >
          <div class="Modal_Readmorestyle">
            <div class="ModalInnerStyle">
              <p style="font-weight: 600">
                Your organization has a feature to view portfolio level Gender
                ROI™ Assessment Portfolio results. However, your organization's
                admin has not yet granted you access to view it. Do you wish to
                request access?
              </p>
              <button
                style="background-color: #fe5000; color: white"
                class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mr-4 ml-4"
                @click="requestroixPorfolioPermission()"
              >
                Email request
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End portfolio Modal -->
    <!-- new assesment Modal-->
    <div
      class="fixed z-50 inset-0 overflow-y-auto"
      v-if="displayNewSharedPopup"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 630px; width: 100%"
        >
          <span
            class="close"
            @click="closeDisplayNewSharedPopup()"
            style="right: 8px"
            >&times;</span
          >
          <div class="Modal_Readmorestyle">
            <div class="ModalInnerStyle">
              <p style="font-weight: 600">
                We are happy to see you back here! It looks like your
                organization already completed a Gender ROI™ assessment.
                <router-link
                  to="/roi/roiAssessment"
                  style="color: rgb(0, 119, 200)"
                  >Check it out.</router-link
                >
              </p>
              <p class="mt-3" style="font-weight: 600">
                You can still proceed to create a new assessment now and as many
                times in the future as you want. When you do this, the newest
                assessment survey data submitted will be displayed on your
                dashboard, and previous surveys will be moved to historical data
                points.
              </p>
              <p class="mt-3" style="font-weight: 600">
                In addition, your teammates on this Equilo account will receive
                a new notification when you submit this completed survey so they
                can check out the updated data.
              </p>
              <button
                style="background-color: #fe5000; color: white"
                class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mr-4 ml-4"
                @click="redirectRoiDashbored()"
              >
                Ready to repeat your assessment?
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- close new assesment Modal -->
    <!-- Request Admin Modal Start -->
    <!-- <div class="fixed z-50 inset-0 overflow-y-auto" v-if="ggsfAssessModal && this.$route.path?.includes('ggsf')">
        <div
          class="
            flex
            items-end
            justify-center
            min-h-screen
            pt-4
            px-4
            pb-20
            text-center
            sm:block sm:p-0
          "
        >
          <div class="fixed inset-0 transition-opacity cursor-pointer">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
          >&#8203;
          <div
            class="
              inline-block
              align-bottom
              bg-white
              rounded-lg
              text-left
              overflow-hidden
              shadow-xl
              transform
              transition-all
              sm:my-8 sm:align-middle
            "
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            style="max-width: 630px; width: 100%"
          >
            <span class="close" @click="ggsfAssessModalClose()" style="right: 8px;">&times;</span>
            <div class="Modal_Readmorestyle">
              <div class="ModalInnerStyle">
                <p style="font-weight: 600;text-align: center;">
                  Welcome to the Gender ROI™ Assessment!
                  <br/>
                  Please note that, if you intend to submit the GGSF Gender Assessment, please find it <router-link to="/ggsf" style="color: #0077C8">here</router-link>.
                </p>
                <button
                  style="background-color: #fe5000; color: white"
                  class="
                    text-white
                    px-3
                    py-2
                    text-xs
                    gap-1
                    font-bold
                    rounded-lg
                    text-white
                    transition
                    duration-200
                    shadow
                    flex flex-row
                    items-center
                    justify-center
                    mr-4
                    ml-4
                  "
                  @click="ggsfAssessModalClose"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    <!-- End Modal -->
  </div>
</template>

<script>
import env from "@/../env";
import axios from "axios";
import Cookies from "@/helpers/cookies";
import pageLoader from "@/components/page-loader.vue";
import spiderchart from "@/components/charts/roiSpiderchart.vue";
import TwoXAligned from "@/components/2XAlignedSectionGgsfRoi.vue";
import spiderchartMultiple from "@/components/charts/roiSpiderchartMultiple.vue";
import apiService from "@/helpers/apiServices.js";
import { useUserStore } from "@/stores/user";

export default {
  name: "roi",
  data: function () {
    return {
      user: [],
      organizationDetail: [],
      roiData: [],
      endofcreated: false,
      invitedUserEmail: "",
      learnMoreAboutModal: false,
      selectedSurvey: [],
      modules: [],
      completeSurveyModal: false,
      isShareVisible: false,
      isMyselfRadioChecked: false,
      isOtherRadioChecked: false,
      requestportfoliopermissionModal: false,
      taabs: {
        Entrepreneurship: env.taabColors.humanDignity,
        Leadership: env.taabColors.knowledgeAndBeliefs,
        Employment: env.taabColors.accessToResources,
        Consumption: env.taabColors.powerAndDecision,
        "Indirect Investment": env.taabColors.indirectInvestement,
      },
      userEmail: null,
      inviteMemberEmail: "",
      completeModule1Popup: false,
      showAnimationClass: false,
      proceedModal: false,
      displayNewSharedPopup: false,
      isRepeatAssessment: false,
      repeatInProgressData: [],
      repeatAirtableChangeData: [],
      tag: "",
      roiUserSurveyData: [],
      ggsfAssessModal: false,
      previousPath: "",
    };
  },
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  components: {
    pageLoader,
    spiderchart,
    spiderchartMultiple,
    TwoXAligned,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousPath = from?.fullPath;
    });
  },
  async mounted() {
    this.user = this.userStore.user;
    if (!this.user) {
      await this.userStore.fetchUser();
      this.user = this.userStore.user;
    }
    if (this.user.organization) {
      this.organizationDetail = await this.getOrganizationDetail(
        this.user.organization._id
      );
    }
    await this.getRoiUserSurvey();
    await this.getNewCheckChangesData();
    this.repeatAirtableChangeData = this.repeatInProgressData;
    await this.getFirstRoiData();
    if (
      this.repeatInProgressData &&
      !this.repeatInProgressData?.isDataSynced &&
      this.repeatInProgressData?.percentage < 7
    ) {
      this.roiData.editAccess = false;
    }
    this.modules = [
      {
        name: "Enterprise Intake Information",
        module: "1",
        surveyName: "roi-one",
        surveyUrl: "https://form.jotform.com/230092521534144",
        var: "certifiedThatAllResponsesTrueAndAccurate",
      },
      {
        name: "Financials",
        module: "2",
        surveyName: "roi-two",
        surveyUrl: "https://form.jotform.com/230092852829158",
        var: "modTwoComplete",
      },

      {
        name: "Employment, Leadership, and Pay Equity",
        module: "3",
        surveyName: "roi-three",
        surveyUrl: "https://form.jotform.com/230092958893166",
        var: "modThreeComplete",
      },
      {
        name: "Diversity in Recruitment",
        module: "4",
        surveyName: "roi-four",
        surveyUrl: "https://form.jotform.com/230088004140542",
        var: "modFourComplete",
      },

      {
        name: "Performance, Evaluation, and Progression",
        module: "5",
        surveyName: "roi-five",
        surveyUrl: "https://form.jotform.com/230093851171148",
        var: "modFiveComplete",
      },
      {
        name: "Professional Development, Networking, and Mentoring",
        module: "6",
        surveyName: "roi-six",
        surveyUrl: "https://form.jotform.com/230093768355159",
        var: "modSixComplete",
      },

      {
        name: "Gender Lens in Policies and Strategies",
        module: "7",
        surveyName: "roi-seven",
        surveyUrl: "https://form.jotform.com/230094342764152",
        var: "modSevenComplete",
      },
      {
        name: "Women Workforce Support Policies",
        module: "8",
        surveyName: "roi-eight",
        surveyUrl: "https://form.jotform.com/230094779337162",
        var: "modEightComplete",
      },
      {
        name: "Survivor-Centered Prevention and Response to GBVH",
        module: "9",
        surveyName: "roi-nine",
        surveyUrl: "https://form.jotform.com/230092609309151",
        var: "modNineComplete",
      },
      {
        name: "Inclusive Health Insurance Policies",
        module: "10",
        surveyName: "roi-ten",
        surveyUrl: "https://form.jotform.com/230093172898160",
        var: "modTenComplete",
      },
      {
        name: "Fair Treatment of Part-time or Temporary Workers",
        module: "11",
        surveyName: "roi-eleven",
        surveyUrl: "https://form.jotform.com/230092833682154",
        var: "modElevenComplete",
      },

      {
        name: "Gender-Responsive Procurement and Gender-disaggregated Data in Supply Chains",
        module: "12",
        surveyName: "roi-twelve",
        surveyUrl: "https://form.jotform.com/230092485763157",
        var: "modTwelveComplete",
      },
      {
        name: "Gendered Approach to Value Chains and Gender-Disaggregated Value Chain Data",
        module: "13",
        surveyName: "roi-thirteen",
        surveyUrl: "https://form.jotform.com/230093202135138",
        var: "modThirteenComplete",
      },

      {
        name: "Gender Equality Beyond the Work Place",
        module: "14",
        surveyName: "roi-fourteen",
        surveyUrl: "https://form.jotform.com/230092997747167",
        var: "modFourteenComplete",
      },
      {
        name: "Climate Risks",
        module: "15",
        surveyName: "roi-fifteen",
        surveyUrl: "https://form.jotform.com/230092647462153",
        var: "modFifteenComplete",
      },
      {
        name: "Community Engagement through CSR",
        module: "16",
        surveyName: "roi-sixteen",
        surveyUrl: "https://form.jotform.com/230093833638156",
        var: "modSixteenComplete",
      },
      {
        name: "Financial Products, Services, and Processes",
        module: "17",
        surveyName: "roi-seventeen",
        surveyUrl: `https://form.jotform.com/230093046765153?typeA87=${encodeURIComponent(
          this.checkFundorCompanies
        )}`,
        var: "modSeventeenComplete",
      },
    ];
    this.$store.commit("setBreadcrumbs", [
      {
        name: "Gender ROI™",
        url: "/roi",
      },
    ]);
    this.endofcreated = true;

    if (
      this.endofcreated &&
      this.roiData &&
      this.roiData != "" &&
      this.roiData.percentage == 0 &&
      this.repeatInProgressData &&
      this.repeatInProgressData != "" &&
      this.repeatInProgressData?.isDataSynced
    ) {
      this.completeModule1Popup = true;
    }
  },
  computed: {
    checkFundorCompanies() {
      const typeFlag =
        this.roiData.organizationType == "On-lending facility"
          ? "Financial Institution"
          : this.roiData.organizationType;
      return typeFlag;
    },
    roiSector() {
      return this.roiData.sector == "Other"
        ? this.roiData.otherSector
        : this.roiData.sector;
    },
    averageFinanceScore() {
      let val = "";
      if (this.roiData.averageFinanceScore) {
        val = Number(this.roiData.averageFinanceScore).toFixed(2);
      }
      if (
        this.roiData.averageFinanceScore == 0 &&
        this.roiData.averageFinanceScore !== null
      ) {
        val = 0;
      }
      if (
        (this.roiData.averageFinanceScore == null ||
          this.roiData.averageFinanceScore == "") &&
        this.roiData.genderRoiScore !== 0
      ) {
        val = "";
      }
      return val;
    },
    genderRoiScore() {
      let val = "";
      if (this.roiData.genderRoiScore) {
        val = Number(this.roiData.genderRoiScore).toFixed(2);
      }
      if (
        this.roiData.genderRoiScore == 0 &&
        this.roiData.genderRoiScore !== null
      ) {
        val = 0;
      }
      if (
        (this.roiData.genderRoiScore == null ||
          this.roiData.genderRoiScore == "") &&
        this.roiData.genderRoiScore !== 0
      ) {
        val = "";
      }
      return val;
    },
    companySize() {
      let state = "";
      let val = Math.round(Number(this.roiData.womworkforceTwo));
      if (val <= 10) {
        state = "Micro";
      } else if (val > 10 && val <= 250) {
        state = "SME";
      } else if (val > 250) {
        state = "Large";
      }
      return state;
    },
  },
  methods: {
    determineEntityType() {
      let type = "";
      if (
        this.roiUserSurveyData.filter((data) => data.surveyId == "roi-one")?.[0]
      ) {
        type = this.roiUserSurveyData
          .filter((data) => data.surveyId == "roi-one")?.[0]
          ?.surveyResponse.filter(
            (item) => item.questionId == "General3"
          )?.[0]?.answer;
      }
      if (
        type == "Institutional Investor" ||
        this.roiData?.organizationType == "Institutional Investor"
      ) {
        this.tag = "institutional-investor";
      }
      if (type == "Fund" || this.roiData?.organizationType == "Fund") {
        this.tag = "fund";
      }
      if (
        type == "Micro-enterprise" ||
        this.roiData?.organizationType == "Micro-enterprise"
      ) {
        this.tag = "micro";
      }
      if (
        type == "Small or medium enterprise" ||
        this.roiData?.organizationType == "Small or medium enterprise"
      ) {
        this.tag = "sme";
      }
      if (
        type == "Corporate (large)" ||
        this.roiData?.organizationType == "Corporate (large)"
      ) {
        this.tag = "large";
      }
      if (
        type == "On-lending facility" ||
        this.roiData?.organizationType == "On-lending facility"
      ) {
        this.tag = "on-lending-facility";
      }
      if (!this.tag && this.roiUserSurveyData) {
        this.roiUserSurveyData.forEach((data) => {
          if (data.surveyId == "roi-one") {
            this.tag = data?.tag;
          }
        });
      }
    },

    getRoiUserSurvey() {
      apiService.fetchRoiUserSurvey("roi").then((response) => {
        this.roiUserSurveyData = response.data.data;
        this.determineEntityType();
      });
    },
    getNewCheckChangesData() {
      return axios
        .get(
          env.apiUrl +
            "/roi/check-changes-new?teamId=" +
            this.user?.organization?._id,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(async (res) => {
          if (res.data.data) {
            this.repeatInProgressData = res?.data?.data;
            this.repeatInProgressData.certifiedThatAllResponsesTrueAndAccurate =
              this.repeatInProgressData
                ?.certifiedThatAllResponsesTrueAndAccurate
                ? "Yes"
                : "No";
          }
        })
        .catch(() => {
          this.endofcreated = true;
        });
    },
    checkInProgressSurvey() {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/roi/check-changes", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data);
          });
      });
    },
    async closeModule1Popup() {
      this.checkModule1PopupStatus();
    },
    closeDisplayNewSharedPopup() {
      this.displayNewSharedPopup = false;
    },
    checkModule1PopupStatus() {
      this.showAnimationClass = true;
      this.completeModule1Popup = false;

      if (this.showAnimationClass) {
        setTimeout(() => {
          this.showAnimationClass = false;
        }, 5000);
      }
    },
    getOrganizationDetail(organization_id) {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/organizations/" + organization_id, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },

    sendInviteToMember(body) {
      return new Promise(function (resolve) {
        axios
          .post(env.apiUrl + "/roi/send-invite", body, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    async inviteMember() {
      let validate = this.validEmail(this.inviteMemberEmail);
      if (validate) {
        if (
          this.roiData.percentage == 100 ||
          (this.roiData.scoreData &&
            this.roiData.scoreData != "" &&
            this.roiData.scoreData.length > 1)
        ) {
          let body = {
            user_id: this.user._id,
            sharedEmails: [this.inviteMemberEmail],
            recordId:
              this.roiData.percentage == 100
                ? this.roiData.recordId
                : this.roiData.allRecordIds[1],
          };
          let getInvitedUserData = await this.sendInviteToMember(body);
          if (getInvitedUserData.acknowledged) {
            this.closePopup();
            this.$swal.fire({
              icon: "success",
              text: "Success! Your invitation was sent!",
              showConfirmButton: true,
            });
          } else {
            this.$swal.fire({
              icon: "error",
              text: getInvitedUserData.message
                ? getInvitedUserData.message
                : "Please try again!",
              showConfirmButton: true,
            });
          }
          this.invitedUserEmail = "";
        } else {
          this.$swal.fire({
            icon: "error",
            text: "Sorry, you don't have an assessment to share. Please complete an assessment so you can share it!",
            showConfirmButton: true,
          });
        }
      } else {
        alert("Please enter valid email address!");
      }
    },
    fillMyself() {
      this.redirectToSurvey(this.selectedSurvey);
    },
    isMyselfChecked() {
      if (document.getElementById("myself").checked == true) {
        this.isShareVisible = false;
        this.isMyselfRadioChecked = true;
      }
      if (document.getElementById("other").checked == true) {
        this.isOtherRadioChecked = true;
        this.isShareVisible = true;
      }
    },
    moduleClicked(event) {
      let modVal = event.target.alt.split("/")[1];
      if (event.target.alt.split("/")[0] == "edit-image-icon") {
        this.selectedSurvey = [];
        this.modules.forEach((data) => {
          if (data.module == modVal) {
            this.selectedSurvey = data;
          }
        });
        if (this.roiData.shareAccess) {
          Object.entries(this.roiData).forEach((item) => {
            if (item[0] == this.selectedSurvey.var) {
              if (item[1] == false) {
                this.completeSurveyModal = true;
              } else {
                this.redirectToSurvey(this.selectedSurvey);
              }
            }
          });
        } else {
          this.redirectToSurvey(this.selectedSurvey);
        }
      }
    },
    redirectToSurvey(data) {
      this.closePopup();
      this.determineEntityType();
      let query = this.isRepeatAssessment
        ? { repeat: this.isRepeatAssessment }
        : {};
      let routeData = this.$router.resolve({
        name: "roiSurvey",
        params: {
          tag: data.module == 1 ? "base" : this.tag,
          surveyName: data.surveyName,
        },
        query: query,
      });
      window.open(routeData.href, "_self");
    },
    closeProceedModal() {
      this.proceedModal = false;
      this.displayNewSharedPopup = false;
    },

    submitSurvey() {
      this.displayNewSharedPopup = true;
    },
    redirectModule1() {
      let routeData = this.$router.resolve({
        name: "roiSubmitSurvey",
        params: { tag: "base", surveyName: "roi-one" },
        query: { repeat: this.isRepeatAssessment },
      });
      window.open(routeData.href, "_self");
    },

    redirectRoiDashbored() {
      this.roiData.certifiedThatAllResponsesTrueAndAccurate = "";
      this.roiData.percentage = 0;
      this.isRepeatAssessment = true;
      this.closeProceedModal();
    },
    closePopup: function () {
      this.learnMoreAboutModal = false;
      this.completeSurveyModal = false;
      this.isShareVisible = false;
    },
    pageReload() {
      window.location.reload();
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(email)) {
        return true;
      } else {
        return false;
      }
    },
    async onInviteClick() {
      let validate = this.validEmail(this.invitedUserEmail);
      if (validate) {
        let body = {
          email: this.invitedUserEmail,
          surveyName: this.selectedSurvey.name,
          surveyUrl:
            "roi/survey/" +
            this.selectedSurvey.module +
            "/" +
            (this.selectedSurvey.module == 1 ? "base" : this.tag) +
            "/" +
            this.selectedSurvey.surveyName,
        };
        let getInvitedUserData = await this.sendInviteToUser(body);
        if (getInvitedUserData.status) {
          this.closePopup();
          this.$swal.fire({
            icon: "success",
            text: getInvitedUserData.message,
            showConfirmButton: true,
          });
        } else {
          this.$swal.fire({
            icon: "error",
            text: getInvitedUserData.message,
            showConfirmButton: true,
          });
        }
        this.invitedUserEmail = "";
      } else {
        alert("Please enter valid email address!");
      }
    },
    sendInviteToUser(body) {
      return new Promise(function (resolve) {
        axios
          .post(env.apiUrl + "/roi/shared-assessments", body, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    async getFirstRoiData() {
      try {
        const { data, status } = await axios.get(env.apiUrl + "/roi/flags", {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        });

        if (status === 200) {
          this.roiData = data.data || data.haveData;
          this.updateGesiScores(this.roiData?.roiGesiScoreData?.Resilience);
        }
      } catch (error) {
        this.endofcreated = true;
      }
    },

    updateGesiScores(resilienceData) {
      resilienceData.forEach((data) => {
        if (data.chartTitle === "Managing Partners") {
          data.gesiScore = resilienceData.find(
            (obj) => obj.chartTitle === "Senior Managers"
          ).gesiScore;
        }
        if (data.chartTitle === "Partners") {
          data.gesiScore = this.gesiScore.Resilience.find(
            (obj) => obj.chartTitle === "Middle Managers"
          ).gesiScore;
        }
      });
    },

    async emailAssessmentReq() {
      let validate = this.validEmail(this.userEmail);
      if (validate) {
        let usersAry = [];
        usersAry.push(this.userEmail);
        let body = {
          sendEmails: usersAry,
          type: "invite",
        };
        let getROISurvey = await this.sendROISurveyEmail(body);
        if (getROISurvey) {
          this.$swal.fire({
            icon: "success",
            text: "Success! Your invitation was sent!",
            showConfirmButton: true,
          });
        } else {
          this.$swal.fire({
            icon: "error",
            text: "Please try again!",
            showConfirmButton: true,
          });
        }
        this.userEmail = null;
      } else {
        alert("Please enter valid email address!");
      }
    },

    sendROISurveyEmail(body) {
      return new Promise((resolve) => {
        axios
          .post(env.apiUrl + "/roi/send-survey", body, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    async closeRequestModal() {
      this.requestportfoliopermissionModal = false;
    },
    async checkportfolioPermissionpopup() {
      if (!this.user.accessROIPortfolio) {
        this.requestportfoliopermissionModal = true;
      } else {
        this.$router.push({ name: "roiPortfolio" });
      }
    },
    async requestroixPorfolioPermission() {
      axios
        .get(env.apiUrl + "/roi/roiPortfolioAccess-request-to-admin", {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          this.closeRequestModal();
          if (res.data.data.status) {
            this.$swal.fire({
              icon: "success",
              text: res.data.data.message,
              showConfirmButton: true,
            });
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Something went wrong!",
              showConfirmButton: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.tooltip_most_recent {
  position: relative;
  display: inline-block;
}
.tooltip_most_recent .tooltip_text_most_recent {
  visibility: hidden;
  width: 280px !important;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 20px;
  height: 200px !important;
  font-family: "Montserrat", arial !important;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  text-align: center;
  line-height: 1.5 !important;
  font-weight: 500;
  border: none !important;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
.tooltip_most_recent:hover .tooltip_text_most_recent {
  visibility: visible;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .gender_roi_context_hover_text {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 350px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 99;
  left: 225px;
  top: -40px;
}

.tooltip:hover .gender_roi_context_hover_text {
  visibility: visible;
}

.tooltip .gender_roi_context_hover_text::after {
  right: 95%;
}

.tooltip .module_complete_hover_text {
  font-family: "Montserrat", arial;
  padding: 8px;
  font-size: small;
  letter-spacing: normal;
  visibility: hidden;
  width: 350px !important;
  background-color: rgb(32, 23, 71);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 99;
  left: 59px;
  top: 9px;
}
.tooltip:hover .module_complete_hover_text {
  visibility: visible;
}
.tooltip .module_complete_hover_text::after {
  right: 95%;
}

.tooltip .share-email-tooltip-text {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 737px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 99;
  left: -666px;
  top: 37px;
}

.tooltip:hover .share-email-tooltip-text {
  visibility: visible;
}

.tooltip .share-email-tooltip-text::after {
  right: 95%;
}

.survey {
  a {
    border: 1px solid #1b1b1b;
    height: 36px;
    color: #1b1b1b !important;
    border-radius: 20px;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 9px 11px;
    transition: 0.3s ease;

    &:hover {
      background: rgb(32, 23, 71);
      color: #fff !important;
      border-color: rgb(32, 23, 71);
    }
  }
}
.roi_portfolio_button {
  p {
    display: flex;
    align-items: center;
    border: 1px solid #1b1b1b;
    border-radius: 20px;
    color: #1b1b1b;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 11px 17px;
    transition: 0.3s ease;

    img {
      margin-right: 5px;
      width: 15px;
      height: 15px;
    }

    &:hover {
      background: rgb(32, 23, 71);
      color: #fff;
      border-color: rgb(32, 23, 71);
    }
  }
}

.DownloadBtn {
  p {
    display: flex;
    align-items: center;
    border: 1px solid #1b1b1b;
    border-radius: 20px;
    color: #1b1b1b;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 11px 17px;
    transition: 0.3s ease;

    img {
      margin-right: 5px;
      width: 15px;
      height: 15px;
    }

    &:hover {
      background: rgb(32, 23, 71);
      color: #fff;
      border-color: rgb(32, 23, 71);
    }
  }
}

.k-progress {
  margin: 0 !important;
}

.orange-dot {
  width: 10px;
  height: 10px;
  background-color: #fe5000;
  border-radius: 10px;
  top: 6px;
  position: relative;
}

.blue-dot {
  width: 10px !important;
  height: 10px;
  background-color: #201747;
  border-radius: 10px;
  top: 6px;
  position: relative;
  cursor: pointer;
}

.teal-dot {
  width: 10px !important;
  height: 10px;
  background-color: #2bd4c4;
  border-radius: 10px;
  top: 6px;
  position: relative;
  cursor: pointer;
}

.yellow-dot {
  width: 10px;
  height: 10px;
  background-color: #f5ed3e;
  border-radius: 10px;
  top: 6px;
  position: relative;
}

#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #ffff;
  border-radius: 10px;
  background-color: #ffff;
}

#style-1::-webkit-scrollbar {
  width: 6px;
  background-color: #ffff;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #c0c0c1;
  background-color: #c0c0c1;
}

.pointer_event_none {
  pointer-events: none;
}

.opacity_light {
  opacity: 0.5;
}

.learn_more_modal_text_container {
  h2 {
    width: 60%;
  }

  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    top: -8px;
    position: relative;
    width: 50%;
    text-align: end;
    cursor: pointer;
  }

  .learn_more_modal_text {
    height: 68vh;

    ::-webkit-scrollbar {
      width: 14px;
    }

    input {
      accent-color: black;
    }
  }
}

.assementPageStyle {
  background: #f5f6f9;
  margin-top: 65px;

  .EmailInput {
    padding: auto 0;
    // border: 1px solid gainsboro;
    border-radius: 5px;
    color: black;

    input {
      background: #fff;
      border: 0.9px solid #d0d0d0;
      padding: 10px 14px;
      border-radius: 4px;
      color: #1b1b1b;
      font-size: 13px;
      font-weight: 500;
      width: 240px;
      height: 36px;
      margin-right: 10px;
      // height: 100%;

      ::placeholder {
        color: #d0d0d0;
        font-size: 13px;
        font-weight: 500;
      }
    }
  }

  .container {
    margin: 0 auto;

    .modal {
      display: none;
      /* Hidden by default */
      position: fixed;
      /* Stay in place */
      z-index: 1;
      /* Sit on top */
      padding-top: 100px;
      /* Location of the box */
      left: 0;
      top: 0;
      width: 100%;
      /* Full width */
      height: 100%;
      /* Full height */
      overflow: auto;
      /* Enable scroll if needed */
      background-color: rgb(0, 0, 0);
      /* Fallback color */
      background-color: rgba(0, 0, 0, 0.4);
      /* Black w/ opacity */
    }

    /* Modal Content */
    .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: 40%;
      border-radius: 20px;

      .selected-class {
        background-color: green;
      }
    }

    /* The Close Button */
    .close {
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      top: -8px;
      position: relative;
    }

    .close:hover,
    .close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }

    .save-opportunity-button {
      text-align: right;
    }

    .save-opportunity-button button {
      background: #201747;
      color: #fff;
      border-color: #201747;
      border: 1px solid #1b1b1b;
      height: 36px;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;
    }
  }

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    .PoweredbyTitleWrepper {
      max-width: 847px;
      widows: 100%;
      display: flex;
      align-items: center;

      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        // display: none;
      }

      h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 61px;
        color: #000;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powerd-logo {
        font-size: 12px;
        color: #000;
        margin-right: 50px;
      }

      img {
        max-width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .invite_members {
      input {
        background: #fff;
        border: 1px solid #d0d0d0;
        height: 36px;
        border-radius: 4px;
        padding: 5px;

        .invite-member-btn {
        }

        &::placeholder {
          font-size: 13px;
          color: #d0d0d0;
        }
      }
    }

    .EmailInvitemember {
      display: flex;
      margin-top: 33px;

      .EmailInput {
        padding: auto 0;
        border: 1px solid gainsboro;
        border-radius: 5px;

        input {
          background: #fff;
          border: 0.9px solid #d0d0d0;
          padding: 10px 14px;
          border-radius: 4px;
          color: #1b1b1b;
          font-size: 13px;
          font-weight: 500;
          width: 240px;
          height: 36px;
          margin-right: 10px;
          // height: 100%;

          ::placeholder {
            color: #d0d0d0;
            font-size: 13px;
            font-weight: 500;
          }
        }
      }

      .InviteBtn {
        button {
          border: 1px solid #1b1b1b;
          height: 36px;
          color: #1b1b1b;
          border-radius: 20px;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          padding: 9px 11px;
          transition: 0.3s ease;

          &:hover {
            background: rgb(32, 23, 71);
            color: #fff;
            border-color: rgb(32, 23, 71);
          }
        }
      }
    }
  }

  .InviteBtn {
    button {
      border: 1px solid #1b1b1b;
      height: 36px;
      color: #1b1b1b;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      transition: 0.3s ease;

      &:hover {
        background: rgb(32, 23, 71);
        color: #fff;
        border-color: rgb(32, 23, 71);
      }
    }
    a {
      border: 1px solid #1b1b1b;
      height: 36px;
      color: #1b1b1b;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      transition: 0.3s ease;
      width: max-content;

      &:hover {
        background: rgb(32, 23, 71);
        color: #fff;
        border-color: rgb(32, 23, 71);
      }
    }
  }

  .fav-image {
    margin: auto !important;
    margin-right: 15px !important;
    padding: 0;
    cursor: pointer;
  }

  .AssementPageWrepper {
    // margin-top: 30px;
    padding-bottom: 60px;

    .OverviewBlock {
      // background: #ffffff;
      // border-radius: 10px;
      // padding: 0 40px 40px;
      // padding-top: 40px;
      // margin-bottom: 18px;
      .OverviewBlock_left_side {
        width: 58%;
        border-radius: 10px;
        padding: 40px;
        background: #ffffff;
      }

      .OverviewBlock_right_side {
        width: 42%;

        .equal_strength {
          background: #ffffff;
          border-radius: 10px;
          padding: 40px;

          .OverviewTitle {
            margin-bottom: 20px;
          }

          li {
            margin: 0;
            padding: 2px 0;
          }
        }

        .gender_roi_score {
          background: #ffffff;
          border-radius: 10px;
          padding: 40px;
          min-height: 425px;

          .OverviewTitle {
          }

          .span_text {
            color: black;
          }
        }
      }

      .take_survey_container {
        .take_survey_inner {
          h2 {
            display: flex;

            img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
              object-fit: contain;
            }
          }

          width: 50%;
          padding: 5px;

          ul {
            margin-top: 20px;

            li {
              display: flex;
              font-size: 13px;
              font-weight: 700;
              line-height: 16px;
              letter-spacing: 0px;
              text-align: left;
              margin-left: 0;
              padding-bottom: 10px;

              .edit_button {
                width: 16px;
                height: 16px;
              }

              span {
                width: 70%;
              }

              img {
                height: 20px;
                padding-left: 40px;
              }
            }
          }
        }
      }

      .grey_border {
        display: inline-block;
        border-bottom: 2px solid #d0d0d0;
        width: 100%;
        height: 2px;
        margin: 35px 0;
      }

      .progress_bar_container {
        h2 {
          margin: auto 20px auto 0;
          width: 40%;
        }

        .span_text {
          padding-left: 68px;
          width: 100%;
          font-size: 15px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0px;
          text-align: right;
        }
      }

      .OverviewTitle {
        display: flex;
        align-items: center;

        h2 {
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;
          color: #1b1b1b;
          margin-right: 17px;
          position: relative;
        }

        span {
          display: inline-block;
          border-bottom: 2px solid #1b1b1b;
          width: 100%;
          height: 2px;
        }
      }

      .spiderchartEligibleWrepper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .Xeligibleconten {
          width: 50%;

          .EligibleListWrepper {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            .EligibleListImg {
              position: relative;
              z-index: 1;

              &::after {
                content: "";
                display: inline-block;
                border: 1px solid #d0d0d0;
                position: absolute;
                height: 30px;
                top: 18px;
                left: 8px;
                z-index: -1;
              }

              img {
                border-radius: 50%;
                object-fit: contain;
                margin: 0 !important;
                margin-right: 15px !important;
              }
            }

            .bigImg {
              margin-left: -10px;

              &::after {
                top: 40px;
                left: 18px;
                height: 28px;
              }

              img {
                width: 40px !important;
                height: 40px;
              }
            }

            .lastAffter {
              &::after {
                content: none;
              }
            }

            .EligibleTitle {
              display: flex;
              align-items: center;

              img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                object-fit: contain;
              }

              p {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                color: #1b1b1b;
              }

              h3 {
                font-size: 25px;
                font-style: normal;
                font-weight: bold;
                line-height: 30px;
                color: #1b1b1b;
              }
            }
          }

          .bigMargin {
            margin-bottom: 25px;
          }

          .BorderLine {
            border: 1px solid #d0d0d0;
            max-width: 375px;
            margin: 30px 0;
          }

          .PartyVerifiedToolTip {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .PartyTooltipImg {
              img {
                border-radius: 50%;
              }
            }

            .TooltipImgText {
              display: flex;
              align-items: center;

              img {
                width: 15px;
                height: 15px;
                margin-left: 5px;
              }

              p {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                color: #1b1b1b;
              }

              .tooltiptext {
                max-width: 400px;
                top: 20px;
              }
            }
          }

          .DownloadBtn {
            p {
              display: flex;
              align-items: center;
              border: 1px solid #1b1b1b;
              border-radius: 20px;
              color: #1b1b1b;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              padding: 11px 17px;
              transition: 0.3s ease;

              img {
                margin-right: 5px;
                width: 15px;
                height: 15px;
              }

              &:hover {
                background: rgb(32, 23, 71);
                color: #fff;
                border-color: rgb(32, 23, 71);
              }
            }
          }
        }
      }
    }

    .spiderchart_main_container {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px 40px 80px 40px;

      .spiderchart_content {
        .left_side {
          width: 26%;
        }

        .dot_with_text {
          position: absolute;
          margin-top: 30px;
        }

        .teal_dot_with_text {
          position: absolute;
          margin-top: 60px;
        }

        .right_side {
          width: 100%;

          .spiderchartContent {
            // padding: 0 20px;
            // width: 85%;

            .chartjs-render-monitor {
              // margin-left: -33px;
              // height: 530px !important;
            }

            .resilince-icon {
              max-width: 200px;
              left: 200px;
              top: 50px;
            }

            .opportunity-icon {
              max-width: 200px;
              top: 50px;
              right: 200px;
            }

            .inclusion-icon {
              max-width: 200px;
              right: 49%;
              bottom: -50px;
            }

            .spiderchartContent {
              width: 60%;
            }

            .Ind_Inv_Icon {
              // z-index: 9999;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              position: absolute;
              cursor: pointer !important;

              img {
                // width: 30px !important;
                // height: 30px;
                object-fit: contain;
                margin-right: 0 !important;
                margin-top: 30px !important;
              }

              span {
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                text-align: center;
                margin-top: 6px;
                // margin-left: 20px !important;
              }
            }

            .Indirect_Investment {
              top: 0;
              left: 0;
            }
          }
        }
      }
    }

    .Profile_Criteria_Wrepper {
      display: flex;
      align-items: flex-start;
      margin-bottom: 18px;

      &.gbvh {
        .barchart2xCriteria_Wrepper {
          margin-left: 0;
          margin-right: auto;
        }

        .Profile_CountryWrepper {
          max-width: 523px;
          width: 100%;
        }

        h2 {
          width: 518px;
        }

        .PartyVerifiedToolTip {
          display: flex;
          align-items: flex-start;

          .PartyTooltipImg {
            margin-right: 10px;

            img {
              margin: 0;
              min-height: 35px;
              max-width: 18px;
            }
          }
        }

        .BorderLine {
          border: 1px solid #d0d0d0;
          max-width: 100%;
          margin: 30px 0;
        }

        .gbvh-right-side-bread-list {
          display: flex;
          align-items: center;

          span {
            font-size: 15px;
            font-weight: bolder;
            display: flex;
            align-items: center;

            &:first-child {
              margin-right: 30px;
            }

            &:nth-child(2) {
              margin-left: 190px;
            }

            img {
              margin-right: 10px;
              max-width: 20px;
            }
          }
        }

        .gbvh-bread-list {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          span {
            color: black;
            font-size: 13px;
            display: flex;
            align-items: center;

            &:first-child {
              margin-right: 30px;
            }

            img {
              margin-right: 10px;
              max-width: 20px;
            }
          }
        }

        .gbvh-progress-wrapper {
          .gbvh-progress-head {
            color: #1b1b1b;

            span {
              display: flex;

              img {
                margin-left: 10px;
              }
            }
          }

          .gbvh-progress-content {
            display: flex;
            margin-top: 30px;
            justify-content: space-between;

            &--left,
            &--right {
              font-size: 13px;
              width: 45%;
              font-weight: 600;
            }

            .gbvh-progress-content--left {
              color: #1b1b1b;
            }

            .gbvh-progress-content--right {
              width: 60%;
              line-height: 1;

              .bar-div {
                span {
                  color: #646363;
                  width: 50px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }

      .Profile_CountryWrepper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .Profile_Block {
          background: #ffffff;
          border-radius: 10px;
          padding: 40px;
          margin-bottom: 18px;
          max-width: 523px;
          width: 100%;

          .BorderLine {
            border: 1px solid #d0d0d0;
            max-width: 100%;
            margin: 30px 0;
          }

          .actions-left-side {
            width: 260px;
          }

          .actions-right-side {
            display: flex;
            padding: 10px;
            width: 50px;
            height: 50px;

            img {
              margin: auto;
            }
          }

          .actions-efforts-section {
            padding-top: 20px;
            font-size: small;

            span {
              margin-right: 20px;
            }
          }

          .Profile_blockListText {
            .About_CompanyListText {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              margin-bottom: 30px;

              b {
                font-style: normal;
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                color: #1b1b1b;
                margin-bottom: 5px;
              }

              a {
                img {
                  width: 20px !important;
                  height: 20px;
                  object-fit: contain;
                }
              }
            }

            ul {
              border-top: 1px solid #d0d0d0;
              padding-top: 30px;
              margin: 0;
              margin-bottom: 30px;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: space-between;

              li {
                width: 48%;
                margin: 0;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                color: #1b1b1b;
                margin-bottom: 15px;
                word-break: break-all;

                b {
                  font-style: normal;
                  font-weight: bold;
                  font-size: 13px;
                  line-height: 16px;
                  color: #1b1b1b;
                  display: block;
                  margin-bottom: 5px;
                  word-break: break-all;
                }
              }
            }

            .DownloadBtnDeck {
              display: flex;

              a {
                display: flex;
                align-items: center;
                border: 1px solid #1b1b1b;
                border-radius: 20px;
                color: #1b1b1b;
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                padding: 11px 10px;
                transition: 0.3s ease;

                img {
                  margin-right: 8px;
                  width: 15px;
                  height: 15px;
                }

                &:hover {
                  background: rgb(32, 23, 71);
                  color: #fff;
                  border-color: rgb(32, 23, 71);
                }
              }
            }
          }

          .CountryOverviewText {
            margin-bottom: 30px;

            h3 {
              font-style: normal;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              color: #1b1b1b;
              margin-bottom: 5px;
            }

            p,
            .leading-7 {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              display: -webkit-box;
              -webkit-line-clamp: 15;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            a {
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              color: #1b1b1b;
              display: flex;
              align-items: center;

              img {
                margin-left: 5px !important;
                width: 15px !important;
                height: 15px;
                object-fit: contain;
              }
            }
          }

          .WanttoUnderstandHere {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #1b1b1b;

            a {
              text-decoration: underline;
            }
          }

          .ContryNameImg {
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            img {
              width: 60px;
              height: 40px;
              object-fit: contain;
              margin-right: 15px;
            }

            h1 {
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 22px;
              color: #1b1b1b;
            }
          }

          .ExploreBtnDeck {
            display: flex;
            margin-top: 30px;

            div {
              cursor: pointer;
              display: flex;
              align-items: center;
              border: 1px solid #1b1b1b;
              border-radius: 20px;
              color: #1b1b1b;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              padding: 11px 10px;
              transition: 0.3s ease;

              img {
                margin-right: 8px;
                width: 15px;
                height: 15px;
              }

              &:hover {
                background: rgb(32, 23, 71);
                color: #fff;
                border-color: rgb(32, 23, 71);
              }
            }
          }
        }
      }

      .barchart2xCriteria_Wrepper {
        background: #ffffff;
        border-radius: 10px;
        padding: 40px;
        max-width: 740px;
        width: 100%;
        margin-left: auto;

        .CriteriaTitleText {
          margin-bottom: 30px;
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
            width: 20px;
            height: 20px;
            object-fit: contain;
          }

          p {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: #1b1b1b;
          }
        }

        .ChartBarContentWrepper {
          display: flex;
          justify-content: space-between;
          margin-bottom: 60px;

          .ChartBarContent {
            width: 100%;
            max-width: 310px;

            .Founded_WomanText {
              font-style: normal;
              font-weight: bold;
              font-size: 13px;
              color: #1b1b1b;
              margin-bottom: 15px;
            }

            .Founded_WomanImg {
              display: flex;
              align-items: center;

              img {
                margin-right: 10px;
                width: 20px;
                height: 20px;
                object-fit: contain;
              }
            }

            .ChartBarPercentageText {
              font-style: normal;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              color: #1b1b1b;
              margin-bottom: 10px;
            }
          }
        }

        .Consumption_ListText {
          margin-bottom: 60px;

          p {
            margin-bottom: 15px;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
          }

          ul {
            li {
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              list-style: none;
              margin-bottom: 10px;

              img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                object-fit: contain;
                margin-right: 10px;
              }
            }
          }
        }

        .Quality_EmploymentText {
          p {
            margin-bottom: 15px;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
          }

          ul {
            li {
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              list-style: none;
              margin-bottom: 10px;
              margin-left: 0;

              img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                object-fit: contain;
                margin-right: 10px;
              }
            }
          }
        }

        .IndirectInvestmentListContent {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .IndirectContent {
            max-width: 301px;
            width: 100%;

            .IndirectInvWrepper {
              margin-bottom: 45px;

              .IndirectInvText {
                font-style: normal;
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                color: #1b1b1b;
                margin-bottom: 16px;
              }

              .IndirectInvImg {
                display: flex;
                align-items: center;

                img {
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  object-fit: contain;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }

    .GBVdetailsBlock {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px;

      .GBVdetailsContent {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .overOverviewDeitail {
          width: 100%;

          span {
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
            margin-bottom: 5px;
          }

          p {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #1b1b1b;
          }
        }

        .GbvRiskContent {
          max-width: 670px;
          widows: 100%;

          .step_gbvRisk {
            flex-wrap: wrap;
          }

          .GbvRiskTitleWrepper {
            margin-bottom: 40px;

            .GbvRiskTitle {
              margin-bottom: 30px;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 60px;
                height: 40px;
                object-fit: contain;
                margin-right: 20px;
              }

              h1 {
                font-style: normal;
                font-weight: bold;
                font-size: 25px;
                line-height: 30px;
                color: #1b1b1b;
              }
            }

            p {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              text-align: center;
              color: #9d9d9c;
            }
          }

          .SevereRiskWrepper {
            h1 {
              margin-top: 60px;
              margin-bottom: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-style: normal;
              font-weight: bold;
              font-size: 25px;
              line-height: 30px;
              color: #fe5000;

              img {
                width: 40px;
                height: 70px;
                object-fit: contain;
                margin-right: 30px;
              }

              span {
                margin-right: 5px;
              }
            }

            .UbiquitousTextIcon {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              img {
                width: 16px;
                height: 30px;
                object-fit: contain;
                margin-right: 10px !important;
              }

              span {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #9d9d9c;
                margin-right: 20px;

                &:last-child {
                  margin-right: 0px;
                }
              }
            }
          }
        }
      }
    }

    .esg-pre-screening {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px;
      margin-bottom: 20px;

      .OverviewTitle {
        h2 {
          width: 320px;
        }
      }

      .GBVdetailsContent {
        justify-content: space-between;

        ul {
          margin-top: 1.5rem;
        }

        ul li {
          display: flex;
          margin: 0;
        }

        .overOverviewDeitail {
          width: 100%;

          span {
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
            margin-bottom: 5px;
          }

          p {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #1b1b1b;
          }
        }

        .GbvRiskContent {
          max-width: 670px;
          widows: 100%;

          .step_gbvRisk {
            flex-wrap: wrap;
          }

          .GbvRiskTitleWrepper {
            margin-bottom: 40px;

            .GbvRiskTitle {
              margin-bottom: 30px;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 60px;
                height: 40px;
                object-fit: contain;
                margin-right: 20px;
              }

              h1 {
                font-style: normal;
                font-weight: bold;
                font-size: 25px;
                line-height: 30px;
                color: #1b1b1b;
              }
            }

            p {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              text-align: center;
              color: #9d9d9c;
            }
          }

          .SevereRiskWrepper {
            h1 {
              margin-top: 60px;
              margin-bottom: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-style: normal;
              font-weight: bold;
              font-size: 25px;
              line-height: 30px;
              color: #fe5000;

              img {
                width: 40px;
                height: 70px;
                object-fit: contain;
                margin-right: 30px;
              }

              span {
                margin-right: 5px;
              }
            }

            .UbiquitousTextIcon {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              img {
                width: 16px;
                height: 30px;
                object-fit: contain;
                margin-right: 10px !important;
              }

              span {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #9d9d9c;
                margin-right: 20px;

                &:last-child {
                  margin-right: 0px;
                }
              }
            }
          }
        }
      }
    }

    .amount-funded {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px;
      width: 100%;
      margin-top: 20px;

      .funding-status-profile-container {
        margin: 1rem 0 3rem 0;

        ul {
          display: flex;
          justify-content: space-between;
        }
      }

      .funding-status-title {
        h2 {
          width: 250px;
        }
      }

      .funding-bar-chart {
        width: 50%;
      }

      .funding-status-info {
        width: 50%;

        .Quality_EmploymentText {
          p {
            margin-bottom: 15px;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
          }

          ul {
            li {
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              list-style: none;
              margin-bottom: 10px;
              margin-left: 0;

              img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                object-fit: contain;
                margin-right: 10px;
              }
            }
          }
        }
      }

      .CriteriaTitleText {
        margin-bottom: 30px;
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
          width: 20px;
          height: 20px;
          object-fit: contain;
        }

        p {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          color: #1b1b1b;
        }
      }

      .ChartBarContentWrepper {
        justify-content: space-between;
        margin: 0 auto 60px auto;

        .ChartBarContent {
          width: 100%;
          margin: auto;
          max-width: 400px;

          .Founded_WomanText {
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            color: #1b1b1b;
            margin-bottom: 15px;
          }

          .Founded_WomanImg {
            display: flex;
            align-items: center;

            img {
              margin-right: 10px;
              width: 20px;
              height: 20px;
              object-fit: contain;
            }
          }

          .ChartBarPercentageText {
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
            margin-bottom: 10px;
          }
        }
      }

      .get-it-on-deal-button {
        text-align: right;
        margin-bottom: 20px;
      }

      .get-it-on-deal-button button {
        background: #fff;
        color: #1b1b1b;
        border-color: #201747;
        border: 1px solid #1b1b1b;
        height: 36px;
        color: #1b1b1b;
        border-radius: 20px;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        padding: 9px 11px;
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
      }

      .get-it-on-deal-button button:hover {
        background: #201747;
        color: #fff;
      }
    }

    .OverviewTitle {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #1b1b1b;
        margin-right: 10px;
        position: relative;
        width: 320px;
      }

      span {
        display: inline-block;
        border-bottom: 2px solid #1b1b1b;
        width: 100%;
        height: 2px;
      }
    }

    .CountryLine {
      align-items: flex-end;

      h2 {
        width: auto;
      }

      span {
        width: 110px;
      }
    }
  }

  .download-csv {
    button {
      border: 1px solid #1b1b1b;
      height: 36px;
      color: #1b1b1b;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;

      &:first-child {
        margin-right: 10px;
      }

      &:hover {
        background: rgb(32, 23, 71);
        color: #fff;
        border-color: rgb(32, 23, 71);

        img {
          filter: invert(1);
        }
      }
    }
  }
}

.pointer-event-none {
  pointer-events: none;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #1b1b1b;
}

span {
  cursor: pointer;
}
// input {
//   margin: 6px;
// }
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  top: -8px;
  position: relative;
}

.Modal_Readmorestyle {
  padding: 30px 0 30px 35px;
  position: relative;
  display: flex;
  .ModalInnerStyle {
    max-height: 630px;
    overflow: auto;
    background: #fff;
    padding-right: 30px;
    h1 {
      font-weight: bold;
      line-height: 16px;
      color: #1b1b1b;
      margin-bottom: 5px;
      text-align: center;
      padding-bottom: 30px;
    }
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      text-align: left;
      line-height: 18px;
      color: #1b1b1b;
    }
    button {
      margin: auto;
      margin-top: 30px;
      order: none;
      padding: 15px 32px;
      font-size: 16px;
    }
  }
  .cross_Icon {
    position: absolute;
    top: 15px;
    right: 25px;
    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
      margin: 0 !important;
      cursor: pointer;
    }
  }
}

.border-animations {
  position: relative;
  margin-bottom: 10px;
  // padding: 30px 60px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.4);
  // color: #999;
  // text-decoration: none;
  // text-transform: uppercase;
  // letter-spacing: 4px;
  // font: 700 30px consolas;
  overflow: hidden;
}

.span-space {
  margin-top: 10px;
  margin-left: 10px;
}

.img-space {
  margin-top: 10px;
  padding-left: 33px !important;
}
.border-animations p:nth-child(1) {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, rgb(32, 23, 71), rgb(254, 80, 0));
  animation: animate1 2s linear infinite;
}

@keyframes animate1 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.border-animations p:nth-child(2) {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 3px;
  background: linear-gradient(to bottom, rgb(32, 23, 71), rgb(254, 80, 0));
  animation: animate2 2s linear infinite;
  animation-delay: 1s;
}
@keyframes animate2 {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

.border-animations p:nth-child(3) {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to left, rgb(32, 23, 71), rgb(254, 80, 0));
  animation: animate3 2s linear infinite;
}

@keyframes animate3 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.border-animations p:nth-child(4) {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 3px;
  background: linear-gradient(to top, rgb(32, 23, 71), rgb(254, 80, 0));
  animation: animate4 2s linear infinite;
  animation-delay: 1s;
}

@keyframes animate4 {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}
</style>
