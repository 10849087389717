<template>
  <div class="">
    <div class="flex items-center space-x-2">
      <span class="w-[25%] flex-shrink-0 flex-grow-0"></span>

      <h3 class="font-medium mb-2" v-if="title">
        {{ title }}
      </h3>
    </div>

    <div class="relative">
      <!-- Ticks -->
      <div
        class="flex items-center space-x-2 absolute top-0 left-0 right-0 bottom-0 -z-10"
      >
        <span
          class="w-[25%] flex-shrink-0 flex-grow-0 text-right text-xs font-medium leading-3"
        ></span>
        <div class="relative w-full h-full -z-10 flex justify-between">
          <div class="relative" v-for="tick in xTickCount + 1" :key="tick">
            <div
              class="absolute top-0 left-0 bottom-0 border-l border-grey-20"
            ></div>
          </div>
        </div>
      </div>

      <!-- Chart Container -->
      <div class="pt-2">
        <div
          v-for="(bar, index) in data"
          :key="index"
          class="flex items-center space-x-2 pb-2"
        >
          <!-- Label -->
          <span
            class="w-[25%] flex-shrink-0 flex-grow-0 text-right text-xs font-medium leading-3"
            >{{ bar.label }}</span
          >

          <!-- Stacked Bar -->
          <div class="relative flex flex-1 h-6 overflow-hidden">
            <div
              :style="{ width: valuePercent(bar) + '%' }"
              class="bg-navy flex items-center justify-end text-gray-700 text-xs font-medium relative"
              v-if="hasValue(bar)"
            >
              <div
                class="absolute flex items-center left-full top-0 bottom-0 z-10 pl-1"
                v-if="valuePercent(bar) < 90"
              >
                {{ formatNumber(bar.value) }}
              </div>
              <div v-else class="text-white pr-1">
                {{ formatNumber(bar.value) }}
              </div>
            </div>
            <div
              :style="{ width: remainingPercent(bar) + '%' }"
              class="flex items-center justify-end text-gray-700 text-xs font-medium"
              :class="{
                'bg-gray-200': !hasValue(bar),
                'bg-light-navy': hasValue(bar),
              }"
            >
              <span class="pr-1">
                {{ formatNumber(bar.target) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- X-Axis -->
    <div class="flex items-center space-x-2 pb-2">
      <span
        class="w-[25%] flex-shrink-0 flex-grow-0 text-right text-xs font-medium leading-3"
      ></span>
      <div class="relative w-full">
        <!-- <div
          class="absolute top-0 left-0 right-0 border-t border-grey-20"
        ></div> -->
        <div
          class="flex justify-between text-xs font-medium text-gray-600 mt-1"
        >
          <div class="relative" v-for="tick in xTickCount + 1" :key="tick">
            <div class="absolute top-0 left-0 -ml-10 w-20 text-center">
              {{ percentAtTick(tick) }}%
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Legend -->
    <div class="flex items-center space-x-2 pb-2 pt-2">
      <span class="w-[25%] flex-shrink-0 flex-grow-0"></span>

      <div class="flex items-center space-x-4 mt-4 text-xs font-medium w-full">
        <div class="flex items-center space-x-1">
          <div class="w-4 h-4 bg-navy"></div>
          <span>{{ label }}</span>
        </div>
        <div class="flex items-center space-x-1">
          <div class="w-4 h-4 bg-light-navy"></div>
          <span>{{ targetLabel }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const numberFormat = Intl.NumberFormat("en-US", {
  notation: "compact",
  maximumFractionDigits: 1,
});

export default {
  name: "StackedPercentBarChart",
  props: {
    xTickStep: {
      type: Number,
      required: false,
      default: 20,
    },
    data: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: "Actual",
    },
    targetLabel: {
      type: String,
      required: false,
      default: "Target",
    },
  },
  computed: {
    xTickCount() {
      return Math.round(100 / this.xTickStep);
    },
  },
  methods: {
    formatNumber(value) {
      return numberFormat.format(value);
    },
    hasValue(bar) {
      return bar.value !== undefined && bar.value !== null;
    },
    valuePercent(bar) {
      if (!bar.value) {
        return 0;
      }
      if (!bar.target) {
        return 100;
      }
      return this.hasTarget(bar) ? (bar.value / bar.target) * 100 : 100;
    },
    hasTarget(bar) {
      return bar.target !== undefined && bar.target !== null;
    },
    remainingPercent(bar) {
      if (!bar.target) {
        return 0;
      }
      if (!bar.value) {
        return 100;
      }
      return Math.max(0, 100 - this.valuePercent(bar));
    },
    percentAtTick(tick) {
      return (tick - 1) * this.xTickStep;
    },
  },
};
</script>

<style scoped></style>
