<template>
  <div class="flex gap-8">
    <div class="h-[32px]" v-if="!periods">
      <clip-loader
        :color="env.brandingColors.teal"
        :size="'24px'"
      />
    </div>
    <div class="flex h-8 bg-[#f4f6f9] rounded-lg cursor-pointer" v-else>
      <div
        :class="[
          'px-3 py-[6px] text-sm font-arial',
          {
            'bg-[#2177c1] rounded-lg text-white font-bold':
              value.frequency === 'All',
          },
        ]"
        @click="value.frequency = 'All'"
      >
        All
      </div>
      <div
        :class="[
          'px-3 py-[6px] text-sm font-arial   ',
          {
            'bg-[#2177c1] rounded-lg text-white font-bold':
              value.frequency === 'Quarterly',
          },
        ]"
        @click="value.frequency = 'Quarterly'"
        v-if="periods && 'Quarterly' in periods"
      >
        Quarters
      </div>
      <div
        :class="[
          'px-3 py-[6px] text-sm font-arial   ',
          {
            'bg-[#2177c1] rounded-lg text-white font-bold':
              value.frequency === 'Monthly',
          },
        ]"
        @click="value.frequency = 'Monthly'"
        v-if="periods && 'Monthly' in periods"
      >
        Months
      </div>
      <div
        :class="[
          'px-3 py-[6px] text-sm font-arial ',
          {
            'bg-[#2177c1] rounded-lg text-white font-bold':
              value.frequency === 'Annually',
          },
        ]"
        @click="value.frequency = 'Annually'"
        v-if="periods && 'Annually' in periods"
      >
        Annual
      </div>
    </div>
    <div class="flex items-center h-8" v-if="value.frequency !== 'All' && periods && (periods[value.frequency] || []).length > 0">
      <select
        name="year"
        id="year"
        class="px-3 py-[6px] bg-[#f4f6f9] rounded-lg text-sm font-arial cursor-pointer focus:outline-none w-28"
        :disabled="(periods[value.frequency] || []).length === 0"
        @change="value.period = $event.target.value"
      >
        <option
          :value="period"
          :key="period"
          v-for="period in periods[value.frequency] || []"
        >
          {{ period }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
import ClipLoader from "vue-spinner/src/ClipLoader";
import env from "@/../env";
export default {
  name: "FrequencyFilter",
  components: {
    ClipLoader,
  },
  data() {
    return {
      env,
    };
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        frequency: "All",
        period: null,
      }),
    },
    periods: {
      type: Object,
      required: false,
    },
  },
  watch: {
    value: {
      handler: function (newValue) {
        if (newValue.frequency === "All") {
          newValue.period = null;
        } else {
          if (!this.periods[newValue.frequency]) {
            newValue.period = null;
          } else if (!this.periods[newValue.frequency].includes(newValue.period)) {
            newValue.period = this.periods[newValue.frequency][0] || null;
          }
        }
        this.$emit("input", newValue);
      },
      deep: true,
    },
  },
};
</script>
