<template>
  <div class="static ecosystem-landing">
    <div class="container mx-auto -mt-20 px-4 lg:px-0 pb-32">
      <!--Loader-->
      <div id="notLoaded" v-if="loading" key="loading">
        <page-loader></page-loader>
      </div>
      <div class="PoweredbyTitle_logo">
        <div class="PoweredbyContentWrepper">
          <img src="@/assets/images/2X-Global_logo_transparent.svg" alt="background-image" style="height: 50px" />
        </div>
        <div style="display: flex; justify-content: flex-end">
          <div class="Poweredby_logoImg mt-2">
            <div class="powered-text flex">
              <span>Powered by:</span>
            </div>
            <img src="@/assets/images/logo-navy.png" alt="background-image" />
          </div>
        </div>
      </div>
      <div class="fixed z-50 inset-0 overflow-y-auto" v-if="is2xCertification">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div class="fixed inset-0 transition-opacity cursor-pointer">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen cursor-pointer"></span>&#8203;
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
            role="dialog" aria-modal="true" aria-labelledby="modal-headline" style="max-width: 624px">
            <div class="Modal_Readmorestyle py-4 px-6">
              <div class="ModalInnerStyle">
                <div class="mt-5">
                  <div>
                    This platform is a Beta version that is built to test
                    content.There are known technical bugs that are currently
                    being addressed. We appreciate your patience and
                    understanding for this. For technical issues that prevent
                    you from proceeding with testing, please reach out to the
                    technical team by clicking
                    <a href=" https://airtable.com/embed/appxe5pWrRZuSKxdS/shrvvhKBs0eAcICnu?backgroundColor=red"
                      target="_blank" download="filename.extension"
                      class="text-blue-600 font-normal rounded border-blue-400">
                      "Support"
                    </a>
                  </div>
                </div>
                <button @click="is2xCertification = false"
                  class="flex justify-center m-auto mt-4 bg-[#A21942] px-4 py-2 rounded-lg text-white font-semibold">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="PoweredbyTitle_logo bg-image" style="padding: 0">
        <div class="PoweredbyContentWrepper bg-text-style">
          <div class="PoweredbyTitleWrepper mt-5">
            <h1 class="text-6xl tracking-tighter font-medium print:text-7xl" style="max-width: 375px; color: #fff">
              <span style="font-family: Work Sans">Welcome to the 2X Certification Platform</span>
            </h1>
          </div>
          <div class="flex flex-col mt-10 mx-auto items-center justify-center" style="max-width: 845px">
            <p class="text-lg leading-7 font-medium gap-10 text-justify leading-7" style="color: #fff">
              Designed for a wide range of audience types, 2X Certification offers a structured pathway towards
              excellence in gender equity for investors, companies and financial instruments. By providing clear
              standards and benchmarks, it aims to promote transparency, accountability, and credibility in the
              financial sector.
            </p>
          </div>
        </div>
      </div>

      <div class="ContentWrapper w-12/12">
        <!-- CERTIFICATION PROCESS -->
        <div>
          <h1 class="text-4xl tracking-tighter font-medium print:text-7xl mt-12 mb-4" style="max-width: 500px">
            <span style="font-family: Work Sans">2X Certification</span>
          </h1>
          <div class="flex gap-2">
            <div class="flex flex-col mt-10 w-2/4">
              <div class="text-med leading-7 font-semibold gap-10 text-justify leading-7 font-medium w-3/4">
                <span style="cursor: default; color: #21191d">
                  2X Certification is an independent and universally available certification mechanism that further
                  advances the standard-setting work which 2X Global has been leading in the global field of gender lens
                  investing (GLI). 2X Certification is a powerful lever to unlock gender-smart capital at scale and
                  deliver on <a href="https://www.2xglobal.org/" target="_blank" class="text-[#0077C8] text-semibold">2X
                    Global’s</a> goal of increasing the volume and impact of GLI across asset classes, sectors
                  and geographies globally.
                </span>
                <br />
                <br />
                <span style="cursor: default; color: #21191d">
                  2X Certification builds on the foundation of the
                  <a href="https://2xchallenge.org/2xcriteria" target="_blank" class="text-[#0077C8] text-semibold">2X
                    Criteria</a>. It serves as a comprehensive assessment
                  of an organisation’s gender efforts across its full value chain and core gender themes and drives
                  further credibility through third-party verification. 2X Certification acts as a guiding star,
                  encouraging entities to implement robust gender practices and foster meaningful change across
                  industries.
                </span>
                <br />
                <br />
                <span style="cursor: default; color: #21191d">
                  The 2X Certification Process comprises a 4-step process which Entities are required to complete in
                  order to be issued with a 2X Certificate. This 2X Certification Platform captures steps all 2X
                  Certification Process steps. After issuance of the 2X Certificate it is strongly recommend that
                  Entities continue to strengthen gender-smart practices as an ongoing process to further improve.
                </span>
                <br /><br />
                <div class="flex flex-col">
                  <p class="text-med leading-7 font-semibold gap-10 text-justify leading-7 font-medium"
                    style="cursor: default; color: #21191d">
                    As a fund, financial institution, or institutional investor,
                    you may invite your portfolio companies, pipeline, and
                    alumni to take the 2X Certification Self-Assessment. Keen on
                    tracking their performance across the 2X Criteria?
                  </p>
                  <p class="text-med leading-7 font-semibold gap-10 text-justify leading-7 font-medium mt-4">
                    Invite an entity to take the 2X Certification
                    Self-Assessment:
                  </p>
                  <div class="flex gap-2 mt-2">
                    <input class="invitee-email-input" type="text" placeholder="Type e-mail address"
                      v-model="inviteeEmail" />
                    <!-- Need to update API here to invite for assess not protfolio -->
                    <button v-track-click="{ elementName: 'Invite' }" @click="sendInviteToCompany()"
                      :class="{ 'in-active-button': !inviteeEmail }"
                      class="certification-button text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center p-3 text-center">
                      Invite
                    </button>
                  </div>
                  <p v-if="
                      userStore?.subscriptionType == subscriptionType.PREMIUM
                    " class="text-med leading-7 font-semibold gap-10 text-justify leading-7 font-medium mt-4">
                    Invite entities to join your 2X Portfolio:
                  </p>
                  <div class="flex gap-2 mt-2" v-if="
                      userStore?.subscriptionType == subscriptionType.PREMIUM
                    ">
                    <button v-track-click="{ elementName: 'Portfolio Invite' }" @click="
                        $router.push({
                          path: '/2X-Ecosystem/2x-portfolio-setup',
                        })
                      "
                      class="certification-button text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center p-3 text-center">
                      Portfolio Invite
                    </button>
                  </div>
                </div>
                <div class="mt-8">
                  <div>
                    Download questions in advance for following entity type:
                  </div>
                  <div class="text-sm leading-5 text-gray-900 w-full relative items-center">
                    <input class="cursor-pointer" type="radio" id="company" value="company"
                      v-model="selectedEntityTypeForDownloadQuestion" />
                    <label for="company" class="cursor-pointer">Company</label>
                  </div>
                  <div class="text-sm leading-5 text-gray-900 w-full relative items-center">
                    <input class="cursor-pointer" type="radio" id="fund" value="fund"
                      v-model="selectedEntityTypeForDownloadQuestion" />
                    <label for="fund" class="cursor-pointer">Fund</label>
                  </div>
                </div>
                <div class="mt-4" :class="{
                    'pointer-events-none':
                      !selectedEntityTypeForDownloadQuestion,
                  }" style="width: fit-content">
                  <a :href="
                      selectedEntityTypeForDownloadQuestion == 'fund'
                        ? 'https://storage.equilo.io/api-assets/2X_Certification_Detailed_indicator_questions.pdf'
                        : 'https://storage.equilo.io/api-assets/2X%20Certification_Detailed%20indicator%20questions_vCompanies_April%202024.pdf'
                    " :download="
                      selectedEntityTypeForDownloadQuestion == 'fund'
                        ? 'Certification_Detailed_Questions_Fund.pdf'
                        : 'Certification_Detailed_indicator_questions.pdf'
                    "
                    class="certification-button text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center p-3 text-center"
                    target="_blank">
                    <span class="pr-1">Download</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="progress-steps-section w-2/4">
              <div class="outer">
                <div class="progress">
                  <div class="right">
                    <div v-track-click="{
                        elementName: '2X Challenge Self-Assessment',
                      }" class="flex flex-col items-start" v-if="
                        $route.path.includes('/2X-Challenge') ||
                        ($store.state.certPageAccess.isMinSurveyFill &&
                          !$store.state.certPageAccess.isFullSurveyFill)
                      " :class="{ current: currentStep == 1 }">
                      <span class="font-bold">2X Challenge Self-Assessment</span>
                      <span class="font-normal mb-2 mt-0.5">The 2X Criteria are a global baseline standard for
                        gender finance and public good used by investors,
                        financial intermediaries and companies as well as other
                        stakeholders worldwide to assess and monitor gender-lens
                        investments.
                      </span>
                      <button v-track-click="{
                          elementName: '2X Challenge Self-Assessment',
                        }"
                        class="certification-button px-3 py-2 mt-2 mb-8 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                        :class="{
                          'pointer-events-none':
                            currentStep > 1 &&
                            !(
                              $store.state.certPageAccess.isMinSurveyFill &&
                              !$store.state.certPageAccess.isUploadDocPage &&
                              !$store.state.certPageAccess.isFullSurveyFill
                            ),
                        }" @click="
                          navigateModule(
                            '/2X-Challenge/2X-Challenge-Self-Assessment'
                          )
                        ">
                        <span class="">
                          {{
                          this.$store.state.certPageAccess.isMinSurveyFill &&
                          !this.$store.state.certPageAccess.isUploadDocPage
                          ? "Repeat 2X Challenge Self-Assessment"
                          : "2X Challenge Self-Assessment"
                          }}
                        </span>
                      </button>
                    </div>
                    <div class="flex flex-col items-start" v-if="
                        $route.path.includes('/2X-Challenge') ||
                        ($store.state.certPageAccess.isMinSurveyFill &&
                          !$store.state.certPageAccess.isFullSurveyFill)
                      " :class="{ current: currentStep == 2 }">
                      <span class="font-bold">
                        Review 2X Criteria results
                      </span>
                      <span class="font-normal mb-2 mt-0.5">After taking the 2X Criteria self-assessment view the
                        instant indicative self-assessment result to see if you
                        meet the minimum 2X Criteria requirements.</span>
                      <button v-track-click="{
                          elementName: 'Review 2X Criteria results',
                        }" :class="{
                          'pointer-events-none':
                            currentStep != 2 &&
                            currentStep != 3 &&
                            !(
                              this.$store.state.certPageAccess
                                .isMinSurveyFill &&
                              !this.$store.state.certPageAccess.isUploadDocPage
                            ),
                        }"
                        class="certification-button px-3 py-2 mt-2 mb-8 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                        @click="
                          $router.push({
                            path: '/2X-Challenge/2X-Challenge-Self-Assessment-Results',
                          })
                        ">
                        <span class="">Review 2X Criteria results</span>
                      </button>
                    </div>
                    <div class="flex flex-col items-start" :class="{
                        current:
                          (this.$route.path.includes('2X-Certification') &&
                            currentStep == 1) ||
                          currentStep == 3 ||
                          !$store.state.certPageAccess.isFullSurveyFill,
                      }">
                      <span class="font-bold">2X Certification Self-Assessment
                      </span>
                      <span class="font-normal mb-2 mt-0.5">Complete the 2X Certification Self-Assessment to see
                        whether your entity meets the requirements of 2X
                        Certification and which Certification level you align
                        with.
                      </span>
                      <button v-track-click="{
                          elementName: '2X Certification Self-Assessment',
                        }"
                        class="certification-button px-3 py-2 mt-2 mb-8 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                        :class="{
                          'pointer-events-none':
                            currentStep != 3 &&
                            !(
                              $store.state.certPageAccess.isFullSurveyFill &&
                              !$store.state.certPageAccess.isUploadDocPage
                            ),
                        }" @click="
                          navigateModule(
                            '/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment'
                          )
                        ">
                        <span class="">
                          {{
                          this.$store.state.certPageAccess.isFullSurveyFill &&
                          !this.$store.state.certPageAccess.isUploadDocPage
                          ? "Repeat 2X Certification Self-Assessment"
                          : "2X Certification Self-Assessment"
                          }}
                        </span>
                      </button>
                    </div>
                    <div class="flex flex-col items-start" :class="{
                        current:
                          (this.$route.path.includes('2X-Certification') &&
                            currentStep == 1) ||
                          currentStep == 4,
                      }">
                      <span class="font-bold">View Indicative Results</span>
                      <span class="font-normal mb-2 mt-0.5">Once you’ve completed the 2X Certification
                        Self-Assessment, view your results here.
                      </span>
                      <span class="flex gap-5">
                        <button v-track-click="{
                            elementName: 'View Indicative Results',
                          }"
                          class="certification-button px-3 py-2 mt-2 mb-8 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                          :class="{
                            'pointer-events-none': currentStep < 4,
                          }" @click="
                            $router.push({
                              path: '/2X-Ecosystem/2X-Certification/IndicativeResults',
                            })
                          ">
                          <span class="">Indicative Results </span>
                        </button>
                        <button v-track-click="{
                            elementName: 'Level-Up',
                          }"
                          class="certification-button px-3 py-2 mt-2 mb-8 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                          :class="{
                            'pointer-events-none': currentStep < 4,
                          }" @click="proceedToLevelUp">
                          <span class="">Level-Up </span>
                        </button>
                      </span>
                    </div>
                    <div class="flex flex-col items-start" :class="{ current: currentStep == 5 }">
                      <span class="font-bold">Third-Party Verification</span>
                      <span class="font-normal mb-2 mt-0.5">If you choose to proceed with 2X Certification, please
                        indicate your choice of third-party Verifier by
                        proceeding with this step.
                      </span>
                      <button :class="{
                          'pointer-events-none':
                            currentStep < 5 ||
                            $store.state.certPageAccess.isGlobalPage,
                        }"
                        class="certification-button px-3 py-2 mt-2 mb-8 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                        @click="goToThirdPartyPage()">
                        <span class="">Third-Party Verification</span>
                      </button>
                    </div>
                    <div class="flex flex-col items-start" :class="{ current: currentStep == 6 }">
                      <span class="font-bold">Issuance of the 2X Certificate</span>
                      <span class="font-normal mb-2 mt-0.5">A company, fund, or financial institution that has
                        completed the verification process can apply for their
                        2X Certificate here. Public disclosure and amplification
                        of your results will form part of this step.</span>
                      <span class="flex gap-5">
                        <button :class="{
                            'pointer-events-none': currentStep < 6,
                          }"
                          class="certification-button px-3 py-2 mt-2 mb-8 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                          @click="
                            $router.push({
                              path: '/2X-Ecosystem/2X-Certification/GlobalCertification',
                            })
                          ">
                          <span class="">2X Certificate</span>
                        </button>
                        <button :class="{
                            'pointer-events-none': currentStep < 6,
                          }"
                          class="certification-button px-3 py-2 mt-2 mb-8 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                          @click="
                            $router.push({
                              path: '/2X-Ecosystem/2X-Certification/GlobalCertification',
                            })
                          ">
                          <span class="">2X Certification Scorecard</span>
                        </button>
                      </span>
                    </div>
                    <!-- <div
                      class="flex flex-col items-start"
                      :class="{ current: currentStep == 7 }"
                      v-track-click="{ elementName: 'Level up' }"
                    >
                      <span class="font-bold">Act to Level Up</span>
                      <span class="font-normal mb-2 mt-0.5"
                        >Gain access to strategies that can take your entity to
                        the next level</span
                      >
                      <button
                        :class="{
                          'pointer-events-none': currentStep < 7,
                        }"
                        class="certification-button px-3 py-2 mt-2 mb-8 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                        @click="
                          $router.push({
                            path: '/2X-Ecosystem/2X-Certification/score-card',
                          })
                        "
                      >
                        <span class="">2X Certification Scorecard</span>
                      </button>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Section 2 -->
    <div class="container mx-auto -mt-20 px-4 lg:px-0 pb-32">
      <h1>Three Levels of 2X Certification</h1>
      <!-- <div
        class="PoweredbyTitle_logo bg-image-section-2"
        style="padding: 0"
      ></div> -->
      <div class="ContentWrapper w-12/12">
        <!-- CERTIFICATION TIERS -->
        <div>
          <!-- <h1
            class="text-4xl tracking-tighter font-medium print:text-7xl mt-12 mb-4"
            style="max-width: 500px"
          >
            <span style="font-family: Work Sans">3 Tiers of Certification</span>
          </h1> -->
          <!-- 3 TIERS OF CERTIFICATION -->

          <div class="column-wrapper grid grid-cols-3 gap-4 my-10">
            <div class="flex-col">
              <img class="w-48" src="@/assets/images/2x_certify/2x_certification_tier_good.svg" />
              <!-- <div class="mt-10">
                <p class="text-style h-24">
                  The minimum Good level certification is aligned with 2X
                  Challenge qualification requirements with additional minimum
                  safeguarding and governance requirements.<br /><br />
                </p>
                <p class="text-style" style="font-weight: bold">
                  For the Good level certification, the following is required:
                </p>
                <ul
                  class="text-style"
                  style="list-style-type: disc; margin-left: 12px"
                >
                  <li>Basic 2X ESG measures for safeguarding are in place,</li>
                  <li>
                    AND have at least one governance Strategic action in place
                    that is not public Strategic action
                  </li>
                  <li>
                    AND have at least one management systems for governance in
                    place
                  </li>
                  <li>
                    AND must meet GBVH Safeguarding criteria (unless micro or
                    small business, a Strategic action to put in place within
                    certification period)
                  </li>
                  <li>
                    AND must meet gender data governance criteria (unless micro
                    or small business, a Strategic action to put in place within
                    certification period)
                  </li>
                  <li>
                    AND at least one (non-governance) 2X Dimension threshold OR
                    theme threshold is met
                  </li>
                  <li>
                    AND demonstrate intentionality to improve with at least 1
                    additional Strategic action is made to meeting a threshold
                    for an additional (non-governance) 2X Dimension.
                  </li>
                </ul>
              </div> -->
            </div>

            <div class="flex-col">
              <img class="w-48" src="@/assets/images/2x_certify/2x_certification_tier_advanced.svg" />
              <!-- <div class="mt-10">
                <p class="text-style h-24">
                  The Advanced level certification recognizes that the entity is
                  taking actions beyond minimum requirements.<br /><br />
                </p>
                <p class="text-style" style="font-weight: bold">
                  For the Advanced level certification, the following is
                  required:
                </p>
                <ul
                  class="text-style"
                  style="list-style-type: disc; margin-left: 12px"
                >
                  <li>All 2X ESG requirements are in place</li>
                  <li>
                    AND have at least one governance Strategic action in place
                    that is not public Strategic action
                  </li>
                  <li>
                    AND have at least one management systems for governance in
                    place, AND must meet GBVH Safeguarding criteria
                  </li>
                  <li>AND must meet gender data governance criteria</li>
                  <li>
                    AND At least TWO 2X Dimension thresholds are met OR there is
                    a significant "spike" in 1 2X Dimension or Theme, where the
                    score is above 80% in one area.
                  </li>
                  <li>
                    Funds will be further assessed on the proportion of
                    portfolio companies that meet these requirements by year,
                    with a minimum threshold of 30%.
                  </li>
                </ul>
              </div> -->
            </div>

            <div class="flex-col">
              <img class="w-48" src="@/assets/images/2x_certify/2x_certification_tier_bestinclass.svg" />

              <!-- <div class="mt-10">
                <p class="text-style h-24">
                  The Best in Class level certification recognizes that the
                  entity is taking broad and deep action to comprehensively
                  drive gender equality impact across 2X Dimensions.<br /><br />
                </p>
                <p class="text-style" style="font-weight: bold">
                  For the Best in Class level certification, the following is
                  required:
                </p>
                <ul
                  class="text-style"
                  style="list-style-type: disc; margin-left: 12px"
                >
                  <li>Meet all Basic 2X ESG Screen requirements</li>
                  <li>AND meet all 2X governance threshold criteria</li>
                  <li>
                    AND meet all thresholds across all 2X Dimensions and all
                    themes.
                  </li>
                  <li>
                    Funds will be further assessed on the proportion of
                    portfolio companies that meet these requirements by year,
                    with a minimum threshold of 30%.
                  </li>
                </ul>
              </div> -->
            </div>
          </div>

          <!-- Explainer text -->
          <div class="flex gap-2">
            <div class="flex flex-col mt-10 w-4/6">
              <p class="text-med leading-7 font-semibold gap-10 text-justify leading-7 font-medium w-5/6">
                <span style="cursor: default; color: #21191d">
                  2X Certification is a formal certification that assesses and recognises Entities actively implementing
                  gender-smart practices. Designed to go beyond compliance, 2X Certification encourages entities to
                  embed inclusive practices that drive meaningful and measurable gender equity, aligning with best
                  practices and contributing to broader social and economic goals.
                </span>
                <br /><br />
                On the satisfaction of steps 1-4 (inclusive) of the 2X Certification Process, Entities can achieve one
                of three 2X Certificate levels— ‘Good’, ‘Advanced’, and ‘Best-in-Class’. Each 2X Certification Level
                indicates the depth and breadth of an Entity’s gender-smart efforts following analysis of Gender Lens
                Disclosures through application of the 2X Certification Methodology. Each 2X Certification Level has
                specific minimum requirements that outline what is needed to achieve that 2X Certification Level. These
                requirements assess both the breadth (scope across various aspects) and depth (focus within specific
                areas) of an Entity’s gender efforts and commitments.
                <br /><br />
              </p>
            </div>
          </div>
        </div>

        <!-- CERTIFICATION ASSETS -->
      </div>
    </div>
    <!-- Section 3 -->
    <div class="container mx-auto -mt-20 px-4 lg:px-0 pb-32">
      <h1>2X Certification Assets</h1>
      <!-- <div class="PoweredbyTitle_logo bg-image-section-3" style="padding: 0"></div> -->
      <div class="ContentWrapper w-12/12">
        <!-- CERTIFICATION PROCESS -->
        <div>
          <!-- <h1 class="tracking-tighter mt-12 mb-4" style="max-width: 500px">
            How to Leverage 2X Certification Assets
          </h1> -->
          <p class="text-med leading-7 font-semibold gap-10 text-justify leading-7 font-medium w-5/6">
            A 2X Certificate is issued by the 2X Certification Body to an Entity upon satisfactory completion of steps
            1-4 of the 2X Certification Process.
          </p>
          <div class="flex gap-2">
            <div class="intropage_box mt-10" style="max-width: 980px">
              <div class="gap-10 mb-10 grid grid-cols-4">
                <div class="flex-col items-center">
                  <img class="w-48 mb-5" src="@/assets/images/2x_certify/2x_certification_tier_good.svg" />
                  <br />
                  <span class="asset_text">2X Certification Seal</span>
                  <br />
                  <span style="font-weight: normal">
                    Display the 2X Certification Seal on websites, social media, and marketing materials to publicly showcase your commitment to gender equity.
                  </span>
                </div>
                <div class="flex-col items-center">
                  <svg class="mb-5" width="192" height="192" viewBox="0 0 36 36" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect width="36" height="36" rx="6" fill="#FFECE8" />
                    <path
                      d="M26.7363 17.116L17.9992 22.2372L9.26204 17.116C9.07253 17.0049 8.84561 16.9719 8.6312 17.0243C8.41679 17.0767 8.23246 17.2101 8.11875 17.3952C8.00505 17.5803 7.97128 17.8019 8.02488 18.0113C8.07848 18.2208 8.21506 18.4008 8.40458 18.5119L17.5709 23.8838C17.7005 23.9598 17.8488 24 18 24C18.1512 24 18.2995 23.9598 18.4291 23.8838L27.5954 18.5119C27.7849 18.4008 27.9215 18.2208 27.9751 18.0113C28.0287 17.8019 27.995 17.5803 27.8812 17.3952C27.7675 17.2101 27.5832 17.0767 27.3688 17.0243C27.1544 16.9719 26.9275 17.0049 26.738 17.116H26.7363Z"
                      fill="#CB2E00" />
                    <path
                      d="M26.7363 21.116L17.9992 26.2364L9.26204 21.116C9.1682 21.061 9.06419 21.0246 8.95595 21.0089C8.84771 20.9931 8.73736 20.9984 8.6312 21.0243C8.52503 21.0502 8.42514 21.0963 8.33721 21.16C8.24929 21.2236 8.17505 21.3035 8.11875 21.3952C8.06245 21.4868 8.02518 21.5884 8.00908 21.6941C7.99297 21.7999 7.99834 21.9076 8.02488 22.0113C8.07848 22.2208 8.21506 22.4008 8.40457 22.5119L17.5708 27.8838C17.7005 27.9598 17.8488 28 18 28C18.1512 28 18.2995 27.9598 18.4291 27.8838L27.5954 22.5119C27.7849 22.4008 27.9215 22.2208 27.9751 22.0113C28.0287 21.8019 27.995 21.5803 27.8812 21.3952C27.7675 21.2101 27.5832 21.0767 27.3688 21.0243C27.1544 20.9719 26.9275 21.0049 26.738 21.116H26.7363Z"
                      fill="#CB2E00" />
                    <path
                      d="M8.40391 14.7068L16.7241 19.6494C17.1094 19.8788 17.5504 20 18 20C18.4496 20 18.8906 19.8788 19.2759 19.6494L27.5961 14.7068C27.7193 14.6335 27.8212 14.5298 27.892 14.4059C27.9628 14.282 28 14.1421 28 13.9998C28 13.8575 27.9628 13.7176 27.892 13.5937C27.8212 13.4698 27.7193 13.3661 27.5961 13.2928L19.2759 8.35022C18.8905 8.12106 18.4495 8 18 8C17.5505 8 17.1095 8.12106 16.7241 8.35022L8.40391 13.2928C8.2807 13.3661 8.17875 13.4698 8.10797 13.5937C8.0372 13.7176 8 13.8575 8 13.9998C8 14.1421 8.0372 14.282 8.10797 14.4059C8.17875 14.5298 8.2807 14.6335 8.40391 14.7068Z"
                      fill="#FF501C" />
                  </svg>
                  <span class="asset_text">2X Scorecard</span>
                  <br />
                  <span style="font-weight: normal">
                    Your 2X Scorecard, which sets out the list of Dimensions and Themes met or committed to, is publicly disclosed on the 2X Global Certification List. 
                  </span>
                </div>
                <div class="flex-col items-center">
                  <svg class="mb-5" width="192" height="192" viewBox="0 0 36 36" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect width="36" height="36" rx="6" fill="#E5FAFA" />
                    <path
                      d="M15.7669 21.6786L12.2109 18.258L11 19.4146L15.7669 24L26 14.1566L24.7976 13L15.7669 21.6786Z"
                      fill="#13A094" />
                  </svg>
                  <span class="asset_text">3rd Party Verified</span>
                  <br />
                  <span style="font-weight: normal">
                    Details of the Third-Party Verifier and an indication of their 2X Global training status publicized.
                  </span>
                </div>
                <div class="flex-col items-center">
                  <svg class="" width="192" height="192" viewBox="0 0 36 36" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect width="36" height="36" rx="6" fill="#EAF6FF" />
                    <path
                      d="M8 23.7143C8.00132 24.8505 8.44073 25.9398 9.22185 26.7432C10.003 27.5467 11.062 27.9986 12.1667 28H23.8333C24.938 27.9986 25.997 27.5467 26.7782 26.7432C27.5593 25.9398 27.9987 24.8505 28 23.7143V16H8V23.7143ZM22.1667 19.8571C22.4139 19.8571 22.6556 19.9325 22.8611 20.0738C23.0667 20.2151 23.2269 20.4159 23.3215 20.6508C23.4161 20.8858 23.4409 21.1443 23.3926 21.3937C23.3444 21.6431 23.2254 21.8722 23.0505 22.052C22.8757 22.2318 22.653 22.3543 22.4105 22.4039C22.1681 22.4535 21.9167 22.428 21.6883 22.3307C21.4599 22.2334 21.2647 22.0686 21.1273 21.8572C20.99 21.6457 20.9167 21.3971 20.9167 21.1429C20.9167 20.8019 21.0484 20.4748 21.2828 20.2337C21.5172 19.9926 21.8351 19.8571 22.1667 19.8571ZM18 19.8571C18.2472 19.8571 18.4889 19.9325 18.6945 20.0738C18.9 20.2151 19.0602 20.4159 19.1548 20.6508C19.2495 20.8858 19.2742 21.1443 19.226 21.3937C19.1777 21.6431 19.0587 21.8722 18.8839 22.052C18.7091 22.2318 18.4863 22.3543 18.2439 22.4039C18.0014 22.4535 17.7501 22.428 17.5216 22.3307C17.2932 22.2334 17.098 22.0686 16.9607 21.8572C16.8233 21.6457 16.75 21.3971 16.75 21.1429C16.75 20.8019 16.8817 20.4748 17.1161 20.2337C17.3505 19.9926 17.6685 19.8571 18 19.8571ZM13.8333 19.8571C14.0806 19.8571 14.3222 19.9325 14.5278 20.0738C14.7334 20.2151 14.8936 20.4159 14.9882 20.6508C15.0828 20.8858 15.1075 21.1443 15.0593 21.3937C15.0111 21.6431 14.892 21.8722 14.7172 22.052C14.5424 22.2318 14.3197 22.3543 14.0772 22.4039C13.8347 22.4535 13.5834 22.428 13.355 22.3307C13.1266 22.2334 12.9313 22.0686 12.794 21.8572C12.6566 21.6457 12.5833 21.3971 12.5833 21.1429C12.5833 20.8019 12.715 20.4748 12.9494 20.2337C13.1839 19.9926 13.5018 19.8571 13.8333 19.8571Z"
                      fill="#4E99DB" />
                    <path
                      d="M23.8333 9.75H23V8.875C23 8.64294 22.9122 8.42038 22.7559 8.25628C22.5996 8.09219 22.3877 8 22.1667 8C21.9457 8 21.7337 8.09219 21.5774 8.25628C21.4211 8.42038 21.3333 8.64294 21.3333 8.875V9.75H14.6667V8.875C14.6667 8.64294 14.5789 8.42038 14.4226 8.25628C14.2663 8.09219 14.0543 8 13.8333 8C13.6123 8 13.4004 8.09219 13.2441 8.25628C13.0878 8.42038 13 8.64294 13 8.875V9.75H12.1667C11.062 9.75139 10.003 10.2128 9.22185 11.0329C8.44073 11.8531 8.00132 12.9651 8 14.125L8 15H28V14.125C27.9987 12.9651 27.5593 11.8531 26.7782 11.0329C25.997 10.2128 24.938 9.75139 23.8333 9.75Z"
                      fill="#2177C1" />
                  </svg>
                  <br />
                  <span class="asset_text">2-Year Validity</span>
                  <br />
                  <span style="font-weight: normal">
                    The 2X Certificate is valid for 2 years, following which recertification is needed to maintain 2X Certification.
                  </span>
                </div>
              </div>
              <!-- <div class="gap-10 mb-10 grid grid-cols-3">
                <div class="flex-col items-center">
                  <svg class="mb-5" width="72" height="72" viewBox="0 0 36 36" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect width="36" height="36" rx="6" fill="#E5FAFA" />
                    <path
                      d="M15.7669 21.6786L12.2109 18.258L11 19.4146L15.7669 24L26 14.1566L24.7976 13L15.7669 21.6786Z"
                      fill="#13A094" />
                  </svg>
                  <span class="asset_text">Digital ID-Certificate</span>
                </div>
                <div class="flex-col items-center gap-2">
                  <svg class="mb-5" width="72" height="72" viewBox="0 0 36 36" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect width="36" height="36" rx="6" fill="#EAF6FF" />
                    <path
                      d="M8 23.7143C8.00132 24.8505 8.44073 25.9398 9.22185 26.7432C10.003 27.5467 11.062 27.9986 12.1667 28H23.8333C24.938 27.9986 25.997 27.5467 26.7782 26.7432C27.5593 25.9398 27.9987 24.8505 28 23.7143V16H8V23.7143ZM22.1667 19.8571C22.4139 19.8571 22.6556 19.9325 22.8611 20.0738C23.0667 20.2151 23.2269 20.4159 23.3215 20.6508C23.4161 20.8858 23.4409 21.1443 23.3926 21.3937C23.3444 21.6431 23.2254 21.8722 23.0505 22.052C22.8757 22.2318 22.653 22.3543 22.4105 22.4039C22.1681 22.4535 21.9167 22.428 21.6883 22.3307C21.4599 22.2334 21.2647 22.0686 21.1273 21.8572C20.99 21.6457 20.9167 21.3971 20.9167 21.1429C20.9167 20.8019 21.0484 20.4748 21.2828 20.2337C21.5172 19.9926 21.8351 19.8571 22.1667 19.8571ZM18 19.8571C18.2472 19.8571 18.4889 19.9325 18.6945 20.0738C18.9 20.2151 19.0602 20.4159 19.1548 20.6508C19.2495 20.8858 19.2742 21.1443 19.226 21.3937C19.1777 21.6431 19.0587 21.8722 18.8839 22.052C18.7091 22.2318 18.4863 22.3543 18.2439 22.4039C18.0014 22.4535 17.7501 22.428 17.5216 22.3307C17.2932 22.2334 17.098 22.0686 16.9607 21.8572C16.8233 21.6457 16.75 21.3971 16.75 21.1429C16.75 20.8019 16.8817 20.4748 17.1161 20.2337C17.3505 19.9926 17.6685 19.8571 18 19.8571ZM13.8333 19.8571C14.0806 19.8571 14.3222 19.9325 14.5278 20.0738C14.7334 20.2151 14.8936 20.4159 14.9882 20.6508C15.0828 20.8858 15.1075 21.1443 15.0593 21.3937C15.0111 21.6431 14.892 21.8722 14.7172 22.052C14.5424 22.2318 14.3197 22.3543 14.0772 22.4039C13.8347 22.4535 13.5834 22.428 13.355 22.3307C13.1266 22.2334 12.9313 22.0686 12.794 21.8572C12.6566 21.6457 12.5833 21.3971 12.5833 21.1429C12.5833 20.8019 12.715 20.4748 12.9494 20.2337C13.1839 19.9926 13.5018 19.8571 13.8333 19.8571Z"
                      fill="#4E99DB" />
                    <path
                      d="M23.8333 9.75H23V8.875C23 8.64294 22.9122 8.42038 22.7559 8.25628C22.5996 8.09219 22.3877 8 22.1667 8C21.9457 8 21.7337 8.09219 21.5774 8.25628C21.4211 8.42038 21.3333 8.64294 21.3333 8.875V9.75H14.6667V8.875C14.6667 8.64294 14.5789 8.42038 14.4226 8.25628C14.2663 8.09219 14.0543 8 13.8333 8C13.6123 8 13.4004 8.09219 13.2441 8.25628C13.0878 8.42038 13 8.64294 13 8.875V9.75H12.1667C11.062 9.75139 10.003 10.2128 9.22185 11.0329C8.44073 11.8531 8.00132 12.9651 8 14.125L8 15H28V14.125C27.9987 12.9651 27.5593 11.8531 26.7782 11.0329C25.997 10.2128 24.938 9.75139 23.8333 9.75Z"
                      fill="#2177C1" />
                  </svg>
                  <span class="asset_text">2 years certification</span>
                </div>
                <div class="flex-col items-center gap-2">
                  <img class="block mb-5 mt-5" src="@/assets/images/2x_certify/certified_2xicon.svg" width="72px" />
                  <span class="asset_text" style="max-width: 450px">Certified icon displayed in our Deals directory,
                    <span style="font-weight: normal">in your 2X assessment landing page, and you can download
                      it for your website</span></span>
                </div>

                <div class="flex-col items-center gap-2">
                  <svg class="mb-5" width="72" height="72" viewBox="0 0 36 36" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect width="36" height="36" rx="6" fill="#EAF6FF" />
                    <path
                      d="M25 25.8772V30C25.6191 29.7877 26.1825 29.4388 26.6482 28.9789L28.9781 26.6474C29.4384 26.1822 29.7877 25.6191 30 25H25.8772C25.6445 25 25.4214 25.0924 25.2569 25.2569C25.0924 25.4214 25 25.6445 25 25.8772Z"
                      fill="#2177C1" />
                    <path
                      d="M15.9451 21.6358C15.6454 21.9353 15.4078 22.2909 15.2456 22.6824C15.0834 23.0738 15 23.4933 15 23.917V25H16.083C16.5067 25 16.9262 24.9166 17.3176 24.7544C17.7091 24.5922 18.0647 24.3546 18.3642 24.0549L27.499 14.9202C27.8198 14.5994 28 14.1643 28 13.7106C28 13.2569 27.8198 12.8218 27.499 12.501C27.1782 12.1802 26.7431 12 26.2894 12C25.8357 12 25.4006 12.1802 25.0798 12.501L15.9451 21.6358Z"
                      fill="#4E99DB" />
                    <path
                      d="M30 14.1267C29.8581 14.7448 29.5464 15.3111 29.1 15.7617L19.655 25.2025C19.1917 25.6682 18.6407 26.0374 18.0337 26.2888C17.4268 26.5401 16.7761 26.6685 16.1192 26.6667H15C14.558 26.6667 14.134 26.4911 13.8215 26.1785C13.5089 25.8659 13.3333 25.442 13.3333 25V23.8808C13.3315 23.224 13.4601 22.5733 13.7115 21.9665C13.963 21.3597 14.3324 20.8088 14.7983 20.3458L24.2383 10.905C24.6855 10.4579 25.2486 10.1445 25.8642 10C25.8533 10 25.8442 10 25.8333 10H14.1667C13.062 10.0013 12.003 10.4407 11.2218 11.2218C10.4407 12.003 10.0013 13.062 10 14.1667L10 25.8333C10.0013 26.938 10.4407 27.997 11.2218 28.7782C12.003 29.5593 13.062 29.9987 14.1667 30H23.3333V25.8333C23.3333 25.1703 23.5967 24.5344 24.0656 24.0656C24.5344 23.5967 25.1703 23.3333 25.8333 23.3333H30V14.1667C30 14.1533 30 14.1408 30 14.1267Z"
                      fill="#4E99DB" />
                  </svg>

                  <span class="asset_text">Customized action plan <br /><span style="font-weight: normal">to
                      download</span></span>
                </div>
                <div class="flex-col items-center gap-2">
                  <svg class="mb-5" width="72" height="72" viewBox="0 0 36 36" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect width="36" height="36" rx="6" fill="#FFECE8" />
                    <path
                      d="M26.7363 17.116L17.9992 22.2372L9.26204 17.116C9.07253 17.0049 8.84561 16.9719 8.6312 17.0243C8.41679 17.0767 8.23246 17.2101 8.11875 17.3952C8.00505 17.5803 7.97128 17.8019 8.02488 18.0113C8.07848 18.2208 8.21506 18.4008 8.40458 18.5119L17.5709 23.8838C17.7005 23.9598 17.8488 24 18 24C18.1512 24 18.2995 23.9598 18.4291 23.8838L27.5954 18.5119C27.7849 18.4008 27.9215 18.2208 27.9751 18.0113C28.0287 17.8019 27.995 17.5803 27.8812 17.3952C27.7675 17.2101 27.5832 17.0767 27.3688 17.0243C27.1544 16.9719 26.9275 17.0049 26.738 17.116H26.7363Z"
                      fill="#CB2E00" />
                    <path
                      d="M26.7363 21.116L17.9992 26.2364L9.26204 21.116C9.1682 21.061 9.06419 21.0246 8.95595 21.0089C8.84771 20.9931 8.73736 20.9984 8.6312 21.0243C8.52503 21.0502 8.42514 21.0963 8.33721 21.16C8.24929 21.2236 8.17505 21.3035 8.11875 21.3952C8.06245 21.4868 8.02518 21.5884 8.00908 21.6941C7.99297 21.7999 7.99834 21.9076 8.02488 22.0113C8.07848 22.2208 8.21506 22.4008 8.40457 22.5119L17.5708 27.8838C17.7005 27.9598 17.8488 28 18 28C18.1512 28 18.2995 27.9598 18.4291 27.8838L27.5954 22.5119C27.7849 22.4008 27.9215 22.2208 27.9751 22.0113C28.0287 21.8019 27.995 21.5803 27.8812 21.3952C27.7675 21.2101 27.5832 21.0767 27.3688 21.0243C27.1544 20.9719 26.9275 21.0049 26.738 21.116H26.7363Z"
                      fill="#CB2E00" />
                    <path
                      d="M8.40391 14.7068L16.7241 19.6494C17.1094 19.8788 17.5504 20 18 20C18.4496 20 18.8906 19.8788 19.2759 19.6494L27.5961 14.7068C27.7193 14.6335 27.8212 14.5298 27.892 14.4059C27.9628 14.282 28 14.1421 28 13.9998C28 13.8575 27.9628 13.7176 27.892 13.5937C27.8212 13.4698 27.7193 13.3661 27.5961 13.2928L19.2759 8.35022C18.8905 8.12106 18.4495 8 18 8C17.5505 8 17.1095 8.12106 16.7241 8.35022L8.40391 13.2928C8.2807 13.3661 8.17875 13.4698 8.10797 13.5937C8.0372 13.7176 8 13.8575 8 13.9998C8 14.1421 8.0372 14.282 8.10797 14.4059C8.17875 14.5298 8.2807 14.6335 8.40391 14.7068Z"
                      fill="#FF501C" />
                  </svg>

                  <span class="asset_text">Social media materials kit</span>
                </div>
                <div class="flex-col items-center gap-2">
                  <svg class="mb-5" width="72" height="72" viewBox="0 0 36 36" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect width="36" height="36" rx="6" fill="#FFF8E0" />
                    <path
                      d="M27.9617 13L20.9467 19.7506C20.1645 20.5014 19.1048 20.923 18 20.923C16.8952 20.923 15.8355 20.5014 15.0533 19.7506L8.03833 13C8.02667 13.1267 8 13.2414 8 13.3673V22.9904C8.00132 24.0534 8.44073 25.0725 9.22185 25.8242C10.003 26.5759 11.062 26.9987 12.1667 27H23.8333C24.938 26.9987 25.997 26.5759 26.7782 25.8242C27.5593 25.0725 27.9987 24.0534 28 22.9904V13.3673C28 13.2414 27.9733 13.1267 27.9617 13Z"
                      fill="#E8AD17" />
                    <path
                      d="M19.6967 18.2944L27 10.9408C26.6462 10.3502 26.1472 9.86137 25.5512 9.52142C24.9551 9.18146 24.2821 9.00188 23.597 9H12.403C11.7179 9.00188 11.0449 9.18146 10.4488 9.52142C9.85279 9.86137 9.35379 10.3502 9 10.9408L16.3033 18.2944C16.7539 18.7463 17.364 19 18 19C18.636 19 19.2461 18.7463 19.6967 18.2944Z"
                      fill="#FFDE60" />
                  </svg>
                  <span class="asset_text" style="max-width: 450px">Communication material
                    <span style="font-weight: normal">to share this update with relevant stakeholders (i.e.,
                      investors)</span></span>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-if="isOpenSelectFundManager"
      @on-close="isOpenSelectFundManager = false"
    >
      <TwoXFundManagerAUM
        @on-cancel="isOpenSelectFundManager = false"
        @on-continue="handleOnSelectFundManager"
      />
    </Modal>

    <Modal v-if="isPaywallOpen" @on-close="isPaywallOpen = false">
      <template>
        <clip-loader
          v-if="isLoadingProducts"
          :size="'80px'"
          :color="'#2BD4C4'"
        />
        <div v-if="!isLoadingProducts && errorProducts" class="[ text-center ]">
          There was an error getting the products, please try it again later.
        </div>
        <Paywall
          v-if="!isLoadingProducts && !errorProducts"
          title="Continue your 2X Certification journey now!"
          subtitle="Take the next step towards certification and/or advance to the next level with our additional product offering."
          :items="products"
          :displayVerificationProduct="this.isTwoXEligible"
          @on-cancel="isPaywallOpen = false"
          @on-buy="handleOnBuyProducts"
        />
      </template>
    </Modal>
  </div>
</template>
<script>
import auth from "@/helpers/auth";
import env from "@/../env";
const axios = require("axios");
import Cookies from "@/helpers/cookies";
import pageLoader from "@/components/page-loader.vue";
import { useUserStore } from "@/stores/user";
import { SUBSCRIPTION_TYPE } from "@/security/subscriptionTypes";
import apiService from "@/helpers/apiServices.js";
import ClipLoader from "vue-spinner/src/ClipLoader";
import Paywall from "@/components/Paywall/index.vue";
import TwoXFundManagerAUM from "@/components/Paywall/2xFundManagerAUM.vue";
import Modal from "@/components/basics/Modal.vue";
import SubscriptionAPIs from "@/api/SubscriptionAPIs";

export default {
  name: "twoxassesment",
  data: function () {
    return {
      user: [],
      organizationDetail: [],
      currentStep: 1,
      loading: true,
      is2xCertification: false,
      inviteeEmail: "",
      selectedEntityTypeForDownloadQuestion: "",
      userStore: [],
      subscriptionType: "",
      status2xCertification: "",
      entityMap: {
        "financial-institution": "Financial Institution",
        "fund-manager": "Fund Manager",
        "start-up": "Start-up",
        sme: "SME",
        corporate: "Corporate",
      },
      certificationSurveyData: [],
      showDataProcessingScreen: false,
      isPaywallOpen: false,
      isOpenSelectFundManager: false,
      isLoadingProducts: false,
      products: [],
      errorProducts: "",
      entityType2x: "",
      fundManagerOptions: [
        { value: "Funds (AUM<$100M)", text: "Funds (AUM < $100M)" },
        { value: "Funds (AUM>$100M)", text: "Funds (AUM > $100M)" },
      ],
      fundManagerType: "",
      certificationScoreData: [],
      entityChosen: "",
    };
  },
  components: {
    pageLoader,
    ClipLoader,
    Paywall,
    TwoXFundManagerAUM,
    Modal
  },
  methods: {
    proceedToLevelUp() {
      if(this.isPaywallRequired) {
        this.$router.push({ path: '/2X-Ecosystem/2X-Certification/IndicativeResults' })
      } else {
        this.tryPaywall();
      }
    },
    tryPaywall() {
      if (this.entityType2x && this.entityType2x !== "Fund Manager") {
        // User needs to do the payment
        this.getStripeProducts();
      } else if (this.entityType2x === "Fund Manager") {
        // User needs to select Entity 'Funds (AUM<$100M)' | 'Funds (AUM>$100M)'
        this.isOpenSelectFundManager = true;
      } else {
        // TODO: financial-institution was selected, what to do here?
      }
    },
    async getStripeProducts() {
      // Entity type is selected and, it's not "Fund Manager" or if it's the case
      //  the fundManagerType is not empty (>100M or <100M)
      if (
        this.entityType2x &&
        (this.entityType2x !== "Fund Manager" || this.fundManagerType !== "")
      ) {
        this.isPaywallOpen = true;
        this.isLoadingProducts = true;
        this.errorProducts = "";

        try {
          const response = await axios.get(
            `${env.apiUrl}/subscriptions/twox-products?type=User&entityType=${this.entityType2x}`,
            {
              headers: {
                Authorization: `Bearer ${Cookies.get("session")}`,
              },
            }
          );
          const data = response.data.data;

          // This logic applies ONLY for FUND MANAGER, because the prices that depends on the AUM comes
          //  as part of the payload. So, we need to filter them first.
          let products = [];
          if (this.entityType2x === "Fund Manager") {
            products = data.map((product) => {
              product.prices = product.prices.filter(
                (price) =>
                  price.name.toLowerCase() ===
                  this.fundManagerType.toLowerCase()
              );
              return product;
            });
          } else {
            products = data.map((product) => product);
          }

          // Checks if the user is TwoXEligible, if not, the "2X Certification" product is not included in the list of products
          if (!this.isTwoXEligible) {
            products = products.filter(
              (product) => product.name !== "2X Certification"
            );
          }

          this.products = products;
        } catch (error) {
          console.log(error);
          this.errorProducts = error;
          setTimeout(() => {
            this.errorProducts = "";
          }, 3000);
        } finally {
          this.isLoadingProducts = false;
        }
      }
    },
    async handleOnBuyProducts(products) {
      const priceIds = products.map((product) => product.stripePriceId);
      await this.getStripeLink(priceIds);
    },
    async getStripeLink(priceIds) {
      if (!this.isPaywallRequired) {
        try {
          this.isLoading = true;

          const certificationProduct = this.products.find(
            (product) => product.name === "2X Certification"
          );
          const levelUpProduct = this.products.find(
            (product) => product.name === "Level-Up Package"
          );
          const mlAddOnStripePriceId =
            levelUpProduct?.prices[0]?.addOnPrice?.stripePriceId ?? "";

          const indicativeResultsURL = `/2X-Ecosystem/2X-Certification/IndicativeResults`;
          const uploadDocumentsURL = `/2X-Ecosystem/2X-Certification/UploadDocuments`;
          const uploadDocumentsMLURL = `/2X-Ecosystem/upload-files`;
          const fullDetailedAssessmentURL = `/2X-Ecosystem/2X-Certification/Self-Assessment-detailed-results`;

          const hasCertificateCost = priceIds.includes(
            certificationProduct?.prices[0]?.stripePriceId
          );
          const hasLevelUp = priceIds.includes(
            levelUpProduct?.prices[0]?.stripePriceId
          );
          const hasMLAddOn = priceIds.includes(mlAddOnStripePriceId);

          // Check what page the user will reach. DO NOT alter this order.
          let landingURL = indicativeResultsURL;

          // Full detailed assessment page
          if (hasLevelUp) {
            landingURL = fullDetailedAssessmentURL;
          }

          // Go to Upload Documents ML page
          if (hasLevelUp && hasMLAddOn) {
            landingURL = uploadDocumentsMLURL;
          }

          // Upload document page
          if (hasCertificateCost) {
            landingURL = uploadDocumentsURL;
          }

          const encodedIndicativeURL = encodeURIComponent(landingURL);
          const redirectURL = `${window.location.origin}/#/check-session?type=User&teamId=${this.user?.organization?._id}&redirectURL=${encodedIndicativeURL}`;

          const subscriptionAPI = new SubscriptionAPIs();
          const response = await subscriptionAPI.createCheckoutSession(
            "Global",
            {
              type: "User",
              redirectUrl: redirectURL,
              priceIds: priceIds,
            }
          );
          window.location.href = response.data.data;
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    handleOnSelectFundManager(fundManagerType) {
      this.isOpenSelectFundManager = false;
      this.fundManagerType = fundManagerType;
      this.getStripeProducts();
    },
    async fetchCertificationData() {
      await apiService
        .fetch2xCertificationData(
          this.entityChosen,
          this.$route?.query?.teamId,
          this.$route.path ==
            "/2X-Challenge/2X-Challenge-Self-Assessment-Results"
            ? "Min"
            : "Full"
        )
        .then(async (res) => {
          this.certificationScoreData = await res.data.data.latest;
        })
        .catch(() => {
          this.endofcreated = true;
        });
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(email)) {
        return true;
      } else {
        return false;
      }
    },
    async sendInviteToCompany() {
      const c_email = this.inviteeEmail;
      let validate = this.validEmail(c_email);
      if (validate) {
        const payload = {
          email: c_email,
        };

        try {
          const response = await axios.post(
            `${env.apiUrl}/2X-invites/certification`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${Cookies.get("session")}`,
              },
            }
          );
          if (response.data.data) {
            this.$swal.fire({
              icon: "success",
              text: "Success! Your invitation was sent!",
              showConfirmButton: true,
            });
            this.inviteeEmail = "";
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Please try again!",
              showConfirmButton: true,
            });
          }
          return response.data.data;
        } catch (error) {
          this.$swal.fire({
            icon: "error",
            text: error.response.data.error || "Something went wrong!",
            showConfirmButton: true,
          });
          throw error;
        }
      } else {
        alert("Please enter valid email address!");
      }
    },
    goToThirdPartyPage() {
      if (this.$store.state.certPageAccess.isVerificationPage) {
        this.$router.push({
          path: "/2X-Ecosystem/2X-Certification/Verification",
        });
      } else {
        this.$router.push({
          path: "/2X-Ecosystem/2X-Certification/UploadDocuments",
        });
      }
    },
    see2XThresholdCriteria() {
      let routeData = this.$router.resolve({
        path: "/2X-Ecosystem/Thresholds",
      });
      window.open(routeData.href, "_blank");
    },
    mouseOver: function (index) {
      this.active = index;
    },
    getOrganizationDetail(organization_id) {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/organizations/" + organization_id, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    navigateModule(val) {
      const routes = {
        "/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment": {
          condition:
            this.$store.state.certPageAccess.isFullSurveyFill &&
            !this.$store.state.certPageAccess.isUploadDocPage,
        },
        "/2X-Challenge/2X-Challenge-Self-Assessment": {
          condition:
            this.$store.state.certPageAccess.isMinSurveyFill &&
            !this.$store.state.certPageAccess.isUploadDocPage,
        },
      };

      const route = routes[val];
      if (route) {
        const path = val;
        const query = route.condition ? { twoxRepeatAsses: "true" } : undefined;

        this.$router.push({ path, query });
      }
    },
  },
  computed: {
    isPaywallRequired() {
      return (this.user.featureFlags?.includes("proceedToVerification") || this.user.featureFlags?.includes("viewAction") ||
      this.user.featureFlags?.includes("2xDownload") || this.user.featureFlags?.includes("viewScore"));
    },
    isTwoXEligible() {
      return this.twoxEligible === "Yes";
    },
    twoxEligible() {
      let val;
      if (this.entityChosen === "fund-manager") {
        val = this.certificationScoreData["2X_2X_Score_Overall_301"];
      } else if (this.entityChosen === "financial-institution") {
        val = this.certificationScoreData["2X_2X_Score_Overall_302"];
      } else if (
        this.entityChosen === "start-up" ||
        this.entityChosen === "sme" ||
        this.entityChosen === "corporate"
      ) {
        val = this.certificationScoreData["2X_2X_Score_Overall_300"];
      }
      return val;
    },
  },

  async mounted() {
    if (!localStorage.getItem("is2XChallengeVisited")) {
      this.is2xBetaModal = true;
      localStorage.setItem("is2XChallengeVisited", "true");
    }
    this.loading = true;
    this.$store.commit("resetCitations");
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    this.userStore = useUserStore();
    this.subscriptionType = SUBSCRIPTION_TYPE;
    this.organizationDetail = await this.getOrganizationDetail(
      this.user.organization._id
    );
    await apiService
      .fetch2xCertRequest(
        this.$route.query?.teamId
          ? this.$route.query?.teamId
          : this.user?.organization?._id
      )
      .then(async (response) => {
        this.status2xCertification = response?.data?.data?.status;
      });
    await apiService
      .fetch2xUserSurvey(
        "2x",
        this.$route.query?.teamId
          ? this.$route.query?.teamId
          : this.user?.organization?._id
      )
      .then(async (response) => {
        this.certificationSurveyData = await response.data.data;
      });
    if (this.certificationSurveyData) {
      this.certificationSurveyData?.forEach((data) => {
        if (data.surveyId === "2x-intake") {
          this.entityChosen = data.tag;
          this.entityType2x = this.entityMap[data.tag]
            ? this.entityMap[data.tag]
            : "";
        }
      });
    }
    await this.fetchCertificationData();
    await this.$store.commit("setCertPageStatus", this.status2xCertification);
    this.status2xCertification == "Min Survey Submitted" ||
    this.$route.path.includes("2X-Certification")
      ? (this.currentStep = 3)
      : 1;
    if (this.$store.state.certPageAccess.isMinSurveyFill) {
      this.currentStep = 3;
    }
    if (this.$store.state.certPageAccess.isFullSurveyFill) {
      this.currentStep = 4;
    }
    if (this.$store.state.certPageAccess.isUploadDocPage) {
      this.currentStep = 5;
    }
    if (this.$store.state.certPageAccess.isGlobalPage) {
      this.currentStep = 7;
    }
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", [
      { name: "2X Certification", url: "/2X-Ecosystem/2X-Certification" },
    ]);
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.bg-image-section-2 {
  background-image: url("./../../assets/images/2x-certification-section-2.png");
  height: 600px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.bg-image-section-3 {
  background-image: url("./../../assets/images/2x-certification-section-3.png");
  height: 600px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.ContentWrapper {
  margin-top: 10px;
  .in-active-button {
    opacity: 0.6;
    cursor: auto;
    pointer-events: none;
  }
  .invitee-email-input {
    width: 302px;
    // height: 48px;
    padding: 12px;
    border-radius: 8px;
    gap: 8px;
    margin: 0;
    border: 1px solid #e1e3e9;
    color: linear-gradient(0deg, #e1e3e9, #e1e3e9),
      linear-gradient(0deg, #ffffff, #ffffff);
  }
  .invitee-email-input::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #9ea4ba;
  }

  .progress-steps-section {
    color: #21191d;

    .outer {
      min-width: 20vw;
      flex: 1;
    }

    .progress {
      $gap: 20px;
      $line-height: 20px;
      $bullet-radius: 5px;
      $line-thick: 5px;
      $strip-color: #666461;
      $next-color: #c2beba;
      $current-color: #666461;
      $prev-color: #666461;

      display: inline-flex;
      height: 100%;
      padding: 5vh 10%;

      > div {
        display: flex;
        flex-direction: column;
        color: $prev-color;

        &.left {
          padding-right: $gap;
          text-align: right;

          // Line
          div {
            &:last-of-type:after {
              display: none;
            }

            &:after {
              content: "";
              background: fade_out($strip-color, 0.9); //rgba(0, 0, 0, 0.6);
              border-radius: 2px;
              position: absolute;
              right: -$gap;
              top: $line-height/2;
              height: 101%;
              width: 1px;
              transform: translateX(50%);
            }
          }
        }

        &.right {
          padding-left: $gap;

          div {
            span {
              color: #21191d;
            }

            button {
              span {
                color: #fff;
              }
            }

            &.prev {
              &:after {
                transition: none;
              }
            }

            &.current {
              color: $current-color;
              font-weight: bold;
              z-index: 1;

              &:before {
                background: $current-color;
                padding: 15px;
                transition: all 0.2s 0.15s cubic-bezier(0.175, 0.885, 0.32, 2);
                z-index: 1;
              }

              &:last-child:after {
                height: 0%;
                transition: height 0.2s ease-out;
              }

              &:after {
                content: "";
                background: $next-color; //rgba(0, 0, 0, 0.6);
                border-radius: 2px;
                position: absolute;
                left: -40px;
                top: $line-height/2;
                height: 101%;
                width: $line-thick;
                transform: translateX(-50%);
                transition: height 0.2s ease;
              }

              ~ div {
                color: $next-color;

                &:before {
                  background: $next-color;
                  padding: 15px;
                }

                &:after {
                  content: "";
                  background: $next-color; //rgba(0, 0, 0, 0.6);
                  border-radius: 2px;
                  position: absolute;
                  left: -40px;
                  top: $line-height/2;
                  height: 101%;
                  width: $line-thick;
                  transform: translateX(-50%);
                  transition: height 0.2s ease;
                }

                &:last-child:after {
                  height: 0%;
                  transition: none;
                }
              }
            }

            // Dot
            &:before {
              content: "";
              background: $strip-color;
              padding: 15px;
              border-radius: 50%;
              position: absolute;
              left: -40px;
              top: $line-height/2;
              transform: translateX(-50%) translateY(-50%);
              transition: padding 0.2s ease;
            }

            // Line
            &:after {
              content: "";
              background: $strip-color; //rgba(0, 0, 0, 0.6);
              border-radius: 2px;
              position: absolute;
              left: -40px;
              top: $line-height/2;
              height: 101%;
              width: $line-thick;
              transform: translateX(-50%);
              transition: height 0.2s ease;
            }
          }
        }

        div {
          flex: 1;
          //outline: 1px solid rgba(0, 0, 0, 0.1);
          position: relative;
          line-height: $line-height;
          cursor: default;
          height: fit-content;

          &:last-of-type {
            flex: 0;
          }
        }
      }
    }

    .done.current {
      color: #62af0b !important;

      &:before {
        background: #62af0b !important;
      }
    }
  }

  .standard-button {
    font-weight: bold;
    font-size: 12px;
    color: #19486a;
    border-style: solid;
    border-color: #19486a;
    border-width: 1px;
  }

  .certification-button {
    font-weight: 500;
    color: white;
    background: #19486a;
    font-size: 16px;
  }
}

.asset_text {
  font-size: 16px;
  font-weight: bold;
  color: #1f1747;
}

.grey-out {
  pointer-events: none;
  opacity: 0.7;
}

router-link {
  cursor: pointer;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 45px;
  color: #000;
}

.ecosystem-landing {
  background-color: #f4f1ed;
  color: #21191d !important;
  font-family: Work Sans;
  padding-top: 12em;

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .bg-text-style {
      margin-left: 9rem;
      position: relative;
      top: 20rem;
      color: #f4f1ed;
      width: 570px;
    }

    .PoweredbyTitleWrepper {
      max-width: 847px;
      display: flex;

      // align-items: center;
      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powered-text {
        span {
          font-size: 14px;
          line-height: 20px;
          margin-right: 35px;
          font-weight: 400;
          color: #19486a;
        }
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .bg-image {
    background-image: linear-gradient(
        201deg,
        rgba(0, 0, 0, 0.1) 55%,
        rgba(221, 166, 58, 0.8) 100%
      ),
      url("./../../assets/images/2x-certification-banner.png");
    height: 700px;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    margin-top: 2em;
  }

  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    top: 0;
    position: relative;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .Modal_Readmorestyle {
    position: relative;
    display: flex;

    .ModalInnerStyle {
      background: #fff;

      .heading-section {
        gap: 28px;
      }

      h1 {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #dda63a;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }

      .textarea-section {
        width: 552px;
        gap: 12px;

        textarea {
          margin: 0;
          min-width: 576px;
          min-height: 120px;
          padding: 12px;
          border-radius: 8px;
          border: 1px solid #e1e3e9;
          gap: 16px;
        }

        textarea::placeholder {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }
      }

      .cancel-button {
        width: auto;
        height: 40px;
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        gap: 8px;
        background: #dda63a;
        border: 1px solid #dda63a;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
      }

      .save-button {
        width: 280px;
        height: 40px;
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        gap: 8px;
        background: #191b45;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
      }

      .congrats-box-doc {
        border: 1px solid #2177c1;
        border-radius: 16px;
        padding: 32px, 0px, 32px, 0px;
        background: #eaf6ff;
        border-style: dashed;
        height: 160px;
      }
    }
  }

  .what-modal {
    padding: 24px 0 24px 24px;

    .content {
    }

    .modal-link-button {
      font-weight: bold;
      color: #19486a;
      border-style: solid;
      border-color: #19486a;
      border-width: 1px;
      padding: 12px 24px 12px 24px;
      width: 250px;
      font-size: 16px;
    }

    .modal-send-button {
      font-weight: bold;
      color: white;
      background-color: #3f7e44;
      border-radius: 8px;
      border-width: 1px;
      font-size: 16px;
      width: 250px;
    }

    .center-span-modal {
      position: relative;
      top: 35px;
      line-height: 20px;
      font-family: arial;
      color: #191b45;
    }

    .upload-documents-button {
      font-weight: bold;
      color: white;
      background: #2177c1;
      // width: 150px;
      padding: 8px 16px 8px 16px;
    }

    .center-button {
      position: relative;
      top: 27px;
    }
  }
}

.pointer-events-none {
  pointer-events: none;
  opacity: 0.7;
}

input {
  margin: 6px;
}

#SPAN_1:hover {
  color: #40a9ff;
}
</style>
