<template>
    <div class="static">
        <div class="theme-badge-container container mx-auto px-4 lg:px-0 pb-32">
            <div id="notLoaded" v-if="!endofcreated" key="loading">
                <page-loader></page-loader>
            </div>
            <BreadCrumbs class="breadcrumbs-section"></BreadCrumbs>
            <div class="PoweredbyTitle_logo mt-6">
                <div class="PoweredbyContentWrepper">
                    <div class="PoweredbyTitleWrepper">
                        <h3 class="text-3xl tracking-tighter font-bold print:text-7xl"
                            style="color: #201747; max-width: 845px">
                            <span>Thematic Scores</span>
                        </h3>
                    </div>
                </div>
                <div style="display: flex; justify-content: flex-end">
                    <div class="Poweredby_logoImg mt-2">
                        <div class="powered-text flex mr-8">
                            <span>Powered by:</span>
                            <img src="@/assets/images/2X.png" alt="background-image" style="width: 80px;" />
                        </div>
                        <img src="@/assets/images/logo-navy.png" alt="background-image" />
                    </div>
                </div>
            </div>
            <div class="spider-chart-section mt-6">
                <div class="p-8 inner-section flex justify-between">
                    <div class="progress-bar-container w-1/4">
                        <div class="progress-bar mb-6 flex" v-for="(theme, index) in themes" :key="theme.name + '_theme_1'" v-show="theme.name != 'Climate'">
                            <div v-if="theme.name === 'Climate' && (!themesScores[index] || themesScores[index] === '' || themesScores[index] == 'NA')" class="bigImg">
                                <img style="width: 60px; height: 60px; line-height: 0" src="@/assets/images/NA-SVG.svg" alt="background-image" />
                            </div>
                            <progress-bar v-else :options="theme.name == 'Climate' ? options_grey_out : (expectedLevels.includes(criteriaMet[index]) ? options_eligible : options_notEligible)" :value="themesScores[index]"
                                style="width: 60px; height: 60px; line-height: 0;left:20" />
                            <div class="theme-name flex items-center" :style="'color:' + theme.color">
                                {{ theme.name }}
                            </div>
                        </div>
                    </div>
                    <div class="spider-chart-container w-3/4">
                        <div class="z-20 relative">
                            <SpiderChart
                                :themes="themes"
                                :themesScores="themesScores"
                                :assessmentYear="certificationScoreData.assessmentYear"
                                :paddingL="40"
                                :paddingR="40"
                                :paddingT="40"
                                :paddingB="40"
                                :key="themesScores[0]"
                                class="z-10 overflow-visible" style="position: relative; height: 100%; width: 100%" />
                            <div class="flex items-center" v-for="theme in themes" :key="theme.name + '_theme_2'"
                                :style="'color: ' + theme.color + '; position: absolute; left:' + theme.left + '%; top:' + theme.top + '%;'">
                                <span style="font-weight: bold;">{{ theme.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ThemeBadgeBox class="mb-11"
                :cert_categories="cert_categories"
                :certificationScoreData="certificationScoreData"
                :displayArrowButton=false>
            </ThemeBadgeBox>
        </div>
        <div class="bottom-button-section text-right">
            <button @click="$router.push({ path: '/2X-Ecosystem/2X-Certification/GlobalCertification' })">Go back to
                Certification
                results</button>
        </div>
    </div>
</template>
<script>
import auth from "@/helpers/auth";
import SpiderChart from "@/components/charts/my2xCertification-ThemesSpiderChart.vue"
import BreadCrumbs from "@/components/new-breadcrumbs.vue"
import ThemeBadgeBox from "@/components/themeBadgesBox.vue"
import pageLoader from "@/components/page-loader.vue";
import apiService from '@/helpers/apiServices.js';
export default {
    name: "My2XCertificationThemeBadgePage",
    data() {
        return {
            themes: [
                { name: 'GBVH', percent: '', color: '#201747', left: 46, top: 4 },
                { name: 'Climate', percent: null, color: '#4A742C', left: 68, top: 25 },
                { name: 'Health & Well-being', percent: '', color: '#7C7C7C', left: 68, top: 70 },
                { name: 'JEDI', percent: '', color: '#2574BB', left: 40, top: 92 },
                { name: 'Care', percent: '', color: '#F05323', left: 22, top: 70 },
                { name: 'Pay', percent:'', color: '#9A2B15', left: 22, top: 25 },
            ],
            options_eligible: {
                text: {
                color: "#2BD4C4",
                fontSize: 12,
                fontFamily: "Montserrat",
                fontWeight: "bold",
                shadowEnable: false,
                shadowColor: "#000000",
                dynamicPosition: false,
                hideText: false,
                },
                progress: {
                color: "#2BD4C4",
                backgroundColor: "#D0D0D0",
                inverted: false,
                },
                    layout: {
                    height: 60,
                    width: 60,
                    verticalTextAlign: 30,
                    horizontalTextAlign: 12,
                    zeroOffset: 0,
                    strokeWidth: 12,
                    progressPadding: 2,
                    type: "circle",
                },
            },
            options_notEligible: {
                text: {
                color: "#FE5000",
                fontSize: 12,
                fontFamily: "Montserrat",
                fontWeight: "bold",
                shadowEnable: false,
                shadowColor: "#000000",
                dynamicPosition: false,
                hideText: false,
                },
                progress: {
                color: "#FE5000",
                backgroundColor: "#D0D0D0",
                inverted: false,
                },
                layout: {
                height: 60,
                width: 60,
                verticalTextAlign: 30,
                horizontalTextAlign: 12,
                zeroOffset: 0,
                strokeWidth: 12,
                progressPadding: 2,
                type: "circle",
                },
            },
            options_grey_out: {
                text: {
                color: "#1C1C1C",
                fontSize: 12,
                fontFamily: "Montserrat",
                fontWeight: "bold",
                shadowEnable: false,
                shadowColor: "#000000",
                dynamicPosition: false,
                hideText: false,
                },
                progress: {
                color: "#7C7C7C",
                backgroundColor: "#D0D0D0",
                inverted: false,
                },
                layout: {
                height: 60,
                width: 60,
                verticalTextAlign: 30,
                horizontalTextAlign: 12,
                zeroOffset: 0,
                strokeWidth: 12,
                progressPadding: 2,
                type: "circle",
                },
            },
            cert_categories: {
                Good: {
                    name: "Good",
                    darkColor: "#FF501C",
                    lightColor: "#FFECE8",
                },
                Advanced: {
                    name: "Advanced",
                    darkColor: "#E8AD17",
                    lightColor: "#FFF8E0",
                },
                'Best in Class': {
                    name: "Best in Class",
                    darkColor: "#4DC9BE",
                    lightColor: "#E5FAFA",
                },
                InProgress: {
                    name: "In Progress",
                    darkColor: "#4DC9BE",
                    lightColor: "#E5FAFA",
                }
            },
            user: [],
            certificationScoreData: [],
            entityChosen: "",
            certificationSurveyData: [],
            endofcreated: false,
            themesScores: [],
            criteriaMet: [],
            status2xCertification: "",
            expectedLevels: ['Good', 'Best in Class', 'Advanced'],
        }
    },
    components: {
        SpiderChart,
        BreadCrumbs,
        ThemeBadgeBox,
        pageLoader
    },
    async mounted() {
        this.user = await auth.me().catch(() => {
            console.log("Error login");
        });
        await apiService.fetch2xUserSurvey("2x", this.$route.query?.teamId ? this.$route.query?.teamId : this.user?.organization?._id).then(async (response) => {
            this.certificationSurveyData = await response.data.data;
        });
        await apiService
        .fetch2xCertRequest(this.$route.query?.teamId ? this.$route.query?.teamId : this.user?.organization?._id)
        .then(async (response) => {
            this.status2xCertification = response?.data?.data?.status;
        });
        if(!this.certificationSurveyData) {
            this.$router.push({
                path: "/2X-Ecosystem/2X-Certification",
            });
        }
        if (this.certificationSurveyData) {
            await this.certificationSurveyData.forEach(data => {
                if (data.surveyId == '2x-intake') {
                    this.entityChosen = data.tag;
                }
            })
        }
        await this.$store.commit("setCertPageStatus", this.status2xCertification);
        await this.fetchCertificationData();
        this.$store.commit("setNewBreadcrumbs", [
            { name: "Get Your 2X Certification", url: "/2X-Challenge/2X-Challenge-Self-Assessment" },
            { name: "2X Global", url: "/2X-Ecosystem/2X-Certification/GlobalCertification" },
            { name: "Theme Badges", url: "/2X-Ecosystem/2X-Certification/theme-badge" },
        ]);
    },
    methods: {
        async fetchCertificationData() {
            await apiService.fetch2xCertificationData(this.entityChosen, this.$route?.query?.teamId, this.$store.state.certPageAccess.isFullSurveyFill ? 'Full' : 'Min')
            .then(async (res) => {
                this.certificationScoreData = res.data.data.latest;
                this.themesScores = [
                    Math.round(this.certificationScoreData['2X_2X_Score_Overall_511']),
                    '',
                    Math.round(this.certificationScoreData['2X_2X_Score_Overall_508']),
                    Math.round(this.certificationScoreData['2X_2X_Score_Overall_507']),
                    Math.round(this.certificationScoreData['2X_2X_Score_Overall_510']),
                    Math.round(this.certificationScoreData['2X_2X_Score_Overall_506'])
                ]
                this.criteriaMet = [
                    this.certificationScoreData['2X_2X_Score_Overall_523'],
                    '',
                    this.certificationScoreData['2X_2X_Score_Overall_521'],
                    this.certificationScoreData['2X_2X_Score_Overall_520'],
                    this.certificationScoreData['2X_2X_Score_Overall_522'],
                    this.certificationScoreData['2X_2X_Score_Overall_519'],
                ]
                this.endofcreated = true;
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.theme-badge-container {
    .breadcrumbs-section {
        margin-top: 100px;
    }
    .PoweredbyTitle_logo {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .PoweredbyTitleWrepper {
            max-width: 847px;
            widows: 100%;
            display: flex;

            img {
                width: 100px;
                height: 100px !important;
                object-fit: contain;
                display: none;
            }
        }

        .Poweredby_logoImg {
            display: flex;
            justify-content: flex-end;

            .powered-text {
                span {
                    font-size: 14px;
                    line-height: 20px;
                    margin-right: 35px;
                    font-weight: 400;
                    color: #2C2E35;
                }
            }

            img {
                width: 100px;
                height: 33px;
                object-fit: contain;
                margin-right: 35px;
                max-width: 100px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .spider-chart-section {
        border-radius: 16px;
        gap: 32px;
        border: 1px solid #E1E3E9;

        .inner-section {
            .progress-bar-container {
                .progress-bar {
                    width: 187px;
                    gap: 20px;

                    .theme-name {
                        font-family: Arial;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: left;
                    }
                }
            }
        }
    }
}

.bottom-button-section {
    margin-top: 143px;
    height: 120px;
    padding: 36px 80px 36px 80px;
    border-top: 1px solid #E1E3E9;
    gap: 24px;
    box-shadow: 0px -2px 20px 0px #636E801A;

    button {
        height: 48px;
        gap: 20px;
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        border: 1px solid #191B45;
        color: #191B45;
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
    }
}
</style>
