/**
 * Parses a period string into object
 * @param {String} period Period formatted as YYYY, YYYY-Q# or YYYY-MM
 * @returns {Object} Object with year, quarter and month properties
 */
export function parsePeriod(period) {
  if (!period) {
    return { year: null, quarter: null, month: null };
  }
  if (/^\d{4}$/.test(period)) {
    return { year: parseInt(period), quarter: null, month: null };
  }
  if (/^\d{4}-Q[1-4]$/.test(period)) {
    const [year, quarter] = period.split("-");
    return { year: parseInt(year), quarter: parseInt(quarter[1]), month: null };
  }
  if (/^\d{4}-\d{1,2}$/.test(period)) {
    const [year, month] = period.split("-");
    return { year: parseInt(year), quarter: null, month: parseInt(month) };
  }
}

/**
 * Formats a period object into a string
 * @param {Object} parsedPeriod - Object with year, quarter and month properties
 * @returns {String} Period formatted as YYYY, #QYY or YYYY-MM
 */
export function formatPeriod(parsedPeriod) {
  const { year, quarter, month } = parsedPeriod;
  if (month !== null && month !== undefined) {
    return `${year}-${month.toString().padStart(2, "0")}`;
  }
  if (quarter !== null && quarter !== undefined) {
    const shortYear = year.toString().slice(-2);
    return `${quarter}Q${shortYear}`;
  }
  return year.toString();
}

export function comparePeriod(parsedPeriodA, parsedPeriodB) {
  const hasMonthA = parsedPeriodA.quarter === null && parsedPeriodA.month === null;
  const hasMonthB = parsedPeriodB.quarter === null && parsedPeriodB.month === null;
  if (!hasMonthA || !hasMonthB) {
    // compare the years only, unless both periods have months
    if (parsedPeriodA.year < parsedPeriodB.year) {
      return -1;
    }
    if (parsedPeriodA.year > parsedPeriodB.year) {
      return 1;
    }
    return 0;
  }
  const intA = parsedPeriodA.year * 100 + (parsedPeriodA.quarter || 0) * 3 + parsedPeriodA.month;
  const intB = parsedPeriodB.year * 100 + (parsedPeriodB.quarter || 0) * 3 + parsedPeriodB.month;
  if (intA < intB) {
    return -1;
  }
  if (intA > intB) {
    return 1;
  }
  return 0;
}