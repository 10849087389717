<template>
    <div class="table-container mt-5 mx-5">
        <table class="w-full inner-table">
            <thead>
                <tr class="table-heading-row">
                    <th class="w-2/6">{{ headers?.[0] }}</th>
                    <th class="w-4/6">{{ headers?.[1] }}</th>
                </tr>
            </thead>
            <tbody>
                <tr class="table-content-row border-b" v-for="(indicator, k) in values?.indicators" :key="k">
                    <template>
                        <td class="" colspan="2" style="padding: 0; border: none;">
                            <table class="w-full">
                                <tbody>
                                    <tr class="min-w-full table-content-row">
                                        <td class="p-3" style="border: none;">
                                            <h3 class="font-bold">
                                                {{ indicator?.title }}:</h3>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="w-full">
                                <tbody>
                                    <tr class="min-w-full table-content-row" style="border-top: none;"
                                        v-for="(value) in indicator?.values" :key="value?.key">
                                        <td class="p-3 w-2/6 text-center"
                                            v-if="value.title.toLowerCase().includes('theme level overall')"
                                            style="border-top: none; border-right: 0px solid #e1e3e9;" rowspan="100%">
                                            <div class="flex items-center gap-10">
                                                <div>
                                                    <img v-if="value?.value != 'Failed'"
                                                    :src="(badges[value?.value?.toLowerCase()])" width="200" height="200" />
                                                    <span v-if="value?.value == 'Failed'">None</span>
                                                </div>
                                                <verification-dropdown v-if="$route.path.includes('/admin') && value?.surveyQType != 'Calculated'" :value="value" />
                                            </div>
                                        </td>
                                        <td class="w-4/6"
                                            v-if="!value?.title?.toLowerCase().includes('theme level overall') && value?.value !== 'Failed'"
                                            style="border: none;">
                                            <div class="flex items-center gap-5">
                                                <div>
                                                    <span class="font-semibold">{{ filteredTitle(value?.title, value) + ": "}}</span>
                                                    {{ (value?.value == 'Failed' ? 'None' : value?.value) }}
                                                </div>
                                                <verification-dropdown v-if="$route.path.includes('/admin') && value?.surveyQType != 'Calculated'" :value="value" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </template>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import VerificationDropdown from "./verificationDropdown.vue";
export default {
    name: "ThematicScoresSection",
    data() {
        return {

        }
    },
    props: ["values", "headers", "badges"],
    computed: {},
    components: {
        VerificationDropdown
    },
    methods: {
        filteredTitle(value, data) {
            let name = value;
            if (value.includes('Theme Level')) {
                name = "Thematic Certification Level";
            }
            if (value.includes('theme level within Domain')) {
                if (data.key.includes('GOVACC')) {
                    name = "Governance & Accountability";
                }
                if (data.key.includes('EMPLOY')) {
                    name = "Employment";
                }
                if (data.key.includes('PROD')) {
                    name = "Product & Services";
                }
                if (data.key.includes('SUPCH')) {
                    name = "Supply Chain";
                }
                if (data.key.includes('ENTOWN')) {
                    name = "Entrepreneurship & Ownership";
                }
                if (data.key.includes('LEAD')) {
                    name = "Leadership";
                }
            }
            return name;
        }
    },
}
</script>
<style lang="scss" scoped>
.table-container {
    // gap: 20px;
    border-radius: 16px;
    border: 1px solid #e1e3e9;
    .inner-table {
        width: 100%;
        th,
        td {
            border: 1px solid #e1e3e9;
        }
        .table-heading-row {
            th {
                padding: 16px;
                border-top: none;
                background: linear-gradient(0deg, #f4f6f9, #f4f6f9),
                    linear-gradient(0deg, #e1e3e9, #e1e3e9);
                font-family: Montserrat, Arial;
                font-size: 15px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #2c2e35;
            }

            th:first-child {
                border-top-left-radius: 16px;
                border-left: none;
            }

            th:last-child {
                border-top-right-radius: 16px;
                border-right: none;
            }
        }

        .table-content-row {
            img {
                margin: auto;
            }

            td {
                border-bottom: none;
                // height: 68px;
                padding: 16px 12px 16px 12px;
                // gap: 1px;
                font-family: Montserrat, Arial;
                font-size: 15px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                color: #2c2e35;
            }

            td:first-child {
                border-left: none;
            }

            td:last-child {
                border-right: none;
            }
        }
    }

    .red {
        color: #cb2e00;
    }

    .green {
        color: #13a094;
    }
}
</style>