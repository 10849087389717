<template>
  <div class="flex flex-col gap-4 justify-between my-5">
    <div class="h-[32px]" v-if="state === State.LOADING">
      <clip-loader :color="env.brandingColors.teal" :size="'24px'" />
    </div>
    <div v-else-if="state === State.READY">
      <PlotStackedPercentBarChart
        :title="`Progress of ${period || ''} Customised
      Impact Metrics`"
        :data="impactMetrics"
        :x-tick-step="25"
        :label="formattedPeriod"
        :target-label="`Remaining Impact Target`"
      />
    </div>
    <div v-else-if="state === State.NO_DATA">
      <div class="text-gray-500">No impact metrics available</div>
    </div>
    <div v-else-if="state === State.ERROR">
      <div class="text-red-500">Failed to fetch impact metrics</div>
    </div>
  </div>
</template>

<script>
import PlotStackedPercentBarChart from "@/components/imm-components/dashboard/module-cards-content/charts/Plot-StackedPercentBarChart.vue";
import ClipLoader from "vue-spinner/src/ClipLoader";
import env from "@/../env";
const { apiExecute } = require("@/helpers/api");
const { comparePeriod, parsePeriod, formatPeriod } = require("@/helpers/date");

// define enum for state with options: loading, ready, noData, error
const State = Object.freeze({
  LOADING: "LOADING",
  READY: "READY",
  NO_DATA: "NO_DATA",
  ERROR: "ERROR",
});

export default {
  name: "ImpactMetricsModuleCardContent",
  props: {
    frequency: {
      type: String,
      required: false,
    },
    period: {
      type: String,
      required: false,
    },
    teamId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      env,
      State,
      state: State.LOADING,
      metrics: null,
    };
  },
  mounted() {
    this.fetchMetrics();
  },
  computed: {
    formattedPeriod() {
      return formatPeriod(this.parsedPeriod);
    },
    parsedPeriod() {
      if (!this.period) {
        const now = new Date();
        return { year: now.getFullYear(), quarter: now.getMonth() / 3 + 1 };
      }
      return parsePeriod(this.period);
    },
    impactMetrics() {
      const compareEntries = (a, b) => {
        const aParsed = parsePeriod(a[0]);
        const bParsed = parsePeriod(b[0]);
        return comparePeriod(aParsed, bParsed);
      };

      return this.metrics.map((metric) => {
        const impacts = Object.entries(metric.impacts)
          .filter(([curPeriod]) => {
            const curParsedPeriod = parsePeriod(curPeriod);
            return comparePeriod(curParsedPeriod, this.parsedPeriod) <= 0;
          })
          .sort(compareEntries)
          .reverse();
        const value = impacts.length > 0 ? impacts[0][1] : null;
        const valuePeriod =
          impacts.length > 0 ? parsePeriod(impacts[0][0]) : this.parsedPeriod;

        const targets = Object.entries(metric.projectedImpacts)
          .filter(([curPeriod]) => {
            const curParsedPeriod = parsePeriod(curPeriod);
            return comparePeriod(curParsedPeriod, this.parsedPeriod) >= 0;
          })
          .sort(compareEntries)
          .reverse();
        const target = targets.length > 0 ? targets[0][1] : null;
        const targetPeriod =
          targets.length > 0 ? parsePeriod(targets[0][0]) : null;

        return {
          label: metric.irisMetric,
          value,
          valuePeriod,
          target,
          targetPeriod,
        };
      });
    },
  },
  methods: {
    async fetchMetrics() {
      try {
        this.state = State.LOADING;
        this.metrics = await apiExecute("get", "/imm-custom-impact-metrics", {
          teamId: this.teamId,
        });
        this.state = this.metrics.length > 0 ? State.READY : State.NO_DATA;
      } catch (error) {
        console.warn("Failed to fetch impact metrics", error);
        this.state = State.ERROR;
      }
    },
  },
  components: {
    PlotStackedPercentBarChart,
    ClipLoader,
  },
  watch: {
    teamId: {
      handler: "fetchMetrics",
    },
  },
};
</script>
